import React, { useContext } from "react";
import { Navigate } from "react-router";

import UserContext from "@/contexts/UserContext";
import { useQuery } from "@/hooks/useQuery";

export default function UndercoverRoute({ children }) {
  const { user } = useContext(UserContext);

  const query = useQuery();
  const id_client = query.id === "add" ? null : parseInt(query.id);

  return (
    <>
      {user && user.cognito && user.inscap && user && user.isAdmin ? (
        user.id_client || id_client ? (
          children
        ) : (
          <Navigate to="/" />
        )
      ) : (
        <Navigate to="/403" />
      )}
    </>
  );
}
