import { useContext, useEffect } from "react";

import UserContext from "@/contexts/UserContext";

export function useDetectClient(id_client) {
  const { user, selectClient } = useContext(UserContext);

  useEffect(() => {
    if (user && id_client && id_client !== user.id_client) {
      selectClient(id_client);
    }
  }, [user, id_client, selectClient]);
}
