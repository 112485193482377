import { useContext } from "react";
import {
  AccountBalanceOutlined,
  BusinessOutlined,
  ClearAllOutlined,
  DialpadOutlined,
  EditOutlined,
  GroupOutlined,
  HomeOutlined,
  InfoOutlined,
  PaidOutlined,
  TrendingUpOutlined,
} from "@mui/icons-material";
import {
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from "@mui/material";
import { Link, useLocation } from "react-router";

import UserContext from "@/contexts/UserContext";

import ClientSelect from "./ClientSelect";

export default function SiteNavigation({ collapsed, onLinkClicked, ...props }) {
  const theme = useTheme();
  const { pathname } = useLocation();

  const { user } = useContext(UserContext);

  /*
	const [originalClientData] = useGet((user && user.isPrimary) ? API.getClient(user) : null);
	const { client } = originalClientData || {};
	*/

  function renderItem(label, icon, href, active, sx) {
    const color = active ? theme.palette.primary.main : null;
    return (
      <ListItemButton
        key={label + href}
        component={Link}
        to={href}
        onClick={onLinkClicked}
        sx={
          active
            ? {
                minHeight: "3rem",
                background: "#E8E8E8",
                borderRadius: "0px 15px 15px 0px",
                ...sx,
              }
            : {
                minHeight: "3rem",
                borderRadius: "0px 15px 15px 0px",
                ...sx,
              }
        }
      >
        <ListItemIcon sx={{ color: color }}>{icon}</ListItemIcon>
        <ListItemText
          sx={{
            color: color,
            opacity: collapsed ? 0 : 1,
            transition: "opacity 0.3s",
            minWidth: "11.5rem",
          }}
        >
          {label}
        </ListItemText>
        {/* active &&
				<Box sx={{
					position: 'absolute',
					left: 0,
					top: 0,
					bottom: 0,
					width: '5px',
					background: color,
				}} />
			*/}
      </ListItemButton>
    );
  }

  const clientSelectStyle = {
    marginBottom: 1,
    transition: "margin 0.3s",
  };
  if (collapsed) {
    clientSelectStyle.width = "3.5rem";
  } else {
    clientSelectStyle.marginLeft = 2;
    clientSelectStyle.marginRight = 2;
  }

  return (
    <List key="sn" {...props}>
      {user.isAdmin ? (
        <>
          {renderItem(
            "Admin Dashboard",
            <HomeOutlined />,
            "/",
            pathname === "/",
          )}
          {renderItem(
            "Clients",
            <BusinessOutlined />,
            "/clients",
            pathname === "/clients",
          )}
          {renderItem(
            "Contacts",
            <GroupOutlined />,
            "/contacts",
            pathname === "/contacts",
          )}
          {renderItem(
            "Issuers",
            <AccountBalanceOutlined />,
            "/issuers",
            pathname === "/issuers",
          )}
          {renderItem(
            "Projects",
            <ClearAllOutlined />,
            "/projects",
            pathname === "/projects",
          )}
          {renderItem(
            "Bond Calculator",
            <DialpadOutlined />,
            "/bond",
            pathname === "/bond",
          )}
          <Divider sx={{ marginTop: 2, marginBottom: 3 }} />
          <ClientSelect
            key="cvs"
            collapsed={collapsed}
            sx={clientSelectStyle}
          />
          {user.id_client && (
            <>
              {renderItem(
                "Edit Client",
                <EditOutlined />,
                "/client/" + user.id_client,
                pathname === "/client/" + user.id_client,
                {
                  /*marginBottom: 1*/
                },
              )}
              {renderItem(
                "Financial Data",
                <PaidOutlined />,
                "/client/" + user.id_client + "/financial/",
                pathname === "/client/" + user.id_client + "/financial",
              )}
              {renderItem(
                "Client Dashboard",
                <TrendingUpOutlined />,
                "/client/" + user.id_client + "/dashboard",
                pathname === "/client/" + user.id_client + "/dashboard",
              )}
              {/* renderItem("Client Invoices", <ReceiptOutlined />, "/client/" + user.id_client + "/invoices", (pathname === "/client/" + user.id_client + "/invoices")) */}
              {renderItem(
                "Client Projects",
                <ClearAllOutlined />,
                "/client/" + user.id_client + "/projects",
                pathname === "/client/" + user.id_client + "/projects",
              )}
            </>
          )}
        </>
      ) : user.isDataroom ? (
        <>
          {user.inscap.clients.length > 1 ? (
            <>
              renderItem("Dashboard", <TrendingUpOutlined />, "/", (pathname ===
              "/"))
              <Divider sx={{ marginTop: 2, marginBottom: 3 }} />
              <ClientSelect
                key="cvs"
                collapsed={collapsed}
                sx={clientSelectStyle}
              />
            </>
          ) : null}
          {user.id_client && (
            <>
              {renderItem(
                "Financial Data",
                <PaidOutlined />,
                "/client/" + user.id_client,
                pathname === "/client/" + user.id_client,
                {
                  /*marginBottom: 1*/
                },
              )}
              {renderItem(
                "Projects",
                <ClearAllOutlined />,
                "/client/" + user.id_client + "/projects",
                pathname === "/client/" + user.id_client + "/projects",
              )}
            </>
          )}
        </>
      ) : user.isSubsidiary ? (
        <>
          {renderItem(
            "Dashboard",
            <TrendingUpOutlined />,
            "/",
            pathname === "/",
          )}
          {renderItem(
            "Company Profile",
            <InfoOutlined />,
            "/company",
            pathname === "/company",
          )}
          {renderItem(
            "Bond Calculator",
            <DialpadOutlined />,
            "/bond",
            pathname === "/bond",
          )}
        </>
      ) : (
        <>
          {renderItem(
            "Dashboard",
            <TrendingUpOutlined />,
            "/",
            pathname === "/",
          )}
          {renderItem(
            "Company Profile",
            <InfoOutlined />,
            "/company",
            pathname === "/company",
          )}
          {renderItem(
            "Projects",
            <ClearAllOutlined />,
            "/projects",
            pathname === "/projects",
          )}
          {renderItem(
            "Financial Data",
            <PaidOutlined />,
            "/financial",
            pathname === "/financial",
          )}
          {renderItem(
            "Bond Calculator",
            <DialpadOutlined />,
            "/bond",
            pathname === "/bond",
          )}
          {/* client && client.id_xero_contact ? renderItem("Invoices", <ReceiptOutlined />, "/invoices", (pathname === "/invoices")) : null */}
        </>
      )}
    </List>
  );
}
