import { Typography } from "@mui/material";

import FlexBox from "@/components/layout/FlexBox";
import MobilePadding from "@/components/layout/MobilePadding";
import Actions from "@/components/modules/Actions";

export default function PageTitle({ title, actions, icon, ...props }) {
  return (
    <MobilePadding {...props}>
      <FlexBox mb={2} center sx={{ minHeight: "36px" }}>
        {icon}
        <Typography variant="pageHeader" ml={icon ? 2 : 0} mr="auto">
          {title}
        </Typography>
        {actions ? <Actions>{actions}</Actions> : null}
      </FlexBox>
    </MobilePadding>
  );
}
