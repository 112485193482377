import React, { useContext } from "react";

import API from "@/API";
import { useGet } from "@/hooks/useAPI";

import UserContext from "./UserContext";

const StaticDataContext = React.createContext({});

export const StaticDataProvider = ({ children }) => {
  const { user } = useContext(UserContext);

  const [staticData] = useGet(user && user.inscap && API.getStaticData(user));

  return (
    <StaticDataContext.Provider value={staticData}>
      {children}
    </StaticDataContext.Provider>
  );
};

export default StaticDataContext;

export const ADMIN_ID = 1000;
export const CLIENT_ID = 2000;
export const CLIENT_PRIMARY_ID = 2010;
export const CLIENT_DATAROOM_ID = 2020;
export const CLIENT_SUBSIDIARY_ID = 2030;
