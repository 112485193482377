import { Box } from "@mui/material";

export default function SectionedBar({
  sections,
  right,
  thickness,
  sx,
  ...props
}) {
  if (isNaN(thickness)) thickness = 10;

  let totalProgress = 0;

  return (
    <Box
      sx={{
        width: "100%",
        height: thickness + "px",
        borderRadius: thickness / 2 + "px",
        background: "#E0E0E0",
        position: "relative",
        ...sx,
      }}
      {...props}
    >
      {sections &&
        sections.map((section, index) => {
          totalProgress = Math.min(1, totalProgress + section.progress);
          return (
            <Box
              key={index}
              sx={{
                height: "100%",
                width: totalProgress * 100 + "%",
                borderRadius: thickness / 2 + "px",
                position: "absolute",
                background: section.color,
                opacity: section.opacity,
                right: right ? "0" : null,
                zIndex: sections.length - index,
              }}
            />
          );
        })}
    </Box>
  );
}
