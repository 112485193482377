import { useContext } from "react";
import { Box, Container, Drawer } from "@mui/material";

import FlexBox from "@/components/layout/FlexBox";
import Actions from "@/components/modules/Actions";
import LayoutContext from "@/contexts/LayoutContext";

export default function SaveDrawer({ children, actions, open }) {
  const { wideLayout, navStyle, contentStyle, containerStyle } =
    useContext(LayoutContext);

  return (
    <Drawer variant="persistent" anchor="bottom" open={open}>
      <FlexBox mb={2}>
        {wideLayout && <Box sx={navStyle} />}
        <Box sx={contentStyle}>
          <Container sx={containerStyle}>
            <FlexBox center pt={2}>
              <Box sx={{ flex: "1 1 0" }} mr={actions ? 2 : 0}>
                {children}
              </Box>
              {actions && (
                <Box ml="auto">
                  <Actions>{actions}</Actions>
                </Box>
              )}
            </FlexBox>
          </Container>
        </Box>
      </FlexBox>
    </Drawer>
  );
}
