import { useContext } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { red } from "@mui/material/colors";

import { calculateBondStatusUsage } from "@/BondFunctions";
import SectionedBar from "@/components/common/SectionedBar";
import FlexBox from "@/components/layout/FlexBox";
import StaticDataContext from "@/contexts/StaticDataContext";
import { formatMoney, searchByField } from "@/Utils";

export default function FacilityCapacityBar({
  facility,
  highestCapacity,
  facilityColor,
  additionalBonds,
  additionalBondColors,
  variant,
  ...props
}) {
  const theme = useTheme();

  const staticData = useContext(StaticDataContext);

  if (!staticData || !facility) return null;

  const { pending, active } = calculateBondStatusUsage(
    facility.bond_status_totals,
    staticData.bond_statuses,
  );

  const issuer = searchByField(
    staticData.issuers,
    "id_issuer",
    facility.id_issuer,
  );

  const sections = [];
  if (active > 0)
    sections.push({
      color: facilityColor || "#7B7F8D",
      progress: active / facility.limit,
    });
  if (pending > 0)
    sections.push({
      color: facilityColor || "#7B7F8D",
      opacity: 0.6,
      progress: pending / facility.limit,
    });
  let utilised = pending + active;
  if (additionalBonds) {
    for (let i = 0; i < additionalBonds.length; i++) {
      const bond = additionalBonds[i];
      utilised += bond.face_value;
      sections.push({
        color: additionalBondColors
          ? additionalBondColors[i]
          : theme.bondColors[i % theme.bondColors.length],
        progress: bond.face_value / facility.limit,
      });
    }
  }

  const utilisedStyle = {};
  if (variant === "wide") utilisedStyle.fontWeight = 500;
  if (utilised > facility.limit) utilisedStyle.color = red[700];
  const totalStyle = {};
  if (variant === "wide") totalStyle.fontWeight = 500;

  return (
    <Box {...props}>
      {variant === "wide" ? (
        <>
          <FlexBox center>
            <Typography variant="h6" mr={2}>
              {issuer.name}
            </Typography>
            <Typography variant="secondary">
              <span style={utilisedStyle}>{formatMoney(utilised, 2)}</span> of{" "}
              <span style={totalStyle}>{formatMoney(facility.limit, 2)}</span>{" "}
              total capacity
            </Typography>
          </FlexBox>
          <SectionedBar
            sections={sections}
            sx={{
              width: highestCapacity
                ? (facility.limit / highestCapacity) * 100 + "%"
                : null,
            }}
          />
        </>
      ) : (
        <>
          <SectionedBar sections={sections} sx={{ marginBottom: 1 }} />
          <FlexBox center>
            <Typography variant="tertiary">
              <span style={utilisedStyle}>{formatMoney(utilised, 2)}</span> out
              of{" "}
              <span style={totalStyle}>{formatMoney(facility.limit, 2)}</span>
            </Typography>
          </FlexBox>
        </>
      )}
    </Box>
  );
}
