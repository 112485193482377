import { TextField, useTheme } from "@mui/material";

export default function DisplayField({ sx, value, ...props }) {
  const theme = useTheme();

  return (
    <TextField
      disabled
      value={value || ""}
      {...props}
      sx={{
        "input.Mui-disabled": {
          WebkitTextFillColor: theme.palette.text.primary,
        },
        ".Mui-disabled .Mui-disabled": {
          WebkitTextFillColor: theme.palette.text.primary,
        },
        ...sx,
      }}
    />
  );
}
