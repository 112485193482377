import React from "react";
import * as Sentry from "@sentry/react";
import { render } from "react-dom";

import { UserProvider } from "@/contexts/UserContext";

import { App } from "./App";

Sentry.init({
  dsn: "https://cf5ddb5ece874868bccb2c2be41c4b94@o4507264015335424.ingest.us.sentry.io/4508991012274177",
  enabled: import.meta.env.MODE === "production",
});

const container = document.getElementById("root");
render(
  <React.StrictMode>
    <UserProvider>
      <App />
    </UserProvider>
  </React.StrictMode>,
  container,
);
