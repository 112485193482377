import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Button, Grid, Stack, Typography } from "@mui/material";

import API from "@/API";
import CentreLoader from "@/components/common/CentreLoader";
import NullableTextField from "@/components/common/NullableTextField";
import PageTitle from "@/components/common/PageTitle";
import SectionCard from "@/components/common/SectionCard";
import SectionTitle from "@/components/common/SectionTitle";
import SaveDrawer from "@/components/modules/SaveDrawer";
import StaticDataContext from "@/contexts/StaticDataContext";
import UserContext from "@/contexts/UserContext";
import { usePut } from "@/hooks/useAPI";
import { useQuery } from "@/hooks/useQuery";
import {
  checkFormValidity,
  getIssuerHeaderLines,
  searchByField,
} from "@/Utils";

export default function EditIssuerPage() {
  const query = useQuery();
  const id_issuer = parseInt(query.id);

  const { user } = useContext(UserContext);

  const staticData = useContext(StaticDataContext);

  const originalIssuer =
    staticData && searchByField(staticData.issuers, "id_issuer", id_issuer);

  const [issuer, setIssuer] = useState(originalIssuer);

  const [revertTarget, setRevertTarget] = useState(originalIssuer);

  const [changed, setChanged] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [putIssuer] = usePut(id_issuer ? API.putIssuer(user, id_issuer) : null);

  const formRef = useRef();

  useEffect(() => {
    setIssuer(originalIssuer);
    setRevertTarget(
      structuredClone({
        issuer: originalIssuer,
      }),
    );
  }, [originalIssuer, setIssuer, setRevertTarget]);

  function revert() {
    const clone = structuredClone(revertTarget);
    setIssuer(clone.issuer);
    setChanged(false);
  }

  const updateIssuer = useCallback(
    (newValues) => {
      setIssuer({
        ...issuer,
        ...newValues,
      });
      setChanged(true);
    },
    [issuer],
  );

  function save(event) {
    if (checkFormValidity(formRef.current)) {
      setSubmitting(true);
      const r = structuredClone({
        issuer: issuer,
      });

      putIssuer(r)
        .then((responseData) => {
          setSubmitting(false);
          setRevertTarget(r);
          setChanged(false);
        })
        .catch((error) => {
          console.log("Put issuer error", error);
        });
    }
  }

  return (
    <>
      {staticData && issuer ? (
        <>
          <PageTitle title={issuer.name} />

          <form ref={formRef}>
            <SectionCard>
              <SectionTitle title="Issuer details" />
              <Grid container columnSpacing={2} rowSpacing={2} mb={4}>
                <Grid item xs={12} md={12}>
                  <NullableTextField
                    label="Name"
                    fullWidth
                    type="text"
                    required
                    value={issuer.name}
                    onChange={(value) => {
                      updateIssuer({ name: value });
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container columnSpacing={2} rowSpacing={2} mb={4}>
                <Grid item xs={12} md={9}>
                  <NullableTextField
                    label="Legal name"
                    fullWidth
                    type="text"
                    required
                    value={issuer.legal_name}
                    onChange={(value) => {
                      updateIssuer({ legal_name: value });
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <NullableTextField
                    label="ABN"
                    fullWidth
                    type="text"
                    value={issuer.abn}
                    onChange={(value) => {
                      updateIssuer({ abn: value });
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <NullableTextField
                    label="Phone"
                    fullWidth
                    type="tel"
                    value={issuer.business_phone}
                    onChange={(value) => {
                      updateIssuer({ business_phone: value });
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={9}>
                  <NullableTextField
                    label="Website"
                    fullWidth
                    type="text"
                    value={issuer.business_website}
                    onChange={(value) => {
                      updateIssuer({ business_website: value });
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container columnSpacing={2} rowSpacing={2} mb={3}>
                <Grid item xs={12} md={12}>
                  <NullableTextField
                    label="Street"
                    fullWidth
                    type="text"
                    value={issuer.business_address_street}
                    onChange={(value) => {
                      updateIssuer({ business_address_street: value });
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <NullableTextField
                    label="Suburb"
                    fullWidth
                    type="text"
                    value={issuer.business_address_suburb}
                    onChange={(value) => {
                      updateIssuer({ business_address_suburb: value });
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <NullableTextField
                    label="State"
                    fullWidth
                    type="text"
                    value={issuer.business_address_state}
                    onChange={(value) => {
                      updateIssuer({ business_address_state: value });
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <NullableTextField
                    label="Postcode"
                    fullWidth
                    type="text"
                    value={issuer.business_address_postcode}
                    onChange={(value) => {
                      updateIssuer({ business_address_postcode: value });
                    }}
                  />
                </Grid>
              </Grid>

              <Stack align="right" style={{ fontSize: 12 }}>
                {getIssuerHeaderLines(issuer).map((line, index) => {
                  return (
                    <Typography
                      key={index}
                      sx={{ fontSize: 11, whiteSpace: "pre" }}
                    >
                      {line}{" "}
                    </Typography>
                  );
                })}
              </Stack>
            </SectionCard>

            <SectionCard>
              <SectionTitle title="Admin details" />
              <Grid container columnSpacing={2} rowSpacing={2}>
                <Grid item xs={12} md={12}>
                  <NullableTextField
                    label="Name"
                    fullWidth
                    type="text"
                    value={issuer.admin_name}
                    onChange={(value) => {
                      updateIssuer({ admin_name: value });
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <NullableTextField
                    label="Role"
                    fullWidth
                    type="text"
                    value={issuer.admin_role}
                    onChange={(value) => {
                      updateIssuer({ admin_role: value });
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <NullableTextField
                    label="Email"
                    fullWidth
                    type="email"
                    value={issuer.admin_email}
                    onChange={(value) => {
                      updateIssuer({ admin_email: value });
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <NullableTextField
                    label="Mobile"
                    fullWidth
                    type="tel"
                    value={issuer.admin_mobile}
                    onChange={(value) => {
                      updateIssuer({ admin_mobile: value });
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <NullableTextField
                    label="Landline"
                    fullWidth
                    type="tel"
                    value={issuer.admin_landline}
                    onChange={(value) => {
                      updateIssuer({ admin_landline: value });
                    }}
                  />
                </Grid>
              </Grid>
            </SectionCard>
          </form>

          <SaveDrawer
            open={changed}
            actions={
              <>
                <Button
                  variant="contained"
                  disabled={submitting}
                  onClick={save}
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  disabled={submitting}
                  onClick={revert}
                >
                  Cancel
                </Button>
              </>
            }
          />
        </>
      ) : (
        <CentreLoader />
      )}
    </>
  );
}
