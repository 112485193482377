import { useMemo } from "react";
import { Card, CardContent, useTheme } from "@mui/material";

import ResponsiveCanvas from "@/components/common/ResponsiveCanvas";
import SectionTitle from "@/components/common/SectionTitle";

export default function ProjectDistributionGraph({
  title,
  distribution,
  distributionKey,
  actions,
  sx,
}) {
  const theme = useTheme();

  const { bars, maxValue } = useMemo(() => {
    if (distribution) {
      // Precalculate graph data

      const bars = [];
      let maxValue = 0;
      distribution.forEach((entry) => {
        bars.push({ label: entry.key, value: entry.value });
        maxValue = Math.max(entry.value, maxValue);
      });

      return { bars, maxValue };
    } else return {};
  }, [distribution]);

  return (
    <>
      <SectionTitle title={title} actions={actions} />

      <Card sx={sx}>
        <CardContent
          sx={{
            borderBottom: "1px solid #e0e0e0",
            paddingBottom: "10px",
            overflow: "auto",
          }}
        >
          <ResponsiveCanvas
            width="100%"
            height="300px"
            redraw={(canvas) => {
              drawToCanvas(canvas, 2, bars, maxValue, distributionKey, theme);
            }}
          />
        </CardContent>
      </Card>
    </>
  );
}

function drawToCanvas(
  canvas,
  drawScale,
  bars,
  maxValue,
  distributionKey,
  theme,
) {
  if (!bars || bars.length === 0) return;

  const bounds = canvas.getBoundingClientRect();
  const width = (canvas.width = bounds.width * drawScale);
  const height = (canvas.height = bounds.height * drawScale);

  // Work out value scale
  let valueStep = maxValue / 10;

  const sig = Math.max(1, parseInt(("" + maxValue).substring(0, 2)));
  const sigCeil = Math.ceil(sig / 10) * 10;
  let multiplier = 1;
  while (sigCeil * multiplier < maxValue) multiplier *= 10;

  if (sig >= 50) valueStep = 10 * multiplier;
  else if (sig > 20) valueStep = 5 * multiplier;
  else if (sig > 10) valueStep = 2 * multiplier;
  else valueStep = multiplier;

  const valueSteps = Math.min(maxValue, 10);
  valueStep = Math.max(1, Math.ceil(maxValue / valueSteps));

  const ctx = canvas.getContext("2d");
  ctx.clearRect(0, 0, width, height);

  ctx.font = "400 " + 0.7 * drawScale + "rem " + theme.fontFamily[0];

  const labelAngle = -Math.PI * 0.25;
  const cosA = Math.cos(labelAngle);
  const sinA = Math.sin(labelAngle);
  let maxDiagAsc = 0;
  let maxDiagTotal = 0;
  bars.forEach((bar) => {
    const bounds = ctx.measureText(bar.label);
    const diagAsc = bounds.actualBoundingBoxAscent * sinA;
    maxDiagAsc = Math.max(maxDiagAsc, diagAsc);
    const diagTotal = bounds.actualBoundingBoxRight * cosA + diagAsc;
    maxDiagTotal = Math.max(maxDiagTotal, diagTotal);
  });

  const yLabelMargin = 15 * drawScale;
  const yLabelWidth = Math.ceil(ctx.measureText(maxValue).width) + yLabelMargin;
  const xLabelHeight = maxDiagTotal + 25 * drawScale;

  const gx = yLabelWidth;
  const gy = 10 * drawScale;
  const gw = width - gx;
  const gh = height - gy - xLabelHeight;

  const barWidth = Math.floor(Math.min(200 * drawScale, gw / bars.length));

  function valueY(value) {
    return gy + gh * (1 - value / maxValue);
  }

  ctx.lineCap = "butt";
  ctx.lineWidth = 1 * drawScale;

  // Axes
  ctx.strokeStyle = "#e7e7e7";
  ctx.beginPath();
  ctx.moveTo(gx, gy);
  ctx.lineTo(gx, gy + gh);
  ctx.lineTo(gx + gw, gy + gh);
  ctx.stroke();

  ctx.fillStyle = "#7d7d7d"; //font.color;

  // Value labels
  ctx.textAlign = "right";
  for (let step = 0; step <= valueSteps; step++) {
    const value = step * valueStep;
    ctx.fillText(value, gx - yLabelMargin, valueY(value) + 2);
  }

  // Bar labels
  ctx.textAlign = "right";
  bars.forEach((bar, index) => {
    const x = gx + (index + 0.55) * barWidth;

    //ctx.fillText(bar.label, x, gy + gh + 18 * drawScale);

    ctx.save();
    ctx.translate(x, gy + gh + 18 * drawScale);
    ctx.rotate(labelAngle);
    ctx.fillText(bar.label, 0, 0);
    ctx.restore();
  });

  // Bars
  const colors = theme.distributionColors[distributionKey];
  bars.forEach((bar, index) => {
    ctx.fillStyle = colors[index % colors.length];
    ctx.fillRect(
      gx + barWidth * index,
      gy + gh,
      barWidth,
      (-gh * bar.value) / (valueStep * valueSteps),
    );
  });
}
