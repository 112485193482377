import { useMemo } from "react";
import { useLocation, useParams } from "react-router";

export function useQuery() {
  const params = useParams();
  const location = useLocation();

  return useMemo(() => {
    const searchParams = Object.fromEntries(
      new URLSearchParams(location.search),
    );
    return {
      ...searchParams,
      ...params,
    };
  }, [params, location]);
}
