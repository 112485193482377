import { useContext, useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import moment from "moment";

import {
  BOND_STATUS_APPLIED,
  BOND_STATUS_DRAFT,
  BOND_STATUS_ISSUED,
  BOND_STATUS_QUOTE,
} from "@/BondFunctions";
import ResponsiveCanvas from "@/components/common/ResponsiveCanvas";
import StaticDataContext from "@/contexts/StaticDataContext";
import { formatMoney, orderFacilitys, searchByField } from "@/Utils";

export default function FacilityProfileGraph({ client, facilitys, bonds }) {
  const theme = useTheme();

  const staticData = useContext(StaticDataContext);

  const [sections, setSections] = useState(null);

  useEffect(() => {
    if (staticData && client && bonds && facilitys) {
      // Precalculate graph data
      const validFacilityIDs = {};
      facilitys.forEach((facility) => {
        validFacilityIDs[facility.id_facility] = true;
      });

      const now = new Date().getTime();

      const firstSection = {
        date: new Date(),
        timestamp: now,
        total: 0,
        quotesTotal: 0,
        facilityTotals: {},
        endingBonds: [],
        endingQuotes: [],
      };

      // Calculate totals and sections based on bond end times
      const sectionDict = {};
      bonds.forEach((bond) => {
        const timestamp = bond.date_finish.getTime();
        if (timestamp > now && validFacilityIDs[bond.id_facility]) {
          if (!sectionDict[timestamp]) {
            sectionDict[timestamp] = {
              date: bond.date_finish,
              timestamp: timestamp,
              endingBonds: [],
              endingQuotes: [],
            };
          }

          const value = bond.face_value;
          switch (
            (
              searchByField(
                staticData.bond_statuses,
                "id_bond_status",
                bond.id_bond_status,
              ) || {}
            ).name
          ) {
            case BOND_STATUS_QUOTE:
            case BOND_STATUS_APPLIED:
            case BOND_STATUS_DRAFT:
              sectionDict[timestamp].endingQuotes.push(bond);
              firstSection.quotesTotal += value;
              break;
            case BOND_STATUS_ISSUED:
              sectionDict[timestamp].endingBonds.push(bond);
              firstSection.total += value;
              if (!firstSection.facilityTotals[bond.id_facility])
                firstSection.facilityTotals[bond.id_facility] = 0;
              firstSection.facilityTotals[bond.id_facility] += value;
              break;
            default:
              break;
          }
        }
      });

      // Sort sections chronologically
      const sections = [firstSection];
      for (const key in sectionDict) {
        sections.push(sectionDict[key]);
      }
      sections.sort((a, b) => {
        return a.timestamp - b.timestamp;
      });

      // Flesh out further breakpoints
      for (let i = 1; i < sections.length; i++) {
        const section = sections[i];
        const prev = sections[i - 1];
        section.total = prev.total;
        section.quotesTotal = prev.quotesTotal;
        section.facilityTotals = {};
        section.endingBonds.forEach((bond) => {
          const value = bond.face_value;
          section.total -= value;
          if (section.facilityTotals[bond.id_facility] === undefined) {
            for (let j = i - 1; j >= 0; j--) {
              const oldFacilityTotal =
                sections[j].facilityTotals[bond.id_facility];
              if (oldFacilityTotal !== undefined) {
                section.facilityTotals[bond.id_facility] = oldFacilityTotal;
                break;
              }
            }
          }
          section.facilityTotals[bond.id_facility] -= value;
        });
        section.endingQuotes.forEach((bond) => {
          const value = bond.face_value;
          section.quotesTotal -= value;
        });
      }
      // Carry through values to the end of the graph
      /*
			let lastSection = sections[sections.length - 1];
			for (let id_facility in firstSection.facilityTotals) {
				if (lastSection.facilityTotals[id_facility] === undefined) {
					for (let j = sections.length - 2; j >= 0; j--) {
						let oldFacilityTotal = sections[j].facilityTotals[id_facility];
						if (oldFacilityTotal !== undefined) {
							lastSection.facilityTotals[id_facility] = oldFacilityTotal;
							break;
						}
					}
				}
			}
			*/

      setSections(sections);
    }
  }, [staticData, client, facilitys, bonds, setSections]);

  return (
    <ResponsiveCanvas
      width="100%"
      height="300px"
      redraw={(canvas) => {
        drawToCanvas(canvas, sections, client, facilitys, bonds, theme);
      }}
    />
  );
}

function drawToCanvas(canvas, sections, client, facilitys, bonds, theme) {
  if (!sections || sections.length === 0) return;

  const bounds = canvas.getBoundingClientRect();
  const width = (canvas.width = bounds.width);
  const height = (canvas.height = bounds.height);

  // Work out time scale
  const firstSectionMoment = new moment(sections[0].timestamp);
  const lastSectionMoment = new moment(sections[sections.length - 1].timestamp);

  const maxTimeLabels = Math.max(1, Math.floor(width / 100) - 1); // -1 to allow for the first part-step

  // First marker - the next time it's 30 jun or 31 dec
  const firstMarker = new moment(sections[0].timsetamp);
  firstMarker.date(0);
  firstMarker.month(firstMarker.month() + 1);
  if (firstMarker.month() > 6) {
    firstMarker.month(11);
    firstMarker.date(31);
  } else {
    firstMarker.month(5);
    firstMarker.date(30);
  }

  // Work out start and end times of graph
  const startTimestamp = firstSectionMoment.valueOf();
  const endMoment = new moment(firstSectionMoment);
  while (endMoment < firstMarker || endMoment < lastSectionMoment)
    endMoment.month(endMoment.month() + 6);
  const endTimestamp = endMoment.valueOf();

  // Add a marker/line each 6 months until end of graph
  const markers = [];
  for (
    let marker = new moment(firstMarker);
    marker < endMoment;
    marker.month(marker.month() + 6)
  ) {
    markers.push(new moment(marker));
  }

  const timeLines = [];
  markers.forEach((marker) => {
    if (marker.month() === 11) marker.date(31);
    else marker.date(30);
    timeLines.push({
      moment: marker,
      timestamp: marker.valueOf(),
      label: marker.format("D MMM YYYY"),
      showLabel: true,
    });
  });

  // Cull number of visible labels to an acceptable amount
  let timeLabels = timeLines.concat();
  let culledEoY = false;
  while (timeLabels.length > maxTimeLabels) {
    if (!culledEoY) {
      culledEoY = true;
      timeLabels = timeLabels.filter((timeLabel) => {
        return timeLabel.moment.month() === 5;
      });
      continue;
    }

    const culled = [];
    for (let i = 0; i < timeLabels.length; i += 2) {
      culled.push(timeLabels[i]);
    }
    timeLabels = culled;
  }

  // Work out value scale
  const total = (sections[0].total || 0) + (sections[0].quotesTotal || 0);
  let maxValue = total;
  let valueStep = total / 10;
  if (total < 100) {
    maxValue = 100000;
    valueStep = 10000;
  } else {
    const sig = Math.max(1, parseInt(("" + total).substring(0, 2)));
    const sigCeil = Math.ceil(sig / 10) * 10;
    let multiplier = 1;
    while (sigCeil * multiplier < total) multiplier *= 10;

    if (sig >= 50) valueStep = 10 * multiplier;
    else if (sig > 20) valueStep = 5 * multiplier;
    else if (sig > 10) valueStep = 2 * multiplier;
    else valueStep = multiplier;
    maxValue = Math.ceil(total / valueStep) * valueStep;
  }
  const valueSteps = Math.ceil(maxValue / valueStep);

  const ctx = canvas.getContext("2d");
  ctx.clearRect(0, 0, width, height);

  ctx.font = "400 0.7rem " + theme.fontFamily[0];

  const yLabelMargin = 15;
  const yLabelWidth =
    Math.ceil(ctx.measureText(formatMoney(maxValue, 0)).width) + yLabelMargin;
  const xLabelHeight = 25;

  const gx = yLabelWidth;
  const gy = 10;
  const gw = width - gx;
  const gh = height - gy - xLabelHeight;

  function timestampX(timestamp) {
    return (
      gx + (gw * (timestamp - startTimestamp)) / (endTimestamp - startTimestamp)
    );
  }
  function valueY(value) {
    return gy + gh * (1 - value / maxValue);
  }

  ctx.lineCap = "butt";
  ctx.lineWidth = 1;

  // Vertical lines
  ctx.strokeStyle = "#e7e7e7";
  ctx.beginPath();
  timeLines.forEach((line) => {
    const x = timestampX(line.timestamp);
    ctx.moveTo(x, gy);
    ctx.lineTo(x, gy + gh);
  });
  ctx.stroke();

  // Horizontal lines
  ctx.beginPath();
  for (let step = 0; step <= valueSteps; step++) {
    const y = valueY(step * valueStep);
    ctx.moveTo(gx, y);
    ctx.lineTo(gx + gw, y);
  }
  ctx.stroke();

  ctx.fillStyle = "#7d7d7d"; //font.color;

  // Value labels
  ctx.textAlign = "right";
  for (let step = 0; step <= valueSteps; step++) {
    const value = step * valueStep;
    ctx.fillText(formatMoney(value, 0), gx - yLabelMargin, valueY(value) + 2);
  }

  // Year labels
  ctx.textAlign = "center";
  timeLabels.forEach((timeLine) => {
    const x = timestampX(timeLine.timestamp);
    ctx.fillText(timeLine.label, x, gy + gh + 18);
  });

  // Total/facility lines
  ctx.lineCap = "round";

  const orderedFacilitys = orderFacilitys(facilitys);
  ctx.lineWidth = 2;
  orderedFacilitys.forEach((facility, index) => {
    ctx.strokeStyle = theme.bondColors[index % theme.bondColors.length];
    ctx.beginPath();
    sections.forEach((section, index) => {
      const x = timestampX(section.timestamp);
      const y = valueY(section.facilityTotals[facility.id_facility]);
      if (index === 0) ctx.moveTo(x, y);
      else ctx.lineTo(x, y);
    });
    ctx.stroke();
  });

  ctx.lineWidth = 4;
  ctx.strokeStyle = "#AAB2BB";
  ctx.fillStyle = "#AAB2BB";

  ctx.beginPath();
  for (let i = 0; i < sections.length; i++) {
    const section = sections[i];
    const x = timestampX(section.timestamp);
    const y = valueY(section.total);
    if (i === 0) ctx.moveTo(x, y);
    else ctx.lineTo(x, y);
  }
  for (let i = sections.length - 1; i >= 0; i--) {
    const section = sections[i];
    const x = timestampX(section.timestamp);
    const y = valueY(section.total + section.quotesTotal);
    ctx.lineTo(x, y);
  }
  {
    const section = sections[0];
    const x = timestampX(section.timestamp);
    const y = valueY(section.total);
    ctx.lineTo(x, y);
  }
  ctx.fill();
  ctx.stroke();

  ctx.strokeStyle = "#566678";
  ctx.beginPath();
  sections.forEach((section, index) => {
    const x = timestampX(section.timestamp);
    const y = valueY(section.total);
    if (index === 0) ctx.moveTo(x, y);
    else ctx.lineTo(x, y);
  });
  ctx.stroke();
}
