import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import moment from "moment";
import { Link } from "react-router";

import { getBondStatus } from "@/BondFunctions";
import BondStatus from "@/components/common/BondStatus";
import CardTitle from "@/components/common/CardTitle";
import FlexBox from "@/components/layout/FlexBox";

export default function BondHistory({
  staticData,
  bond: currentBond,
  bond_history,
}) {
  const reverseOrder = [];
  if (bond_history)
    for (let i = bond_history.length - 1; i >= 0; i--)
      reverseOrder.push(bond_history[i]);

  return (
    <Card>
      <CardContent>
        <CardTitle title="History" />

        <Stack direction="column">
          {reverseOrder &&
            reverseOrder.map((bond, index) => {
              const isCurrent = currentBond.id_bond === bond.id_bond;
              const isFirst = index === reverseOrder.length - 1;
              const isLast = index === 0;

              return (
                <Box
                  key={bond.id_bond}
                  style={{
                    position: "relative",
                    paddingLeft: 24,
                    paddingBottom: isFirst ? 10 : 30,
                  }}
                >
                  <Typography variant="secondary">
                    {moment(bond.timestamp_updated).format("DD MMM YYYY")}
                  </Typography>
                  <FlexBox center mt={0.5}>
                    <BondStatus
                      sx={{ marginRight: 0.8 }}
                      bondStatus={getBondStatus(
                        bond.id_bond_status,
                        staticData,
                      )}
                    />
                    {isCurrent ? (
                      <Typography sx={{ display: "inline" }}>
                        {/*viewing*/}
                      </Typography>
                    ) : (
                      <Typography sx={{ display: "inline" }}>
                        <Link to={"/bond/" + bond.id_bond + "/detail"}>
                          view bond
                        </Link>
                      </Typography>
                    )}
                  </FlexBox>

                  <span
                    style={{
                      position: "absolute",
                      top: isLast ? 8 : 0,
                      height: isFirst ? 8 : "100%",
                      left: 7,
                      width: 0,
                      borderRight: "1px solid rgba(0, 0, 0, 0.1)",
                    }}
                  />
                  <span
                    style={{
                      position: "absolute",
                      top: 1,
                      left: 0,
                      width: 14,
                      height: 14,
                      borderRadius: "50%",
                      background: "#808D9D",
                    }}
                  />
                </Box>
              );
            })}
        </Stack>
      </CardContent>
    </Card>
  );
}
