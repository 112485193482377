import { Fragment, useContext, useState } from "react";
import { Button, Divider, Grid, TextField, Typography } from "@mui/material";

import API from "@/API";
import CentreLoader from "@/components/common/CentreLoader";
import DatePicker from "@/components/common/DatePicker";
import DisplayField from "@/components/common/DisplayField";
import SectionCard from "@/components/common/SectionCard";
import SectionTitle from "@/components/common/SectionTitle";
import FlexBox from "@/components/layout/FlexBox";
import FacilityFiles from "@/components/modules/FacilityFiles";
import StaticDataContext from "@/contexts/StaticDataContext";
import UserContext from "@/contexts/UserContext";
import { useGet } from "@/hooks/useAPI";
import { useQuery } from "@/hooks/useQuery";
import { formatMoney, formatPercentage, searchByField } from "@/Utils";

export default function CompanyFacilityPage() {
  const query = useQuery();
  const id_facility = query.id === "add" ? null : query.id;

  const { user } = useContext(UserContext);

  const staticData = useContext(StaticDataContext);

  const [originalFacilityData] = useGet(
    id_facility ? API.getFacility(user, id_facility) : null,
  );
  const { facility } = originalFacilityData || {};

  const [showRates, setShowRates] = useState(false);

  return (
    <>
      {staticData && facility ? (
        <>
          <SectionTitle title={"Facility details"} />
          <SectionCard>
            <Grid container columnSpacing={2} rowSpacing={2}>
              <Grid item xs={12} md={6}>
                <DisplayField fullWidth value={facility.issuer_name} />
              </Grid>
              <Grid item xs={12} md={6}>
                {
                  <DatePicker
                    label="Renewal date"
                    disabled
                    value={facility.renewal_date}
                  />
                }
              </Grid>
            </Grid>

            <Divider sx={{ marginTop: 3, marginBottom: 3 }} />

            <Grid container columnSpacing={2} rowSpacing={2}>
              <Grid item xs={12} md={6}>
                <DisplayField
                  label="Prime rate"
                  fullWidth
                  value={formatPercentage(facility.prime_rate)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DisplayField
                  label="Capacity"
                  fullWidth
                  required
                  value={formatMoney(facility.limit)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DisplayField
                  label="Minimum bond charge"
                  fullWidth
                  required
                  value={formatMoney(facility.minimum_bond_charge)}
                />
              </Grid>
            </Grid>

            <FlexBox justify="center" my={2}>
              <Button
                onClick={(event) => {
                  setShowRates(!showRates);
                }}
              >
                {(showRates ? "Hide" : "show") + " all rates"}
              </Button>
            </FlexBox>

            {showRates && (
              <Grid container columnSpacing={2} rowSpacing={2}>
                {staticData.bond_types.map((bondType) => {
                  const bond_type_facility_rate = searchByField(
                    facility.bond_type_facility_rates,
                    "id_bond_type",
                    bondType.id_bond_type,
                  );
                  return (
                    <Grid item xs={12} md={6} key={bondType.id_bond_type}>
                      <DisplayField
                        label={bondType.name}
                        fullWidth
                        value={
                          bond_type_facility_rate
                            ? formatPercentage(bond_type_facility_rate.rate)
                            : formatPercentage(facility.prime_rate)
                        }
                      />
                    </Grid>
                  );
                })}
              </Grid>
            )}

            <Divider sx={{ marginTop: 3, marginBottom: 3 }} />

            <Grid container columnSpacing={2} rowSpacing={2}>
              {staticData.facility_fee_types.map((feeType, index) => {
                const facility_fee = searchByField(
                  facility.facility_fees,
                  "id_facility_fee_type",
                  feeType.id_facility_fee_type,
                );
                return (
                  <Grid item xs={12} md={6} key={feeType.id_facility_fee_type}>
                    <DisplayField
                      label={feeType.name}
                      fullWidth
                      value={
                        facility_fee ? formatMoney(facility_fee.fee) : null
                      }
                    />
                  </Grid>
                );
              })}
            </Grid>

            <Divider sx={{ marginTop: 3, marginBottom: 3 }} />

            <FacilityFiles
              adminMode={false}
              facility={facility}
              files={facility.facility_files}
            />

            {staticData.banks.map(({ name, id_bank }) => {
              const facility_bank = searchByField(
                facility.facility_banks,
                "id_bank",
                id_bank,
              );

              if (!facility_bank.active) return null;

              return (
                <Fragment key={id_bank}>
                  <Divider sx={{ marginTop: 3, marginBottom: 2 }} />

                  <Typography>{name + " fronted"}</Typography>

                  {facility_bank && facility_bank.active && (
                    <Grid container columnSpacing={2} rowSpacing={2} mt={0}>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        key={facility_bank.id_facility_bank}
                      >
                        <DisplayField
                          label="Minimum fronting charge"
                          fullWidth
                          value={formatMoney(
                            facility_bank.minimum_fronting_charge,
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}></Grid>

                      {staticData.durations.map(({ duration, id_duration }) => {
                        const facility_duration = searchByField(
                          facility_bank.facility_bank_duration_rates,
                          "id_duration",
                          id_duration,
                        );
                        return (
                          <Grid item xs={12} md={6} key={id_duration}>
                            <DisplayField
                              label={duration}
                              fullWidth
                              value={
                                facility_duration
                                  ? formatPercentage(facility_duration.rate)
                                  : null
                              }
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  )}
                </Fragment>
              );
            })}
          </SectionCard>
        </>
      ) : (
        <CentreLoader />
      )}
    </>
  );
}
