import React, { useState } from "react";
import { fetchAuthSession, signOut } from "aws-amplify/auth";

import {
  ADMIN_ID,
  CLIENT_DATAROOM_ID,
  CLIENT_ID,
  CLIENT_PRIMARY_ID,
  CLIENT_SUBSIDIARY_ID,
} from "@/contexts/StaticDataContext";

const UserContext = React.createContext({});

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({
    cognito: null,
    inscap: null,
    contact: null,
    isAdmin: false,
    isClient: false,
    isPrimary: false,
    isSubsidiary: false,
    isDataroom: false,
    id_client: null,
    getToken: null,
  });

  const onDataLoaded = async (inscapUser, contact) => {
    //console.log("onDataLoaded");
    const isAdmin = inscapUser && inscapUser.groups.indexOf(ADMIN_ID) >= 0;
    const isClient = inscapUser && inscapUser.groups.indexOf(CLIENT_ID) >= 0;
    const isPrimary =
      isClient && inscapUser.groups.indexOf(CLIENT_PRIMARY_ID) >= 0;
    const isDataroom =
      isClient && inscapUser.groups.indexOf(CLIENT_DATAROOM_ID) >= 0;
    const isSubsidiary =
      isClient && inscapUser.groups.indexOf(CLIENT_SUBSIDIARY_ID) >= 0;
    let id_client = null;
    if (
      (isPrimary || isSubsidiary) &&
      inscapUser.clients &&
      inscapUser.clients.length === 1
    ) {
      id_client = inscapUser.clients[0];
    }
    setUser((user) => {
      return {
        ...user,
        inscap: inscapUser,
        contact: contact,
        isAdmin: isAdmin,
        isClient: isClient,
        isPrimary: isPrimary,
        isSubsidiary: isSubsidiary,
        isDataroom: isDataroom,
        id_client: id_client,
      };
    });
  };

  const onLoggedIn = async (cognitoUser) => {
    //console.log("onLoggedIn", cognitoUser, user);
    setUser((user) => {
      return {
        ...user,
        cognito: cognitoUser,
        getToken: async () => {
          const token = await fetchAuthSession();

          return token.tokens.idToken.toString();
        },
      };
    });
  };

  const selectClient = (id_client) => {
    setUser((user) => {
      return {
        ...user,
        id_client: id_client,
      };
    });
  };

  const agreeNonreliance = () => {
    setUser((user) => {
      return {
        ...user,
        nonreliance: true,
      };
    });
  };

  const logout = async () => {
    try {
      await signOut();
      setUser((user) => {
        return {
          ...user,
          cognito: null,
          inscap: null,
          contact: null,
          isAdmin: false,
          isClient: false,
          isPrimary: false,
          isSubsidiary: false,
          isDataroom: false,
          id_client: null,
        };
      });
    } catch (error) {
      console.log("Logout error", error);
      //Toast("Error!!", error.message, "danger");
    }
  };

  return (
    <UserContext.Provider
      value={{
        user,
        selectClient,
        onLoggedIn,
        onDataLoaded,
        logout,
        agreeNonreliance,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
