// When a section needs padding on mobile but not desktop

import { useContext } from "react";
import { Box } from "@mui/material";

import LayoutContext from "@/contexts/LayoutContext";

export default function MobilePadding({ children, sx, ...props }) {
  const { wideLayout } = useContext(LayoutContext);

  return (
    <Box {...props} sx={{ ...sx }} px={wideLayout ? 0 : 2}>
      {children}
    </Box>
  );
}
