import { useContext, useState } from "react";
import { FilterList, HighlightOffOutlined } from "@mui/icons-material";
import { Checkbox, FormControlLabel, IconButton } from "@mui/material";
import moment from "moment";
import { Link, useNavigate } from "react-router";

import BondStatus from "@/components/common/BondStatus";
import NullableTextField from "@/components/common/NullableTextField";
import SectionTitle from "@/components/common/SectionTitle";
import StaticDataContext from "@/contexts/StaticDataContext";
import {
  compareDateStrings,
  compareMoneyStrings,
  filterAny,
  formatMoney,
  searchByField,
} from "@/Utils";

import PaginatedTable from "./PaginatedTable";

export default function BondsTable({
  bonds,
  clients,
  filter,
  undercoverMode,
  toggledBonds,
  onToggleBond,
  onRemove,
  sx,
  ...props
}) {
  const navigate = useNavigate();

  const staticData = useContext(StaticDataContext);
  if (!staticData) return null;

  const columns = [
    { key: "reference", cell: "Reference" },
    { key: "status", cell: "Status" },
    { key: "client", cell: "Client" },
    { key: "bond_type", cell: "Type" },
    { key: "fronted", cell: "Fronted" },
    { key: "issuer", cell: "Issuer" },
    {
      key: "face_value",
      cell: "Face Value",
      compareFunc: compareMoneyStrings,
      align: "right",
    },
    { key: "date_finish", cell: "End Date", compareFunc: compareDateStrings },
    { key: "principal", cell: "Principal" },
    { key: "invoice", cell: "Invoice Number" },
  ];
  if (!clients) columns.splice(2, 1);
  if (toggledBonds && onToggleBond) {
    columns.unshift({ key: "toggle", cell: "" });
  }
  if (onRemove) {
    columns.push({ key: "remove", cell: "" });
  }

  const rows = bonds
    ? bonds.map((bond) => {
        const client =
          clients && searchByField(clients, "id_client", bond.id_client);
        const bondType = searchByField(
          staticData.bond_types,
          "id_bond_type",
          bond.id_bond_type,
        );
        const bondStatus = searchByField(
          staticData.bond_statuses,
          "id_bond_status",
          bond.id_bond_status,
        );

        const cells = [
          <span style={{ whiteSpace: "nowrap" }}>{bond.reference || ""}</span>,
          <BondStatus bondStatus={bondStatus} />,
          clients && (
            <Link to={"/client/" + bond.id_client}>
              <span style={{ whiteSpace: "nowrap" }}>
                {client ? client.name : "Unknown"}
              </span>
            </Link>
          ),
          ((bondType && bondType.name) || "")
            .replace("Fronted", "")
            .replace("Bond", "")
            .trim(),
          bondType ? (bondType.fronted ? "Yes" : "No") : "",
          bond.issuer_name || "Unknown",
          formatMoney(bond.face_value, 2),
          moment(bond.date_finish).format("DD/MM/YYYY"),
          <span style={{ whiteSpace: "nowrap" }}>{bond.principal}</span>,
          bond.invoice || "",
        ];
        if (!clients) cells.splice(2, 1);
        if (toggledBonds && onToggleBond) {
          cells.unshift(
            <FormControlLabel
              label=""
              sx={{ marginRight: 0 }}
              control={
                <Checkbox
                  checked={toggledBonds[bond.id_bond] || false}
                  onChange={(event) => {
                    onToggleBond({
                      id_bond: bond.id_bond,
                      bond: bond,
                      toggled: event.target.checked,
                    });
                  }}
                />
              }
            />,
          );
        }
        if (onRemove) {
          cells.push(
            <IconButton
              sx={{ my: -2 }}
              onClick={(event) => {
                event.stopPropagation();
                onRemove(bond);
              }}
            >
              <HighlightOffOutlined />
            </IconButton>,
          );
        }

        return {
          key: bond.id_bond,
          cells: cells,
          bond: bond,
          onClick:
            toggledBonds && onToggleBond
              ? null
              : (event) => {
                  navigate("/bond/" + bond.id_bond + "/detail");
                },
        };
      })
    : [];

  const filterRows = (rows) => {
    if (!filter) return rows;

    const filteredRows = [];
    rows.forEach((row) => {
      let allow = false;
      row.cells.forEach((cell) => {
        if (filterAny(cell, filter)) allow = true;
      });

      for (const s in row.bond) {
        if (filterAny(row.bond[s], filter)) allow = true;
      }

      if (allow) filteredRows.push(row);
    });
    return filteredRows;
  };
  const filteredRows = filter ? filterRows(rows) : rows;

  return (
    <PaginatedTable
      columns={columns}
      rows={filteredRows}
      sx={{ marginBottom: 4, ...sx }}
      {...props}
    />
  );
}

export function FilterableBondsTable({ title, bonds, actions, ...props }) {
  const [filtering, setFiltering] = useState(false);
  const [filter, setFilter] = useState(null);

  return (
    <>
      <SectionTitle
        title={title}
        actions={
          <>
            {filtering ? (
              <NullableTextField
                label="Filter"
                //placeholder="Filter"
                inputRef={(input) => input && input.focus()}
                sx={{
                  background: "#ffffff",
                  input: {
                    paddingTop: 1,
                    paddingBottom: 1,
                  },
                  "label[data-shrink=false]": {
                    transform: "translate(14px, 8px) scale(1)",
                  },
                }}
                value={filter}
                onChange={(value) => setFilter(value)}
              />
            ) : (
              <IconButton
                onClick={() => {
                  setFiltering(true);
                }}
              >
                <FilterList />
              </IconButton>
            )}
            {actions}
          </>
        }
      />
      <BondsTable bonds={bonds} filter={filter} {...props} />
    </>
  );
}
