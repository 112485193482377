import { Typography } from "@mui/material";

import FlexBox from "@/components/layout/FlexBox";

export default function CardTitle({ title, actions, ...props }) {
  return (
    <FlexBox center mb={3} {...props}>
      <Typography variant="cardHeader" mr="auto">
        {title}
      </Typography>
      {actions}
    </FlexBox>
  );
}
