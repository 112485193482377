import fontkit from "@pdf-lib/fontkit";
import moment from "moment";
import { createPDFAcroFields, PDFDocument, setFontAndSize } from "pdf-lib";

import Helvetica from "@/assets/fonts/Helvetica.ttf";
import assetinsurePDFFile from "@/assets/pdf/Assetinsure.pdf"; // Converted to PDFA and back
import inscapPDFFile from "@/assets/pdf/InsCap.pdf";
import veroFrontedPDFFile from "@/assets/pdf/Vero Fronted.pdf";
import veroPDFFile from "@/assets/pdf/Vero.pdf";
import {
  base64ToBytes,
  downloadFile,
  formatMoney,
  getIssuerHeaderLines,
  isBlank,
} from "@/Utils";

/*
const pdfFile = (bond.issuer_name === "Assetinsure" || bond.issuer_name === "Swiss Re") ? assetinsurePDFFile
: (bond.issuer_name === "Vero") ? veroPDFFile
: inscapPDFFile;
const customizedFile = !(bond.issuer_name === "Assetinsure" || bond.issuer_name === "Swiss Re");
*/

export async function fillApplicationForm(data, issuer, fronted, fileName) {
  //console.log("Data", data);
  //console.log("Doc", pdfDoc);
  //console.log("Form:", form);
  //console.log("Font", font);
  //console.log("Issuer", issuer);

  let pdfFile, fillFunction, defaultFontSize;

  switch (issuer.name) {
    case "Vero":
      pdfFile = fronted ? veroFrontedPDFFile : veroPDFFile;
      fillFunction = fillVeroApplicationForm;
      defaultFontSize = 9;
      break;

    case "Assetinsure":
    case "Swiss Re":
      pdfFile = assetinsurePDFFile;
      fillFunction = fillAssetinsureApplicationForm;
      defaultFontSize = 9;
      break;

    default:
      pdfFile = inscapPDFFile;
      fillFunction = fillInscapApplicationForm;
      defaultFontSize = 9;
      break;
  }

  // Create the doc, form, font
  const existingPdfBytes = await fetch(pdfFile).then((res) =>
    res.arrayBuffer(),
  );
  const pdfDoc = await PDFDocument.load(existingPdfBytes, {
    ignoreEncryption: true,
  });
  const form = pdfDoc.getForm();

  pdfDoc.registerFontkit(fontkit);
  const fontBytes = await fetch(Helvetica).then((response) =>
    response.arrayBuffer(),
  );
  const font = await pdfDoc.embedFont(fontBytes);

  // Different fill function for different forms
  await fillFunction();

  form.updateFieldAppearances(font);

  const pdfBytes = await pdfDoc.save();
  downloadFile(pdfBytes, fileName + ".pdf", "application/pdf");

  // Fill functions =========================================================================

  async function fillVeroApplicationForm() {
    fillVeroFields();

    // Inscap representing
    fillCheckGroup("Check Box 48", true, 0, 1);
    fillText("Text Field 97a", "Inscap Pty Ltd");
    fillText("Text Field 98", "Unit 5, 136 Stirling Highway");
    fillText("Text Field 99", "North Fremantle");
    fillText("Text Field 100", "WA");
    fillText("Text Field 101", "6159");
    fillText("Text Field 102", "Mark Coulsen");
    fillText("Text Field 103", "0412 902 994");
    fillText("Text Field 104", "");

    fillDateSegments(
      new Date(),
      "Text Field 105",
      "Text Field 106",
      "Text Field 107",
    );

    const signaturePage = pdfDoc.getPage(3);
    await drawSignature(data.signature, signaturePage, {
      x: 27,
      y: 711,
      width: 268,
      height: 31,
    });
    await drawSignature(data.signature, signaturePage, {
      x: 27,
      y: 392,
      width: 268,
      height: 31,
    });
  }

  async function fillAssetinsureApplicationForm() {
    fillCommonFields();

    const page2 = pdfDoc.getPage(1);
    await drawSignature(data.signature, page2, {
      x: 70,
      y: 100,
      width: 268,
      height: 31,
    });
  }

  async function fillInscapApplicationForm() {
    fillCommonFields();

    const page1 = pdfDoc.getPage(0);
    const page2 = pdfDoc.getPage(1);

    const declarationLines = [
      "The undersigned hereby declares that the information and details provided herein are full and true answers and that it is understood the",
      "information provided will be used for the evaluation of this submission by the Surety. Further, the undersigned confirms that he/she is duly",
      "authorised to sign this questionnaire for and on behalf of the applicant. I/We also acknowledge that Brokerage may be payable to your broker",
      "in relation to the issuance of this/these Bond/s/Bank Guarantee/s. The undersigned consents to the use of and the disclosure of personal",
      "information in accordance with " +
        (issuer.name || issuer.legal_name) +
        "’s privacy policy. If you accept this statement, check the box and complete the fields below.",
    ];

    drawLines(
      page1,
      getIssuerHeaderLines(issuer),
      554,
      742,
      7.2,
      10.9,
      "right",
    );
    drawLines(page2, declarationLines, 35, 240, 8, 16.2);

    function drawLines(page, lines, x, y, fontSize, lineHeight, align) {
      lines.forEach((line, lineIndex) => {
        let lineX = x;
        const lineY = y - lineHeight * lineIndex;
        if (align === "right") lineX -= font.widthOfTextAtSize(line, fontSize);
        drawTextAt(page, line, lineX, lineY, fontSize, lineHeight);
      });
    }
    function drawTextAt(page, text, x, y, fontSize, lineHeight) {
      page.moveTo(x, y);
      page.drawText(text, { size: fontSize, lineHeight: lineHeight });
    }

    await drawSignature(data.signature, page2, {
      x: 70,
      y: 100,
      width: 268,
      height: 31,
    });
  }

  function fillVeroFields() {
    /*
		fields.forEach((field) => {
			if (!field) return;
			let text = '' + field.getName();
			text = text.replace('Text Field ', '');
			text = text.replace('Check Box ', '');
			//console.log(i, "'" + field.getName() + "'", text, text.substring(0, 2), field, field instanceof PDFTextField, field instanceof PDFTextField && field.getMaxLength() , text.length);
			if (field instanceof PDFTextField) {
				if (field.getMaxLength() === undefined) {
					//console.log("Filling", field.getName(), "with", text);
					fillText(field.getName(), text);
				} else {
					if (text.length <= field.getMaxLength()) {
						//console.log("Filling", field.getName(), "with", text);
						fillText(field.getName(), text);
					} else {
						if (text.length >= 3 && text.substring(0, 2) === '10') text = 'A' + text.substring(2);
						if (text.length >= 3 && text.substring(0, 2) === '11') text = 'B' + text.substring(2);
						if (text.length >= 3 && text.substring(0, 2) === '12') text = 'C' + text.substring(2);
						//console.log("Filling", field.getName(), "with", text);
						try {
							fillText(field.getName(), text);
						} catch(e) {
							console.log("Error filling", field.getName(), text);
						}
					}
				}
			} else {
				console.log("Other:", field.getName(), field);
			}
		});
		*/

    let nameAndTitle = data.applicantContactName || "";
    if (nameAndTitle.length > 0) nameAndTitle += ", ";
    nameAndTitle += data.applicantJobTitle || "";

    fillText("Text Field 2", data.applicantContractorName);
    fillText("Text Field 3", data.applicantABN);
    fillText("Text Field 4", data.applicantBusinessAddress1);
    fillText("Text Field 5", data.applicantBusinessAddress2);
    fillText("Text Field 6", data.applicantBusinessAddressState);
    fillText("Text Field 7", data.applicantBusinessAddressPostcode);
    fillText("Text Field 8", nameAndTitle);
    fillText("Text Field 9", ""); // Phone area
    fillText("Text Field 10", data.applicantTelephone);
    fillText("Text Field 11", data.applicantContactEmail);

    data.bonds.forEach((bond) => {
      switch (bond.type.replace("Fronted ", "")) {
        case "Performance":
          fillVeroBond(
            bond,
            "Text Field 12",
            "Text Field 13",
            "Text Field 14",
            "Text Field 15",
            "Text Field 16",
            "Text Field 17",
            "Text Field 18",
            "Text Field 19",
          );
          break;
        case "Maintenance":
          fillVeroBond(
            bond,
            "Text Field 12a",
            "Text Field 13a",
            "Text Field 14a",
            "Text Field 15a",
            "Text Field 16a",
            "Text Field 17a",
            "Text Field 18a",
            "Text Field 19a",
          );
          break;
        case "Advance Payment":
          fillVeroBond(
            bond,
            "Text Field 20",
            "Text Field 21",
            "Text Field 22",
            "Text Field 23",
            "Text Field 24",
            "Text Field 25",
            "Text Field 26",
            "Text Field 27",
          );
          break;
        case "Materials":
          fillVeroBond(
            bond,
            "Text Field 28",
            "Text Field 29",
            "Text Field 30",
            "Text Field 31",
            "Text Field 32",
            "Text Field 33",
            "Text Field 34",
            "Text Field 35",
          );
          break;
        // No "Lease" in form
        default:
        case "Other":
          fillVeroBond(
            bond,
            "Text Field 54",
            "Text Field 52",
            "Text Field 55",
            "Text Field 53",
            "Text Field 56",
            "Text Field 57",
            "Text Field 58",
            "Text Field 59",
          );
          break;
      }
    });

    fillText("Text Field 60", data.contractDescription);
    fillText("Text Field 61", data.contractTitle);
    fillText("Text Field 62", data.contractLocation);

    fillMoney("Text Field 64", data.contractTotalValue, false);
    fillText("Text Field 65", data.contractLegalJurisdiction);
    fillText("Text Field 66", data.contractNumber);

    fillDateSegments(
      data.contractStartDate,
      "Text Field 67",
      "Text Field 68",
      "Text Field 69",
    );
    fillDateSegments(
      data.completionDate,
      "Text Field 70",
      "Text Field 71",
      "Text Field 72",
    );
    fillDateSegments(
      data.practicalCompletionDate,
      "Text Field 73",
      "Text Field 74",
      "Text Field 75",
    );
    fillDateSegments(
      data.finalCompletionDate,
      "Text Field 76",
      "Text Field 77",
      "Text Field 78",
    );

    fillText("Text Field 79", data.beneficiaryContractorName);
    fillText("Text Field 80", data.beneficiaryABN);
    fillText("Text Field 81", data.beneficiaryBusinessAddress1);
    fillText("Text Field 82", data.beneficiaryBusinessAddress2);
    fillText("Text Field 83", data.beneficiaryBusinessAddressState);
    fillText("Text Field 84", data.beneficiaryBusinessAddressPostcode);
    fillText("Text Field 85", data.beneficiaryPM);
    fillText("Text Field 86", ""); // Phone area
    fillText("Text Field 87", data.beneficiaryTelephone);
    fillText("Text Field 88", data.beneficiaryEmail);

    fillCheckGroup("Check Box 43", data.designObligations, 0, 1);
    fillCheckGroup("Check Box 44", data.typeBefore, 1, 0);
    fillCheckGroup("Check Box 45", data.previousContracts, 0, 1);
    fillText("Text Field 89", data.role);
    fillText("Text Field 90", data.percentageSubcontracted);
    fillCheckGroup("Check Box 46", data.wordingSpecified, 1, 0);
    fillText("Text Field 91", data.formOfContract);
    fillCheckGroup("Check Box 47", data.forceMajeure, 0, 1);
    fillText("Text Field 92", data.limitDamages);

    fillText("Text Field 96", data.declarationName);
    fillText("Text Field 97", data.declarationJobTitle);
    fillDateSegments(
      data.declarationDate,
      "Text Field 93",
      "Text Field 94",
      "Text Field 95",
    );
  }

  function fillCommonFields() {
    // Assetinsure and Inscap forms have common fields

    // Begin filling fields

    /*
		applicantContractorName,// Applicant_contractor_name
		applicantABN, // Applicant_ACN_ABN
		applicantBusinessAddress, // Business_Addr_Applicant
		applicantContactName, // Applicant_Contact_name
		applicantTelephone, // Applicant_telephone
		applicantJobTitle, // Applicant_job_title

		beneficiaryContractorName, // Beneficiary_name
		beneficiaryABN, // Benef_ACN_ABN
		beneficiaryBusinessAddress, // Benef_Business Addr
		beneficiaryPM, // Name_of_PM
		beneficiaryEmail, // Managers email address
		beneficiaryTelephone, // Benef_tel

		fronted: bond.fronted, // Bond_or_bank_G
		frontedReason, // Reason_Reqd

		bonds: ([bond].map((bond, index) => {
			if (index >= 4) return null;
			return {
				type: (bondType ? bondType.name : null), // Bond_type_no1
				value: bond.face_value, // Val_in_AUD_1
				periodFrom: bond.date_start, // Period_from_date_1
				periodTo: bond.date_finish, // Period_to_date_1
				expires: bond.expires, // Fixed Expiry Date_1
			}
		})),

		contractStartDate, // Contract_start_date
		practicalCompletionDate, // Practical_Compl_Date
		finalCompletionDate,  // Final_Compl_Date

		contractNumber, // Contract_No
		contractTotalValue, // Total_Val_Contract
		contractLocation, // Contract_location
		contractDescription, // Descr_contract_proj
		
		previousContracts, // Q_prev_contracts
		typeBefore, // Q_before
		role, // Head_or_Sub
		designObligations, // Q_design
		delayEvents, // Q_delay
		wordingSpecified, // Q_wording
		
		issuesDisputes, // Q_issues
		exceededContractSum, // Q_sum_exceeded
		replacingExisting, // Q_replacing

		defectClaims, // Q_claims
		extendedLiabilityPeriod, // Q_liab
		
		offsiteDocumentsProvided, // offsiteq_1

		declarationName, // Declaration_name
		declarationJobTitle, // Job_Title_Declaration
		declarationDate, // Decl_date
		*/

    fillText("Applicant_contractor_name", data.applicantContractorName);
    fillText("Applicant_ACN_ABN", data.applicantABN);
    fillText("Business_Addr_Applicant", data.applicantBusinessAddress);
    fillText("Applicant_Contact_name", data.applicantContactName);
    fillText("Applicant_telephone", data.applicantTelephone);
    fillText("Applicant_job_title", data.applicantJobTitle);

    fillText("Beneficiary_name", data.beneficiaryContractorName);
    fillText("Benef_ACN_ABN", data.beneficiaryABN);
    fillText("Benef_Business Addr", data.beneficiaryBusinessAddress);
    fillText("Name_of_PM", data.beneficiaryPM);
    fillText("Managers email address", data.beneficiaryEmail);
    fillText("Benef_tel", data.beneficiaryTelephone);

    fillRadios("Bond_or_bank_G", data.fronted ? "Bank" : "Bond");
    fillText("Reason_Reqd", data.frontedReason);

    for (let i = 1; i <= 4; i++) {
      if (i > data.bonds.length) {
        fillSelect("Bond_type_no" + i, "");
      } else {
        const bond = data.bonds[i - 1];

        let option = null;
        switch (bond.type.replace("Fronted ", "")) {
          case "Performance":
            option = "Performance";
            break;
          case "Maintenance":
            option = "Maintenance";
            break;
          case "Advance Payment":
            option = "Advance Payment";
            break;
          case "Materials":
            option = "Off Site Materials";
            break;
          case "Lease":
            option = "Lease";
            break;
          default:
          case "Other":
            option = "";
            break;
        }
        if (option) {
          fillSelect("Bond_type_no" + i, option);
        }

        fillMoney("Val_in_AUD_" + i, bond.value);
        fillDate("Period_from_date_" + i, bond.periodFrom);
        fillDate("Period_to_date_" + i, bond.periodTo);
        fillCheckbox("Fixed Expiry Date_" + i, bond.expires);
      }
    }

    fillDate("Contract_start_date", data.contractStartDate);
    fillDate("Practical_Compl_Date", data.practicalCompletionDate);
    fillDate("Final_Compl_Date", data.finalCompletionDate);

    fillText("Contract_No", data.contractNumber);
    fillMoney("Total_Val_Contract", data.contractTotalValue);
    fillText("Contract_location", data.contractLocation);
    fillText("Descr_contract_proj", data.contractDescription);

    fillYesNo("Q_prev_contracts", data.previousContracts);
    fillYesNo("Q_before", data.typeBefore);
    fillSelect("Head_or_Sub", data.role);
    fillYesNo("Q_design", data.designObligations);
    fillYesNo("Q_delay", data.delayEvents);
    fillYesNo("Q_wording", data.wordingSpecified);

    fillYesNo("Q_issues", data.issuesDisputes);
    fillYesNo("Q_sum_exceeded", data.exceededContractSum);
    fillYesNo("Q_replacing", data.replacingExisting);

    fillYesNo("Q_claims", data.defectClaims);
    fillYesNo("Q_liab", data.extendedLiabilityPeriod);

    fillCheckbox("offsiteq_1", data.offsiteDocumentsProvided);

    fillText("Declaration_name", data.declarationName);
    fillText("Job_Title_Declaration", data.declarationJobTitle);
    fillDate("Decl_date", data.declarationDate);
  }

  // PDF helper functions =========================================================================

  function fillText(pdfField, value) {
    if (!isBlank(value)) {
      const field = form.getField(pdfField);

      field.setText(value);

      let rect;
      field.acroField.dict.dict.forEach((entry, key) => {
        if (key.encodedName === "/Rect") {
          const left = entry.array[0].numberValue;
          const top = entry.array[1].numberValue;
          const right = entry.array[2].numberValue;
          const bottom = entry.array[3].numberValue;
          rect = {
            x: top,
            y: left,
            width: right - left,
            height: bottom - top,
          };
        }
      });

      let keepAutosize = true;
      if (rect) {
        const textWidth = font.widthOfTextAtSize(value, defaultFontSize);
        const textHeight = font.heightAtSize(defaultFontSize);
        const availableLines = Math.floor(rect.height / textHeight);
        const linesRequired = textWidth / rect.width;
        if (linesRequired <= availableLines) {
          // autoSize is not required. Set explicit size
          keepAutosize = false;
        }
      }

      if (!keepAutosize) {
        if (!field.acroField.getDefaultAppearance()) {
          field.acroField.setDefaultAppearance(
            setFontAndSize("Helvetica", defaultFontSize).toString(),
          );
        }
        field.setFontSize(defaultFontSize);
      }
    }
  }
  function fillSelect(pdfField, value) {
    if (!isBlank(value)) {
      const field = form.getField(pdfField);
      field.setOptions([value]);
    }
  }
  function fillRadios(pdfField, value) {
    if (!isBlank(value)) form.getField(pdfField).select(value);
  }
  function fillCheckbox(pdfField, value) {
    if (!isBlank(value)) form.getField(pdfField)[value ? "check" : "uncheck"]();
  }
  function fillYesNo(pdfField, value) {
    if (!isBlank(value)) fillRadios(pdfField, value ? "Yes" : "No");
  }
  function fillDate(pdfField, value) {
    if (!isBlank(value))
      fillText(pdfField, "" + moment(value).format("DD-MMM-yyyy"));
  }
  function fillMoney(pdfField, value, includeDollarSign) {
    if (!isBlank(value))
      fillText(pdfField, formatMoney(value, 2, includeDollarSign));
  }
  function fillCheckGroup(pdfField, value, onKidIndex, offKidIndex) {
    if (onKidIndex === undefined && offKidIndex === undefined) {
      onKidIndex = 0;
      offKidIndex = 1;
    }
    const field = form.getField(pdfField);
    const kids = createPDFAcroFields(field.acroField.Kids()).map((_) => _[0]);
    const kid = kids[value ? onKidIndex : offKidIndex];
    kid.setValue(kid.getOnValue());
  }
  function fillDateSegments(date, dayTF, monthTF, yearTF) {
    if (date) {
      const mom = new moment(date);
      fillText(dayTF, mom.format("D"));
      fillText(monthTF, mom.format("M"));
      fillText(yearTF, mom.format("YY"));
    }
  }

  function fillVeroBond(
    bond,
    currencyTF,
    valueTF,
    fromDayTF,
    fromMonthTF,
    fromYearTF,
    toDayTF,
    toMonthTF,
    toYearTF,
  ) {
    fillText(currencyTF, "AUD");
    fillMoney(valueTF, bond.value, false);
    fillDateSegments(bond.periodFrom, fromDayTF, fromMonthTF, fromYearTF);
    fillDateSegments(bond.periodTo, toDayTF, toMonthTF, toYearTF);
  }

  async function drawSignature(signature, page, dimensions) {
    if (!signature) return;

    const bytes = await base64ToBytes(signature);
    const signatureImage = await pdfDoc.embedPng(bytes);

    page.drawImage(signatureImage, dimensions);
  }
}
