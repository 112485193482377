import { useContext, useState } from "react";
import { HighlightOffOutlined } from "@mui/icons-material";
import { IconButton, Link, Typography } from "@mui/material";
import moment from "moment";

import API from "@/API";
import FlexBox from "@/components/layout/FlexBox";
import UserContext from "@/contexts/UserContext";
import { useDelete, useFileGet } from "@/hooks/useAPI";
import useHover from "@/hooks/useHover";
import { downloadPdfFromArrayBuffer } from "@/Utils";

export default function BondFileLink({ file, adminMode }) {
  const { user } = useContext(UserContext);

  const [deleting, setDeleting] = useState(false);
  const [hovering, bindHover] = useHover();

  const [downloadBondFile] = useFileGet(
    file ? API.downloadBondFile(user, file.id_bond, file.id_file) : null,
  );
  const [deleteBondFile] = useDelete(
    adminMode
      ? file
        ? API.deleteBondFile(user, file.id_bond, file.id_file)
        : null
      : null,
  );

  function downloadBond() {
    downloadBondFile()
      .then((responseData) => {
        const arrayBuffer = responseData.data;
        downloadPdfFromArrayBuffer(arrayBuffer, file.filename);
      })
      .catch((error) => {
        console.log("Download bond file error", error);
      });
  }

  function deleteBond() {
    setDeleting(true);
    deleteBondFile({
      bond: {
        id_bond: file.id_bond,
      },
      file: {
        id_file: file.id_file,
      },
    })
      .then((responseData) => {
        //console.log("Deleted");
      })
      .catch((error) => {
        console.log("Delete bond file error", error);
      });
  }

  return (
    <FlexBox
      center
      {...bindHover}
      sx={deleting ? { opacity: 0.5, pointerEvents: "none" } : null}
    >
      <FlexBox align="end" sx={{ flex: "1 1 auto", width: 0 }}>
        <Typography variant="caption" mr={1}>
          {moment(file.timestamp_created).format("DD/MM/YY")}
        </Typography>
        <Typography
          sx={{
            flex: "1 1 auto",
            width: 0,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <Link
            sx={{ cursor: "pointer" }}
            title={file.filename}
            onClick={downloadBond}
          >
            {file.filename}
          </Link>
        </Typography>
      </FlexBox>
      {adminMode && hovering && !deleting && (
        <IconButton sx={{ my: -2 }} onClick={deleteBond}>
          <HighlightOffOutlined />
        </IconButton>
      )}
    </FlexBox>
  );
}
