import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Add } from "@mui/icons-material";
import {
  Alert,
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  Fab,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router";

import API from "@/API";
import {
  BOND_STATUS_QUOTE,
  BOND_TYPE_FRONTED_MAINTENANCE,
  BOND_TYPE_FRONTED_PERFORMANCE,
  BOND_TYPE_MAINTENANCE,
  BOND_TYPE_PERFORMANCE,
  bondTypeId,
  calculateBondStatusUsage,
  doBondCalculations,
  generateNewBond,
  statusId,
} from "@/BondFunctions";
import CentreLoader from "@/components/common/CentreLoader";
import DatePicker from "@/components/common/DatePicker";
import {
  DollarTextField,
  PercentageTextField,
} from "@/components/common/FormattedTextField";
import NullableTextField from "@/components/common/NullableTextField";
import PageTitle from "@/components/common/PageTitle";
import CentreWrapper from "@/components/layout/CentreWrapper";
import BondEditor from "@/components/modules/BondEditor";
import BondsSummary from "@/components/modules/BondsSummary";
import CapacitySummary from "@/components/modules/CapacitySummary";
import SaveDrawer from "@/components/modules/SaveDrawer";
import StaticDataContext from "@/contexts/StaticDataContext";
import UserContext from "@/contexts/UserContext";
import { ENV } from "@/Environment";
import { useGet, usePost } from "@/hooks/useAPI";
import { checkFormValidity, searchByField } from "@/Utils";

export function AdminBondCalculatorPage() {
  const { user } = useContext(UserContext);

  const [clientsData] = useGet(API.getClients(user));
  const clients = clientsData && clientsData.clients;

  // In two parts because it needs to be selected instantly and then fetched
  const [clientId, setClientId] = useState(ENV.quickStart ? 1 : null);

  function selectClient(id_client) {
    setClientId(id_client);
  }

  const [clientData, clientDataLoading] = useGet(
    clientId ? API.getClient(user, clientId) : null,
  );
  const { client, facilitys, indemnifiers } =
    (!clientDataLoading && clientData) || {};

  const [postBond] = usePost(API.postBond(user));

  return (
    <BondCalculatorPage
      adminMode={true}
      canSeeClientTotals={true}
      clients={clients}
      clientId={clientId}
      client={client}
      facilitys={facilitys}
      indemnifiers={indemnifiers}
      selectClient={selectClient}
      postBond={postBond}
    />
  );
}

export function UndercoverBondCalculatorPage() {
  const { user } = useContext(UserContext);

  const [clientData, clientDataLoading] = useGet(
    API.getClient(user, user.id_client),
  );
  const { client, facilitys, indemnifiers } =
    (!clientDataLoading && clientData) || {};

  const [postBond] = usePost(API.postBond(user));

  return (
    <BondCalculatorPage
      adminMode={false}
      canSeeClientTotals={true}
      clientId={user.id_client}
      client={client}
      facilitys={facilitys}
      indemnifiers={indemnifiers}
      postBond={postBond}
    />
  );
}

export function ClientBondCalculatorPage() {
  const { user } = useContext(UserContext);

  const [clientData, clientDataLoading] = useGet(API.getClient(user));
  const { client, facilitys, indemnifiers } =
    (!clientDataLoading && clientData) || {};

  const [postBond] = usePost(API.postBond(user));

  const canSeeClientTotals = user && !user.isSubsidiary;

  return (
    <BondCalculatorPage
      adminMode={false}
      canSeeClientTotals={canSeeClientTotals}
      clientId={client ? client.id_client : null}
      client={client}
      facilitys={facilitys}
      indemnifiers={indemnifiers}
      postBond={postBond}
    />
  );
}

function BondCalculatorPage({
  adminMode,
  canSeeClientTotals,
  clients,
  clientId,
  client,
  facilitys,
  indemnifiers,
  selectClient,
  postBond,
}) {
  const navigate = useNavigate();

  const staticData = useContext(StaticDataContext);

  const [bonds, setBonds] = useState();

  const [clientAssignedToBonds, setClientAssignedToBonds] = useState(false);
  const [details, setDetails] = useState({
    principal: "",
    description: "",
    invoice: null,
    id_bond_status: null,
    date_start: new Date(),
    date_performance: null,
    date_maintenance: null,
  });

  const [createProject, setCreateProject] = useState(true);

  const [submitting, setSubmitting] = useState(false);

  const scrollRef = useRef();
  const formRef = useRef();

  const updateDetails = useCallback(
    (newValues) => {
      setDetails({
        ...details,
        ...newValues,
      });
    },
    [details, setDetails],
  );

  const addNewBond = useCallback(() => {
    const newBonds = bonds ? bonds.concat() : [];
    newBonds.push(generateNewBond(staticData));
    setBonds(newBonds);
  }, [bonds, staticData, setBonds]);

  // Populate bond status when static data arrives
  useEffect(() => {
    if (staticData && !details.id_bond_status)
      updateDetails({
        id_bond_status: statusId(BOND_STATUS_QUOTE, staticData),
      });
  }, [staticData, details, updateDetails]);

  // Create a bond if none exists yet when clients arrive
  useEffect(() => {
    if (!bonds && staticData) addNewBond();
  }, [bonds, staticData, addNewBond]);

  // When a new client is set, make sure the init status is reset
  useEffect(() => {
    setClientAssignedToBonds(false);
  }, [client, setClientAssignedToBonds]);

  // Update bonds with new client
  useEffect(
    (a) => {
      if (client) {
        if (clientAssignedToBonds) return;
        setClientAssignedToBonds(true);

        if (bonds) {
          const updatedBonds = [];
          bonds.forEach((bond) => {
            const updatedBond = {
              ...bond,
              id_indemnifier: null,
              id_facility: null,
            };
            const facility = searchByField(
              facilitys,
              "id_facility",
              updatedBond.id_facility,
            );
            doBondCalculations(updatedBond, facility, staticData);
            updatedBonds.push(updatedBond);
          });
          setBonds(updatedBonds);
        }
      }
    },
    [
      client,
      facilitys,
      bonds,
      staticData,
      clientAssignedToBonds,
      setClientAssignedToBonds,
    ],
  );

  let calculatedPerformanceDate = null;
  let calculatedMaintenanceDate = null;
  if (bonds) {
    bonds.forEach((bond) => {
      switch (bond.id_bond_type) {
        case bondTypeId(BOND_TYPE_PERFORMANCE, staticData):
        case bondTypeId(BOND_TYPE_FRONTED_PERFORMANCE, staticData):
          calculatedPerformanceDate = bond.date_finish;
          break;
        case bondTypeId(BOND_TYPE_MAINTENANCE, staticData):
        case bondTypeId(BOND_TYPE_FRONTED_MAINTENANCE, staticData):
          calculatedMaintenanceDate = bond.date_finish;
          break;
        default:
          break;
      }
    });
  }
  if (!calculatedPerformanceDate) calculatedPerformanceDate = new Date();
  if (!calculatedMaintenanceDate) calculatedMaintenanceDate = new Date();

  function onBondUpdated(bond, index) {
    const updatedBonds = bonds.concat();
    updatedBonds[index] = bond;
    setBonds(updatedBonds);
  }

  function onRemove(bond, index) {
    const updatedBonds = bonds.concat();
    updatedBonds.splice(index, 1);
    setBonds(updatedBonds);
  }

  function onDuplicate(bond) {
    const updatedBonds = bonds.concat();
    updatedBonds.push({ ...bond });
    setBonds(updatedBonds);
    scrollToNewBond();
  }

  function onAddBond() {
    if (staticData) {
      addNewBond();
      scrollToNewBond();
    }
  }

  function submit() {
    if (checkFormValidity(formRef.current)) {
      const submittingBonds = [];
      bonds.forEach((bond) => {
        const b = {
          ...bond,
          id_bond_status: details.id_bond_status,
          invoice: details.invoice,
          project: details.project,
          principal: details.principal,
          id_client: clientId,
        };
        submittingBonds.push(b);
      });

      const project = createProject
        ? {
            name: details.project,
            principal: details.principal,
            id_project_type: details.id_project_type,
            id_principal_type: details.id_principal_type,
            id_client: clientId,
            id_issuer: details.id_issuer,

            date_start: details.date_start,
            date_performance: null,
            date_maintenance: null,

            id_price_structure: details.id_price_structure,

            comment: null,
          }
        : null;

      const margin = createProject
        ? {
            date: details.date_start,
            project_value: details.original_project_value,
            project_margin: details.project_margin || null,
            revised: false,
            client_initiated: !adminMode,
          }
        : null;

      const payload = { bonds: submittingBonds };
      if (margin) payload.project_margin = margin;
      if (project) payload.project = project;

      setSubmitting(true);
      postBond(payload)
        .then((responseData) => {
          setSubmitting(false);
          navigate("/");
        })
        .catch((error) => {
          console.log("Post bond error", error);
          setSubmitting(false); // TODO: remove?
        });
    }
  }

  function scrollToNewBond() {
    setTimeout(() => {
      window.scrollTo({
        behavior: "smooth",
        top: scrollRef.current.offsetTop - 64,
      });
    }, 1);
  }

  let valid = true;
  if (!clientId) valid = false;
  if (bonds)
    bonds.forEach((bond) => {
      if (!bond.id_bond_type) valid = false;
      if (!bond.id_facility) valid = false;
    });

  let capacityExceeded = false;
  if (canSeeClientTotals && bonds)
    bonds.forEach((bond) => {
      const facility =
        facilitys && searchByField(facilitys, "id_facility", bond.id_facility);
      if (facility) {
        const { pending, active } = calculateBondStatusUsage(
          facility.bond_status_totals,
          staticData.bond_statuses,
        );
        if (pending + active + bond.face_value > facility.limit)
          capacityExceeded = true;
      }
    });

  return (
    <>
      <PageTitle title="Bond calculator" />

      <Grid container columnSpacing={2}>
        <Grid item xs={12} md={8} mb={2}>
          <form ref={formRef}>
            {staticData && (clients || !adminMode) && bonds ? (
              <>
                <Card sx={{ marginBottom: 2 }}>
                  <CardContent variant="hero">
                    <Typography variant="h4">Details</Typography>

                    <Grid
                      container
                      columnSpacing={2}
                      rowSpacing={2}
                      alignItems="center"
                    >
                      {adminMode && (
                        <>
                          <Grid item xs={12} md={6}>
                            <TextField
                              label="Client"
                              fullWidth
                              required
                              value={clientId || ""}
                              select
                              onChange={(event) => {
                                selectClient(event.target.value);
                              }}
                            >
                              {clients.map((client) => {
                                return (
                                  <MenuItem
                                    key={client.id_client}
                                    value={client.id_client}
                                  >
                                    {client.name}
                                  </MenuItem>
                                );
                              })}
                            </TextField>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <NullableTextField
                              label="Invoice Number"
                              type="text"
                              fullWidth
                              value={details.invoice}
                              onChange={(value) => {
                                updateDetails({ invoice: value });
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              label="Status"
                              fullWidth
                              value={details.id_bond_status || ""}
                              select
                              onChange={(event) => {
                                updateDetails({
                                  id_bond_status: event.target.value,
                                });
                              }}
                            >
                              {staticData.bond_statuses.map((bondStatus) => {
                                return (
                                  <MenuItem
                                    key={bondStatus.id_bond_status}
                                    value={bondStatus.id_bond_status}
                                  >
                                    {bondStatus.name}
                                  </MenuItem>
                                );
                              })}
                            </TextField>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormControlLabel
                              label="Create Project"
                              control={
                                <Checkbox
                                  checked={createProject}
                                  onChange={(event) => {
                                    setCreateProject(event.target.checked);
                                  }}
                                  sx={{ marginLeft: 1 }}
                                />
                              }
                            />
                          </Grid>

                          <Grid item xs={12} md={12}>
                            <Divider />
                          </Grid>
                        </>
                      )}

                      <Grid item xs={12} md={12}>
                        <NullableTextField
                          label="Project"
                          type="text"
                          fullWidth
                          required
                          value={details.project}
                          onChange={(value) => {
                            updateDetails({ project: value });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <NullableTextField
                          label="Principal"
                          type="text"
                          fullWidth
                          required
                          value={details.principal}
                          onChange={(value) => {
                            updateDetails({ principal: value });
                          }}
                        />
                      </Grid>
                      {createProject && (
                        <>
                          <Grid item xs={12} md={6}>
                            <TextField
                              label="Project Type"
                              required
                              fullWidth
                              value={details.id_project_type || ""}
                              select
                              onChange={(event) => {
                                updateDetails({
                                  id_project_type: event.target.value,
                                });
                              }}
                            >
                              {staticData.project_types.map((projectType) => {
                                return (
                                  <MenuItem
                                    key={projectType.id_project_type}
                                    value={projectType.id_project_type}
                                  >
                                    {projectType.name}
                                  </MenuItem>
                                );
                              })}
                            </TextField>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              label="Principal Type"
                              required
                              fullWidth
                              value={details.id_principal_type || ""}
                              select
                              onChange={(event) => {
                                updateDetails({
                                  id_principal_type: event.target.value,
                                });
                              }}
                            >
                              {staticData.principal_types.map(
                                (principalType) => {
                                  return (
                                    <MenuItem
                                      key={principalType.id_principal_type}
                                      value={principalType.id_principal_type}
                                    >
                                      {principalType.name}
                                    </MenuItem>
                                  );
                                },
                              )}
                            </TextField>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              label="Issuer"
                              required
                              fullWidth
                              value={details.id_issuer || ""}
                              select
                              onChange={(event) => {
                                updateDetails({
                                  id_issuer: event.target.value,
                                });
                              }}
                            >
                              {staticData.issuers.map((issuer) => {
                                return issuer.can_issue_bonds ? (
                                  <MenuItem
                                    key={issuer.id_issuer}
                                    value={issuer.id_issuer}
                                  >
                                    {issuer.name}
                                  </MenuItem>
                                ) : null;
                              })}
                            </TextField>
                          </Grid>

                          <Grid item xs={12} md={12}>
                            <Divider />
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <DatePicker
                              value={details.date_start}
                              label={"Start Date"}
                              onChange={(value) => {
                                updateDetails({ date_start: value });
                              }}
                            />
                          </Grid>

                          <Grid item xs={12} md={12}>
                            <Divider />
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <DollarTextField
                              label="Project Value"
                              fullWidth
                              required
                              value={details.original_project_value}
                              onChange={(numericValue) => {
                                updateDetails({
                                  original_project_value: numericValue,
                                });
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <PercentageTextField
                              label="Project Margin"
                              fullWidth
                              value={details.project_margin}
                              onChange={(numericValue) => {
                                updateDetails({ project_margin: numericValue });
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              label="Price Structure"
                              required
                              fullWidth
                              value={details.id_price_structure || ""}
                              select
                              onChange={(event) => {
                                updateDetails({
                                  id_price_structure: event.target.value,
                                });
                              }}
                            >
                              {staticData.price_structures.map(
                                (priceStructure) => {
                                  return (
                                    <MenuItem
                                      key={priceStructure.id_price_structure}
                                      value={priceStructure.id_price_structure}
                                    >
                                      {priceStructure.name}
                                    </MenuItem>
                                  );
                                },
                              )}
                            </TextField>
                          </Grid>

                          <Grid item xs={12} md={12}>
                            <Divider />
                          </Grid>

                          <Grid item xs={12} md={12}>
                            <NullableTextField
                              label="Comments"
                              fullWidth
                              type="text"
                              multiline
                              rows={5}
                              value={details.comment || ""}
                              onChange={(value) => {
                                updateDetails({ comment: value });
                              }}
                            />
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </CardContent>
                </Card>

                {bonds.map((bond, index) => {
                  return (
                    <BondEditor
                      key={index}
                      label={"Bond " + (index + 1)}
                      staticData={staticData}
                      clientId={clientId}
                      client={client}
                      facilitys={facilitys}
                      indemnifiers={indemnifiers}
                      bond={bond}
                      isOnlyBond={bonds.length === 1}
                      onBondUpdated={(bond) => {
                        onBondUpdated(bond, index);
                      }}
                      onRemove={(bond) => {
                        onRemove(bond, index);
                      }}
                      onDuplicate={(bond) => {
                        onDuplicate(bond);
                      }}
                      adminMode={adminMode}
                    />
                  );
                })}
                <div ref={scrollRef} />
                <CentreWrapper>
                  <Fab color="primary" aria-label="add" onClick={onAddBond}>
                    <Add />
                  </Fab>
                </CentreWrapper>
              </>
            ) : (
              <CentreLoader />
            )}
          </form>
        </Grid>

        <Grid item xs={12} md={4}>
          <BondsSummary
            bonds={bonds}
            facilitys={facilitys}
            staticData={staticData}
          />

          {canSeeClientTotals &&
            bonds &&
            facilitys &&
            facilitys.map((facility) => {
              const facilityBonds = bonds.filter(
                (bond) => bond.id_facility === facility.id_facility,
              );
              return facilityBonds.length > 0 ? (
                <CapacitySummary
                  key={facility.id_facility}
                  bonds={facilityBonds}
                  facility={facility}
                  staticData={staticData}
                />
              ) : null;
            })}
        </Grid>
      </Grid>

      <SaveDrawer
        open={valid}
        actions={
          <Button variant="contained" disabled={submitting} onClick={submit}>
            Submit
          </Button>
          /*<Button variant="outlined" disabled={submitting} onClick={revert}>Cancel</Button>*/
        }
      >
        {capacityExceeded && (
          <Alert severity="error" sx={{ marginTop: -1, marginBottom: -1 }}>
            Bond capacity exceeded
          </Alert>
        )}
      </SaveDrawer>
    </>
  );
}
