import { Stack } from "@mui/material";

import CardTitle from "@/components/common/CardTitle";
import FacilityFileLink from "@/components/common/FacilityFileLink";
import FileUpload from "@/components/modules/FileUpload";

export default function FacilityFiles({
  adminMode,
  facility,
  files,
  postFileUpload,
}) {
  return (
    <>
      <CardTitle title="Deed of indemnity" />

      {files && files.length > 0 && (
        <Stack mb={2} spacing={1}>
          {files.map((file) => {
            return (
              <FacilityFileLink
                key={file.id_file}
                file={file}
                adminMode={adminMode}
              />
            );
          })}
        </Stack>
      )}

      {adminMode && (
        <FileUpload
          postFileUpload={postFileUpload}
          data={{
            id_facility: facility.id_facility,
            id_client: facility.id_client,
          }}
        />
      )}
    </>
  );
}
