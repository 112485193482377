import { useContext, useEffect, useState } from "react";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import deepEqual from "deep-equal";
import moment from "moment";

import API from "@/API";
import DatePicker from "@/components/common/DatePicker";
import DownloadButton from "@/components/common/DownloadButton";
import FlexBox from "@/components/layout/FlexBox";
import LayoutContext from "@/contexts/LayoutContext";
import UserContext from "@/contexts/UserContext";
import { useGet } from "@/hooks/useAPI";
import { downloadStatementSpreadsheet } from "@/Spreadsheets";

export default function StatementDownloader({
  adminMode,
  client,
  facilitys,
  ...props
}) {
  const { wideLayout } = useContext(LayoutContext);

  const { user } = useContext(UserContext);

  const start = moment("1900-01-01");
  const [startDate, setStartDate] = useState(start.toDate());
  const [endDate, setEndDate] = useState(new Date());

  const [issuedOnly, setIssuedOnly] = useState(false);
  const [downloadingStatement, setDownloadingStatement] = useState(false);

  const [dateRangeStatementData, , , getDateRangeStatement] = useGet(
    API.getStatement(user, client.id_client, startDate, endDate, issuedOnly),
    true,
  );

  function downloadDateRangeStatement() {
    if (downloadingStatement || !client || !facilitys) return;

    setDownloadingStatement(true);
    try {
      getDateRangeStatement();

      if (dateRangeStatementData?.dates && dateRangeStatementData?.bonds) {
        downloadStatementSpreadsheet(
          new Date(dateRangeStatementData.dates.date_end),
          dateRangeStatementData.bonds,
          client,
          facilitys,
        );
      }
    } catch (error) {
      console.error("Error downloading statement:", error);
    } finally {
      setDownloadingStatement(false);
    }
  }

  return (
    <>
      <FlexBox center mb={2} sx={{ flexWrap: "wrap" }}>
        <Typography mr={4}>Generate from date range</Typography>
        <FormControlLabel
          label="Issued only"
          control={
            <Checkbox
              checked={issuedOnly}
              onChange={(event) => {
                setIssuedOnly(event.target.checked);
              }}
            />
          }
        />
      </FlexBox>
      <Grid container columnSpacing={2} rowSpacing={2}>
        <Grid item xs={6} md={3}>
          <DatePicker
            value={startDate}
            label={"Start date"}
            onChange={(value) => {
              setStartDate(value);
            }}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <DatePicker
            value={endDate}
            label={"End date"}
            onChange={(value) => {
              setEndDate(value);
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FlexBox center sx={{ height: "100%", flexWrap: "wrap" }}>
            <DownloadButton
              sx={{ minWidth: "226px", marginRight: 2 }}
              onClick={downloadDateRangeStatement}
              disabled={downloadingStatement}
            >
              {downloadingStatement ? "Downloading..." : "Download statement"}
            </DownloadButton>
          </FlexBox>
        </Grid>
      </Grid>
    </>
  );
}
