import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";

import API from "@/API";
import AddButton from "@/components/common/AddButton";
import CentreLoader from "@/components/common/CentreLoader";
import ContactsTable from "@/components/modules/tables/ContactsTable";
import {
  CLIENT_DATAROOM_ID,
  CLIENT_PRIMARY_ID,
  CLIENT_SUBSIDIARY_ID,
} from "@/contexts/StaticDataContext";
import UserContext from "@/contexts/UserContext";
import { useGet } from "@/hooks/useAPI";

export default function AdminContactsPage() {
  const { user } = useContext(UserContext);

  const navigate = useNavigate();

  const [contactsData] = useGet(API.getContacts(user));

  const [dataroomContacts, setDataroomContacts] = useState([]);
  const [clientContacts, setClientContacts] = useState([]);
  const [orphanContacts, setOrphanContacts] = useState([]);

  useEffect(() => {
    if (contactsData && contactsData.contacts) {
      const d = [];
      const c = [];
      const o = [];

      contactsData.contacts.forEach((contact) => {
        if (contact.user) {
          if (contact.user.groups.indexOf(CLIENT_DATAROOM_ID) >= 0) {
            d.push(contact);
          } else if (contact.user.groups.indexOf(CLIENT_PRIMARY_ID) >= 0) {
            c.push(contact);
          } else if (contact.user.groups.indexOf(CLIENT_SUBSIDIARY_ID) >= 0) {
            c.push(contact);
          } else {
            // likely admin
          }
        } else {
          if (contact.id_client) {
            c.push(contact);
          } else {
            o.push(contact);
          }
        }
      });

      setDataroomContacts(d);
      setClientContacts(c);
      setOrphanContacts(o);
    }
  }, [contactsData, setDataroomContacts, setClientContacts, setOrphanContacts]);

  return (
    <>
      {contactsData &&
      (contactsData.contacts.length === 0 ||
        dataroomContacts.length > 0 ||
        clientContacts.length > 0 ||
        orphanContacts.length > 0) ? (
        <>
          {/*<PageTitle title="Contacts"/>*/}

          <ContactsTable
            title="Dataroom Users"
            contacts={dataroomContacts}
            navigate={navigate}
            actions={<AddButton to="/contact/add">Add dataroom user</AddButton>}
          />

          {orphanContacts && orphanContacts.length > 0 && (
            <ContactsTable
              title="Unassigned Contacts"
              contacts={orphanContacts}
              navigate={navigate}
            />
          )}

          <ContactsTable
            title="Client Contacts"
            contacts={clientContacts}
            navigate={navigate}
          />
        </>
      ) : (
        <CentreLoader />
      )}
    </>
  );
}
