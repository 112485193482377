import { useCallback, useEffect, useState } from "react";

import NullableTextField from "@/components/common/NullableTextField";
import {
  formatMoney,
  formatPercentage,
  isBlank,
  sanitizeNumeric,
  stripNonNumeric,
} from "@/Utils";

export function DollarTextField({ ...rest }) {
  return (
    <FormattedTextField
      acceptedSymbols={["$", "-", "."]}
      formatFunc={formatMoney}
      {...rest}
    />
  );
}

export function PercentageTextField({ ...rest }) {
  return (
    <FormattedTextField
      acceptedSymbols={["%", "-", "."]}
      formatFunc={formatPercentage}
      {...rest}
    />
  );
}

export default function FormattedTextField({
  onChange,
  value,
  clearable,
  maxDecimalPlaces,
  acceptedSymbols,
  formatFunc,
  ...props
}) {
  if (isBlank(maxDecimalPlaces)) maxDecimalPlaces = 2;
  const roundingMultiplier = Math.pow(10, maxDecimalPlaces);

  const formatValue = useCallback(
    (value) => {
      if (!value && value !== 0) return "";
      return formatFunc ? formatFunc(value) : value;
    },
    [formatFunc],
  );

  const [internalValue, setInternalValue] = useState(value);
  const [formattedValue, setFormattedValue] = useState(formatValue(value));

  useEffect(() => {
    if (value !== internalValue) {
      setInternalValue(value);
      setFormattedValue(formatValue(value));
    }
  }, [value, internalValue, formatValue]);

  if (clearable && props.placeholder) props.InputLabelProps = { shrink: true };

  return (
    <NullableTextField
      value={formattedValue}
      {...props}
      onChange={(value, event) => {
        let numericValue = stripNonNumeric(value);
        const nan = isNaN(numericValue);

        let onlyAcceptedSymbols = false;

        if (nan && acceptedSymbols) {
          const stripped = ("" + value).replace(/ /g, "");
          if (stripped.length > 0) {
            onlyAcceptedSymbols = true;
            for (let i = 0; i < stripped.length; i++) {
              if (acceptedSymbols.indexOf(stripped.charAt(i)) < 0) {
                onlyAcceptedSymbols = false;
              }
            }
          }
        }

        if (onlyAcceptedSymbols) {
          setInternalValue(0);
          setFormattedValue(value);
          if (internalValue !== 0 && onChange) onChange(0, event);
        } else {
          let changed = false;

          if (nan) numericValue = clearable ? null : 0;
          else
            numericValue =
              Math.round(numericValue * roundingMultiplier) /
              roundingMultiplier;

          if (internalValue !== numericValue) {
            setInternalValue(numericValue);
            changed = true;
          }

          const sanitizedValue =
            numericValue === null ? "" : sanitizeNumeric(value);
          setFormattedValue(sanitizedValue);

          if (changed && onChange) onChange(numericValue, event);
        }
      }}
      onBlur={(event) => {
        setFormattedValue(formatValue(internalValue));
      }}
    />
  );
}
