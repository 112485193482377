import { useState } from "react";

export default function useInput(initialValue, required) {
  const [value, setValue] = useState(initialValue);

  return {
    value,
    setValue,
    reset: () => setValue(""),
    bind: {
      value,
      onChange: (event) => {
        setValue(event.target.value);
      },
      required: required,
    },
  };
}

export function useNullableInput(initialValue, required) {
  const [value, setValue] = useState(initialValue);

  return {
    value,
    setValue,
    reset: () => setValue(""),
    bind: {
      value,
      onChange: (value) => {
        setValue(value);
      },
      required: required,
    },
  };
}
