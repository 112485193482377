import React, { useContext } from "react";
import { useNavigate } from "react-router";

import { calculateBondStatusUsage } from "@/BondFunctions";
import FilterableTable from "@/components/modules/tables/FilterableTable";
import StaticDataContext from "@/contexts/StaticDataContext";
import { formatMoney } from "@/Utils";

export default function ClientsTable({ title, clients, showTotals, actions }) {
  showTotals = showTotals !== false;

  const navigate = useNavigate();

  const staticData = useContext(StaticDataContext);

  const columns = [
    { key: "code", cell: "Code", width: 100 },
    { key: "name", cell: "Name", minWidth: 200 },
  ].concat(
    showTotals
      ? [
          { key: "premium", cell: "Premium in applications", align: "right" },
          { key: "pending", cell: "Quotes & applications", align: "right" },
          { key: "active", cell: "Utilised", align: "right" },
          { key: "capacity", cell: "Total capacity", align: "right" },
        ]
      : [],
  );

  const rows = [];
  clients.forEach((client) => {
    if (showTotals) {
      const { pending, active } = calculateBondStatusUsage(
        client.totals.total_bond_face_values,
        staticData.bond_statuses,
      );
      const premium = client.totals.total_bond_fees.total_client_bond_fees;
      rows.push({
        key: client.id_client,
        cells: [
          client.code || "",
          client.name || "",
          premium ? formatMoney(premium) : "-",
          pending ? formatMoney(pending) : "-",
          active ? formatMoney(active) : "-",
          client.totals.total_client_facility_limits !== undefined &&
          !isNaN(client.totals.total_client_facility_limits)
            ? formatMoney(client.totals.total_client_facility_limits)
            : "-",
        ],
        onClick: () => {
          navigate("/client/" + client.id_client);
        },
      });
    } else {
      rows.push({
        key: client.id_client,
        cells: [client.code || "", client.name || ""],
        onClick: () => {
          navigate("/client/" + client.id_client);
        },
      });
    }
  });

  return (
    <FilterableTable
      title={title}
      actions={actions}
      columns={columns}
      rows={rows}
      sx={{ marginBottom: 4 }}
    />
  );
}
