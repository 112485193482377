import { useContext } from "react";
import { Box, Typography, useTheme } from "@mui/material";

import ResponsiveCanvas from "@/components/common/ResponsiveCanvas";
import FlexBox from "@/components/layout/FlexBox";
import LayoutContext from "@/contexts/LayoutContext";
import { formatNumber } from "@/Utils";

export default function RatioGraph({
  title,
  values,
  colors,
  min,
  max,
  gradient: gradientPoints,
  step,
  sx,
}) {
  const { wideLayout } = useContext(LayoutContext);

  const theme = useTheme();

  return (
    <FlexBox column sx={{ marginTop: 2, marginBottom: 2 }}>
      <Box
        sx={{
          width: "100%",
          maxWidth: "550px",
          paddingRight: wideLayout ? "10%" : "10px",
        }}
      >
        <Typography variant="tableHeader">{title}</Typography>
        <Box>
          <ResponsiveCanvas
            width="100%"
            height="300px"
            redraw={(canvas) => {
              drawToCanvas(canvas, values, colors, min, max, step, theme);
            }}
          />
        </Box>
      </Box>
    </FlexBox>
  );
}

function drawToCanvas(canvas, values, colors, min, max, step, theme) {
  const bounds = canvas.getBoundingClientRect();
  const width = (canvas.width = bounds.width);
  const height = (canvas.height = bounds.height);

  const years = [];
  for (const year in values) years.push(year);

  const gMax = max + step / 2; // max value the graph can show - extend vertical lines a little higher than 'max'

  const yLabelWidth = 40;
  const xLabelHeight = 30;

  const gx = yLabelWidth;
  const gy = xLabelHeight;
  const gw = width - gx;
  const gh = height - gy - 7;

  function yearX(yearIndex) {
    return gx + gw * (0.12 + 0.76 * (yearIndex / (years.length - 1)));
  }
  function valueY(value) {
    return gy + gh * (1 - (value - min) / (gMax - min));
  }

  const lines = [];
  let colorIndex = 0;
  for (let value = min; value <= max; value += step) {
    if (
      colorIndex < colors.length - 1 &&
      value + 0.01 >= colors[colorIndex + 1].value
    )
      colorIndex++;
    lines.push({ value: value, color: colors[colorIndex].color });
  }

  const points = [];
  years.forEach((year, index) => {
    const value = values[year];
    if (value !== undefined) {
      points.push({ x: yearX(index), y: valueY(value) });
    }
  });

  const ctx = canvas.getContext("2d");
  ctx.clearRect(0, 0, width, height);

  ctx.font = "400 0.7rem " + theme.fontFamily[0];

  ctx.lineCap = "butt";
  ctx.lineWidth = 1;

  // Vertical lines
  ctx.strokeStyle = "#e7e7e7";
  ctx.beginPath();
  years.forEach((year, index) => {
    const x = yearX(index);
    ctx.moveTo(x, gy);
    ctx.lineTo(x, gy + gh);
  });
  ctx.stroke();

  // Horizontal lines
  lines.forEach((line) => {
    ctx.strokeStyle = line.color;
    const y = valueY(line.value);
    ctx.beginPath();
    ctx.moveTo(gx, y);
    ctx.lineTo(gx + gw, y);
    ctx.stroke();
  });

  ctx.fillStyle = "#7d7d7d"; //font.color;

  // Value labels
  ctx.textAlign = "right";
  for (let value = min; value <= max; value += step) {
    ctx.fillText(formatNumber(value, 1, 1), gx - 15, valueY(value) + 2);
  }

  // Year labels
  ctx.textAlign = "center";
  years.forEach((year, index) => {
    const x = yearX(index);
    ctx.fillText(year, x, gy - 12);
  });

  // Connecting lines
  ctx.lineCap = "round";
  ctx.lineWidth = 4;
  ctx.strokeStyle = "#566678";
  ctx.beginPath();
  points.forEach((p2, index) => {
    if (index === 0) return;
    const p1 = points[index - 1];
    if (p1 && p2) {
      ctx.moveTo(p1.x, p1.y);
      ctx.lineTo(p2.x, p2.y);
    }
  });
  ctx.stroke();

  // Points
  ctx.lineWidth = 4;
  ctx.strokeStyle = "black";
  points.forEach((point) => {
    ctx.beginPath();
    ctx.arc(point.x, point.y, 4, 0, 2 * Math.PI);
    ctx.stroke();
  });
}
