import React, { useContext } from "react";

import API from "@/API";
import AddButton from "@/components/common/AddButton";
import CentreLoader from "@/components/common/CentreLoader";
import ClientsTable from "@/components/modules/tables/ClientsTable";
import StaticDataContext from "@/contexts/StaticDataContext";
import UserContext from "@/contexts/UserContext";
import { useGet } from "@/hooks/useAPI";

export default function AdminClientsPage() {
  const { user } = useContext(UserContext);

  const staticData = useContext(StaticDataContext);
  const [clientsData] = useGet(API.getClientsTotals(user));
  const { clients } = clientsData || {};

  if (!staticData) return <CentreLoader />;

  return (
    <>
      {clients ? (
        <>
          <ClientsTable
            title="Clients"
            clients={clients}
            actions={<AddButton to="/client/add">Add client</AddButton>}
          />
        </>
      ) : (
        <CentreLoader />
      )}
    </>
  );
}
