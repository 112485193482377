export const ENV = {
  apiBase: import.meta.env.VITE_API_BASE,
  mock: import.meta.env.VITE_USE_MOCK_DATA === "true",
  quickStart: import.meta.env.VITE_QUICK_START === "true",
  logApiCalls: import.meta.env.VITE_LOG_API_CALLS === "true",
  headerColor: import.meta.env.VITE_HEADER_COLOUR,
};

export const AWS = {
  USER_POOL_ID: import.meta.env.VITE_AWS_USER_POOL_ID,
  APP_CLIENT_ID: import.meta.env.VITE_AWS_APP_CLIENT_ID,
};
