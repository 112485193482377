import { useContext, useState } from "react";
import { HighlightOffOutlined } from "@mui/icons-material";
import { IconButton, Link, Typography } from "@mui/material";
import moment from "moment";

import API from "@/API";
import FlexBox from "@/components/layout/FlexBox";
import UserContext from "@/contexts/UserContext";
import { useDelete, useFileGet } from "@/hooks/useAPI";
import useHover from "@/hooks/useHover";
import { downloadPdfFromArrayBuffer } from "@/Utils";

export default function FacilityFileLink({ file, adminMode }) {
  const { user } = useContext(UserContext);

  const [deleting, setDeleting] = useState(false);
  const [hovering, bindHover] = useHover();

  const [downloadFacilityFile] = useFileGet(
    file
      ? API.downloadFacilityFile(user, file.id_facility, file.id_file)
      : null,
  );
  const [deleteFacilityFile] = useDelete(
    adminMode
      ? file
        ? API.deleteFacilityFile(user, file.id_facility, file.id_file)
        : null
      : null,
  );

  function downloadFacility() {
    downloadFacilityFile()
      .then((responseData) => {
        const arrayBuffer = responseData.data;
        downloadPdfFromArrayBuffer(arrayBuffer, file.filename);
      })
      .catch((error) => {
        console.log("Download facility file error", error);
      });
  }

  function deleteFacility() {
    setDeleting(true);
    deleteFacilityFile({
      facility: {
        id_facility: file.id_facility,
      },
      file: {
        id_file: file.id_file,
      },
    })
      .then((responseData) => {
        //console.log("Deleted");
      })
      .catch((error) => {
        console.log("Delete facility file error", error);
      });
  }

  return (
    <FlexBox
      center
      {...bindHover}
      sx={deleting ? { opacity: 0.5, pointerEvents: "none" } : null}
    >
      <FlexBox align="end" sx={{ flex: "1 1 auto", width: 0 }}>
        <Typography variant="caption" mr={1}>
          {moment(file.timestamp_created).format("DD/MM/YY")}
        </Typography>
        <Typography
          sx={{
            flex: "1 1 auto",
            width: 0,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <Link
            sx={{ cursor: "pointer" }}
            title={file.filename}
            onClick={downloadFacility}
          >
            {file.filename}
          </Link>
        </Typography>
      </FlexBox>
      {hovering && !deleting && (
        <IconButton sx={{ my: -2 }} onClick={deleteFacility}>
          <HighlightOffOutlined />
        </IconButton>
      )}
    </FlexBox>
  );
}
