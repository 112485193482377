import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";

import CardTitle from "@/components/common/CardTitle";
import DatePicker from "@/components/common/DatePicker";
import { DollarTextField } from "@/components/common/FormattedTextField";
import NullableTextField from "@/components/common/NullableTextField";
import SignatureBox from "@/components/common/SignatureBox";
import FlexBox from "@/components/layout/FlexBox";
import { useNullableInput } from "@/hooks/useInput";
import { fillApplicationForm } from "@/PDF";
import { searchByField } from "@/Utils";

import Actions from "../Actions";

export default function ApplicationPDFForm({
  storedData,
  bond,
  relatedBonds,
  client,
  indemnifiers,
  staticData,
  onSubmit,
  onClose,
}) {
  if (!storedData) storedData = {};

  const [includeRelatedBonds, setIncludeRelatedBonds] = useState(true);

  const issuer =
    bond &&
    staticData &&
    searchByField(staticData.issuers, "name", bond.issuer_name);

  let performanceCompletionDate = null;
  let performance_id = null;
  staticData.bond_types.forEach((bondType) => {
    if (bondType.name === "Performance") performance_id = bondType.id_bond_type;
  });

  let bonds = [bond];
  if (includeRelatedBonds && relatedBonds.length > 0)
    bonds = [...bonds, ...relatedBonds];
  let totalValue = 0;
  let firstStartDate = null;
  let lastEndDate = null;
  bonds.forEach((bond) => {
    totalValue += bond.face_value;
    if (!firstStartDate || bond.date_start < firstStartDate)
      firstStartDate = bond.date_start;
    if (!lastEndDate || bond.date_finish > lastEndDate)
      lastEndDate = bond.date_finish;
    if (bond.id_bond_type === performance_id)
      performanceCompletionDate = bond.date_finish;
  });

  const [bondDatesEvaluated, setBondDatesEvaluated] = useState(false);

  const contractorName = bond.id_indemnifier
    ? searchByField(indemnifiers, "id_indemnifier", bond.id_indemnifier).name
    : client.name;
  const contractorABN = bond.id_indemnifier
    ? searchByField(indemnifiers, "id_indemnifier", bond.id_indemnifier).abn
    : client.abn;

  const {
    address1: clientAddress1,
    address2: clientAddress2,
    state: clientState,
    postcode: clientPostcode,
  } = splitAddress(client.address);

  const { value: applicantContractorName, bind: bindApplicantContractorName } =
    useNullableInput(storedData.applicantContractorName ?? contractorName);
  const { value: applicantABN, bind: bindApplicantABN } = useNullableInput(
    storedData.applicantABN ?? contractorABN,
  );
  const {
    value: applicantBusinessAddress,
    bind: bindApplicantBusinessAddress,
  } = useNullableInput(storedData.applicantBusinessAddress ?? client.address);
  const {
    value: applicantBusinessAddress1,
    bind: bindApplicantBusinessAddress1,
  } = useNullableInput(storedData.applicantBusinessAddress1 ?? clientAddress1);
  const {
    value: applicantBusinessAddress2,
    bind: bindApplicantBusinessAddress2,
  } = useNullableInput(storedData.applicantBusinessAddress2 ?? clientAddress2);
  const {
    value: applicantBusinessAddressState,
    bind: bindApplicantBusinessAddressState,
  } = useNullableInput(storedData.applicantBusinessAddressState ?? clientState);
  const {
    value: applicantBusinessAddressPostcode,
    bind: bindApplicantBusinessAddressPostcode,
  } = useNullableInput(
    storedData.applicantBusinessAddressPostcode ?? clientPostcode,
  );
  const { value: applicantContactName, bind: bindApplicantContactName } =
    useNullableInput(storedData.applicantContactName ?? client.contact);
  const { value: applicantTelephone, bind: bindApplicantTelephone } =
    useNullableInput(storedData.applicantTelephone ?? client.phone);
  const { value: applicantJobTitle, bind: bindApplicantJobTitle } =
    useNullableInput(storedData.applicantJobTitle ?? client.role);
  const { value: applicantContactEmail, bind: bindApplicantContactEmail } =
    useNullableInput(storedData.applicantContactEmail ?? "");

  const {
    value: beneficiaryContractorName,
    bind: bindBeneficiaryContractorName,
  } = useNullableInput(storedData.beneficiaryContractorName ?? bond.principal);
  const { value: beneficiaryABN, bind: bindBeneficiaryABN } = useNullableInput(
    storedData.beneficiaryABN ?? null,
  );
  const {
    value: beneficiaryBusinessAddress,
    bind: bindBeneficiaryBusinessAddress,
  } = useNullableInput(storedData.beneficiaryBusinessAddress ?? null);
  const {
    value: beneficiaryBusinessAddress1,
    bind: bindBeneficiaryBusinessAddress1,
  } = useNullableInput(storedData.beneficiaryBusinessAddress1 ?? null);
  const {
    value: beneficiaryBusinessAddress2,
    bind: bindBeneficiaryBusinessAddress2,
  } = useNullableInput(storedData.beneficiaryBusinessAddress2 ?? null);
  const {
    value: beneficiaryBusinessAddressState,
    bind: bindBeneficiaryBusinessAddressState,
  } = useNullableInput(storedData.beneficiaryBusinessAddressState ?? null);
  const {
    value: beneficiaryBusinessAddressPostcode,
    bind: bindBeneficiaryBusinessAddressPostcode,
  } = useNullableInput(storedData.beneficiaryBusinessAddressPostcode ?? null);
  const { value: beneficiaryPM, bind: bindBeneficiaryPM } = useNullableInput(
    storedData.beneficiaryPM ?? null,
  );
  const { value: beneficiaryEmail, bind: bindBeneficiaryEmail } =
    useNullableInput(storedData.beneficiaryEmail ?? null);
  const { value: beneficiaryTelephone, bind: bindBeneficiaryTelephone } =
    useNullableInput(storedData.beneficiaryTelephone ?? null);

  const { value: frontedReason, bind: bindFrontedReason } = useNullableInput(
    storedData.frontedReason ?? null,
  );

  const [contractStartDate, setContractStartDate] = useState(
    new Date(storedData.contractStartDate ?? firstStartDate),
  );
  const [practicalCompletionDate, setPracticalCompletionDate] = useState(
    new Date(
      storedData.practicalCompletionDate ??
        performanceCompletionDate ??
        lastEndDate,
    ),
  );
  const [finalCompletionDate, setFinalCompletionDate] = useState(
    new Date(storedData.finalCompletionDate ?? lastEndDate),
  );
  const [completionDate, setCompletionDate] = useState(
    new Date(
      storedData.completionDate ?? performanceCompletionDate ?? lastEndDate,
    ),
  );

  const { value: contractNumber, bind: bindContractNumber } = useNullableInput(
    storedData.contractNumber ?? null,
  );
  const { value: contractTotalValue, bind: bindContractTotalValue } =
    useNullableInput(storedData.contractTotalValue ?? totalValue);
  const { value: contractLocation, bind: bindContractLocation } =
    useNullableInput(storedData.contractLocation ?? null);
  const { value: contractDescription, bind: bindContractDescription } =
    useNullableInput(storedData.contractDescription ?? bond.project);
  const { value: contractTitle, bind: bindContractTitle } = useNullableInput(
    storedData.contractTitle ?? "",
  );
  const {
    value: contractLegalJurisdiction,
    bind: bindContractLegalJurisdiction,
  } = useNullableInput(storedData.contractLegalJurisdiction ?? "");

  const { value: previousContracts, bind: bindPreviousContracts } =
    useNullableInput(yesNoFromString(storedData.previousContracts));
  const { value: typeBefore, bind: bindTypeBefore } = useNullableInput(
    yesNoFromString(storedData.typeBefore),
  );
  const { value: role, bind: bindRole } = useNullableInput(
    storedData.role ?? null,
  );
  const { value: designObligations, bind: bindDesignObligations } =
    useNullableInput(yesNoFromString(storedData.designObligations));
  const { value: delayEvents, bind: bindDelayEvents } = useNullableInput(
    yesNoFromString(storedData.delayEvents),
  );
  const { value: wordingSpecified, bind: bindWordingSpecified } =
    useNullableInput(yesNoFromString(storedData.wordingSpecified));
  const { value: percentageSubcontracted, bind: bindPercentageSubcontracted } =
    useNullableInput(storedData.percentageSubcontracted ?? null);
  const { value: formOfContract, bind: bindFormOfContract } = useNullableInput(
    yesNoFromString(storedData.formOfContract),
  );
  const { value: forceMajeure, bind: bindForceMajeure } = useNullableInput(
    yesNoFromString(storedData.forceMajeure),
  );
  const { value: limitDamages, bind: bindLimitDamages } = useNullableInput(
    yesNoFromString(storedData.limitDamages),
  );

  const { value: issuesDisputes, bind: bindIssuesDisputes } = useNullableInput(
    yesNoFromString(storedData.issuesDisputes),
  );
  const { value: exceededContractSum, bind: bindExceededContractSum } =
    useNullableInput(yesNoFromString(storedData.exceededContractSum));
  const { value: replacingExisting, bind: bindReplacingExisting } =
    useNullableInput(yesNoFromString(storedData.replacingExisting));

  const { value: defectClaims, bind: bindDefectClaims } = useNullableInput(
    yesNoFromString(storedData.defectClaims),
  );
  const { value: extendedLiabilityPeriod, bind: bindExtendedLiabilityPeriod } =
    useNullableInput(yesNoFromString(storedData.extendedLiabilityPeriod));

  const {
    value: offsiteDocumentsProvided,
    bind: bindOffsiteDocumentsProvided,
  } = useNullableInput(storedData.offsiteDocumentsProvided === "1");

  const { value: declarationName, bind: bindDeclarationName } =
    useNullableInput(storedData.declarationName ?? null);
  const { value: declarationJobTitle, bind: bindDeclarationJobTitle } =
    useNullableInput(storedData.declarationJobTitle ?? null);
  const [declarationDate, setDeclarationDate] = useState(
    storedData.declarationDate ?? new Date(),
  );

  const [signature, setSignature] = useState(storedData.signature ?? null);

  function yesNoFromString(s) {
    if (s === "1") return true;
    else if (s === "0") return false;
    else return null;
  }

  useEffect(() => {
    if (!bondDatesEvaluated && firstStartDate) {
      setContractStartDate(contractStartDate || firstStartDate);
      setPracticalCompletionDate(
        practicalCompletionDate || performanceCompletionDate || lastEndDate,
      );
      setFinalCompletionDate(finalCompletionDate || lastEndDate);
      setCompletionDate(
        completionDate || performanceCompletionDate || lastEndDate,
      );
      setBondDatesEvaluated(true);
    }
  }, [
    bondDatesEvaluated,
    firstStartDate,
    lastEndDate,
    performanceCompletionDate,
    contractStartDate,
    practicalCompletionDate,
    finalCompletionDate,
    completionDate,
  ]);

  function generateFileName() {
    return client.name + " bond application";
  }

  function getFormData() {
    return {
      applicantContractorName, // Applicant_contractor_name
      applicantABN, // Applicant_ACN_ABN
      applicantBusinessAddress, // Business_Addr_Applicant
      applicantBusinessAddress1,
      applicantBusinessAddress2,
      applicantBusinessAddressState,
      applicantBusinessAddressPostcode,
      applicantContactName, // Applicant_Contact_name
      applicantTelephone, // Applicant_telephone
      applicantJobTitle, // Applicant_job_title
      applicantContactEmail,

      beneficiaryContractorName, // Beneficiary_name
      beneficiaryABN, // Benef_ACN_ABN
      beneficiaryBusinessAddress, // Benef_Business Addr
      beneficiaryBusinessAddress1,
      beneficiaryBusinessAddress2,
      beneficiaryBusinessAddressState,
      beneficiaryBusinessAddressPostcode,
      beneficiaryPM, // Name_of_PM
      beneficiaryEmail, // Managers email address
      beneficiaryTelephone, // Benef_tel

      fronted: bond.fronted, // Bond_or_bank_G
      frontedReason, // Reason_Reqd

      bonds: bonds.map((bond, index) => {
        if (index >= 4) return null;
        return {
          type: (
            (staticData &&
              searchByField(
                staticData.bond_types,
                "id_bond_type",
                bond.id_bond_type,
              )) ||
            {}
          ).name, // Bond_type_no1
          value: bond.face_value, // Val_in_AUD_1
          periodFrom: bond.date_start, // Period_from_date_1
          periodTo: bond.date_finish, // Period_to_date_1
          expires: bond.expires, // Fixed Expiry Date_1
        };
      }),

      contractStartDate, // Contract_start_date
      practicalCompletionDate, // Practical_Compl_Date
      finalCompletionDate, // Final_Compl_Date
      completionDate,

      contractNumber, // Contract_No
      contractTotalValue, // Total_Val_Contract
      contractLocation, // Contract_location
      contractDescription, // Descr_contract_proj
      contractTitle,
      contractLegalJurisdiction,

      previousContracts, // Q_prev_contracts
      typeBefore, // Q_before
      role, // Head_or_Sub
      designObligations, // Q_design
      delayEvents, // Q_delay
      wordingSpecified, // Q_wording
      percentageSubcontracted,
      formOfContract,
      forceMajeure,
      limitDamages,

      issuesDisputes, // Q_issues
      exceededContractSum, // Q_sum_exceeded
      replacingExisting, // Q_replacing

      defectClaims, // Q_claims
      extendedLiabilityPeriod, // Q_liab

      offsiteDocumentsProvided, // offsiteq_1

      declarationName, // Declaration_name
      declarationJobTitle, // Job_Title_Declaration
      declarationDate, // Decl_date

      signature, // base64 dataURL
    };
  }

  function renderIncludeRelated() {
    return (
      <Box mb={2}>
        <FormControlLabel
          label="Include related bonds"
          control={
            <Checkbox
              checked={includeRelatedBonds}
              onChange={(event) => {
                setIncludeRelatedBonds(event.target.checked);
              }}
            />
          }
        />
      </Box>
    );
  }

  function renderCommonApplicantDetails() {
    return (
      <>
        <CardTitle title="Applicant Details" />
        <Grid container columnSpacing={2} rowSpacing={2}>
          <Grid item xs={12} md={6}>
            <NullableTextField
              fullWidth
              type="text"
              label="Contractor name"
              {...bindApplicantContractorName}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <NullableTextField
              fullWidth
              type="text"
              label="ACN/ABN"
              {...bindApplicantABN}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <NullableTextField
              fullWidth
              type="text"
              label="Business Address"
              {...bindApplicantBusinessAddress}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <NullableTextField
              fullWidth
              type="text"
              label="Contact Name"
              {...bindApplicantContactName}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <NullableTextField
              fullWidth
              type="phone"
              label="Telephone"
              {...bindApplicantTelephone}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <NullableTextField
              fullWidth
              type="text"
              label="Job Title"
              {...bindApplicantJobTitle}
            />
          </Grid>
        </Grid>
      </>
    );
  }
  function renderVeroApplicantDetails() {
    return (
      <>
        <CardTitle title="Applicant Details" />
        <Grid container columnSpacing={2} rowSpacing={2}>
          <Grid item xs={12} md={6}>
            <NullableTextField
              fullWidth
              type="text"
              label="Contractor name"
              {...bindApplicantContractorName}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <NullableTextField
              fullWidth
              type="text"
              label="ACN/ABN"
              {...bindApplicantABN}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <NullableTextField
              fullWidth
              type="text"
              label="Business Address 1"
              {...bindApplicantBusinessAddress1}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <NullableTextField
              fullWidth
              type="text"
              label="Business Address 2"
              {...bindApplicantBusinessAddress2}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <NullableTextField
              fullWidth
              type="text"
              label="State"
              {...bindApplicantBusinessAddressState}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <NullableTextField
              fullWidth
              type="text"
              label="Postcode"
              {...bindApplicantBusinessAddressPostcode}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <NullableTextField
              fullWidth
              type="text"
              label="Contact Name"
              {...bindApplicantContactName}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <NullableTextField
              fullWidth
              type="phone"
              label="Telephone"
              {...bindApplicantTelephone}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <NullableTextField
              fullWidth
              type="text"
              label="Job Title"
              {...bindApplicantJobTitle}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <NullableTextField
              fullWidth
              type="text"
              label="Email"
              {...bindApplicantContactEmail}
            />
          </Grid>
        </Grid>
      </>
    );
  }

  function renderCommonBeneficiaryDetails() {
    return (
      <>
        <CardTitle title="Beneficiary Details" />
        <Grid container columnSpacing={2} rowSpacing={2}>
          <Grid item xs={12} md={6}>
            <NullableTextField
              fullWidth
              type="text"
              label="Beneficiary name"
              {...bindBeneficiaryContractorName}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <NullableTextField
              fullWidth
              type="text"
              label="ACN/ABN"
              {...bindBeneficiaryABN}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <NullableTextField
              fullWidth
              type="text"
              label="Business Address"
              {...bindBeneficiaryBusinessAddress}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <NullableTextField
              fullWidth
              type="text"
              label="Name of Project Manager/Representative"
              {...bindBeneficiaryPM}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <NullableTextField
              fullWidth
              type="text"
              label="Project Manager's Email Address"
              {...bindBeneficiaryEmail}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <NullableTextField
              fullWidth
              type="text"
              label="Telephone"
              {...bindBeneficiaryTelephone}
            />
          </Grid>
        </Grid>
      </>
    );
  }
  function renderVeroBeneficiaryDetails() {
    return (
      <>
        <CardTitle title="Beneficiary Details" />
        <Grid container columnSpacing={2} rowSpacing={2}>
          <Grid item xs={12} md={6}>
            <NullableTextField
              fullWidth
              type="text"
              label="Beneficiary name"
              {...bindBeneficiaryContractorName}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <NullableTextField
              fullWidth
              type="text"
              label="ACN/ABN"
              {...bindBeneficiaryABN}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <NullableTextField
              fullWidth
              type="text"
              label="Business Address 1"
              {...bindBeneficiaryBusinessAddress1}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <NullableTextField
              fullWidth
              type="text"
              label="Business Address 2"
              {...bindBeneficiaryBusinessAddress2}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <NullableTextField
              fullWidth
              type="text"
              label="State"
              {...bindBeneficiaryBusinessAddressState}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <NullableTextField
              fullWidth
              type="text"
              label="Postcode"
              {...bindBeneficiaryBusinessAddressPostcode}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <NullableTextField
              fullWidth
              type="text"
              label="Name of Project Manager/Representative"
              {...bindBeneficiaryPM}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <NullableTextField
              fullWidth
              type="text"
              label="Project Manager's Email Address"
              {...bindBeneficiaryEmail}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <NullableTextField
              fullWidth
              type="text"
              label="Telephone"
              {...bindBeneficiaryTelephone}
            />
          </Grid>
        </Grid>
      </>
    );
  }

  function renderSecurityRequired() {
    return (
      <>
        <CardTitle title="Security Required" />
        <Grid container columnSpacing={2} rowSpacing={2}>
          <Grid item xs={12} md={6}>
            <RadioGroup defaultValue={bond.fronted}>
              <FormControlLabel
                disabled
                value={false}
                control={<Radio />}
                label="Bond/s"
              />
              <FormControlLabel
                disabled
                value={true}
                control={<Radio />}
                label="Bank Guarantee/s"
              />
            </RadioGroup>
          </Grid>
          <Grid item xs={12} md={12}>
            <NullableTextField
              fullWidth
              disabled={!bond.fronted}
              type="text"
              label="Reason Bank Guarantee is required"
              {...bindFrontedReason}
            />
            <Box mt={0.5}>
              <Typography variant="label">
                (i.e. part of the contract, beneficiary does not accept bonds
                etc.)
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </>
    );
  }

  function renderCommonContractDetails() {
    return (
      <>
        <CardTitle title="Contract Details" />
        <Grid container columnSpacing={2} rowSpacing={2}>
          <Grid item xs={12} md={4}>
            <DatePicker
              value={contractStartDate}
              label={"Contract Start Date"}
              onChange={(value) => {
                setContractStartDate(value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <DatePicker
              value={practicalCompletionDate}
              label={"Practical Completion"}
              onChange={(value) => {
                setPracticalCompletionDate(value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <DatePicker
              value={finalCompletionDate}
              label={"Final Completion"}
              onChange={(value) => {
                setFinalCompletionDate(value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <NullableTextField
              fullWidth
              type="text"
              label="Contract Number"
              {...bindContractNumber}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DollarTextField
              fullWidth
              type="text"
              label="Total Value of Contract"
              {...bindContractTotalValue}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <NullableTextField
              fullWidth
              type="text"
              label="Contract Location"
              {...bindContractLocation}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <NullableTextField
              fullWidth
              label="Description of Contract/Project (as required by the Beneficiary) to appear on bond"
              multiline
              minRows={3}
              {...bindContractDescription}
            />
          </Grid>
        </Grid>
      </>
    );
  }
  function renderVeroContractDetails() {
    return (
      <>
        <CardTitle title="Contract Details" />
        <Grid container columnSpacing={2} rowSpacing={2}>
          <Grid item xs={12} md={12}>
            <NullableTextField
              fullWidth
              label="Description of Contract"
              {...bindContractDescription}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <NullableTextField
              fullWidth
              label="Contract Title"
              {...bindContractTitle}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <NullableTextField
              fullWidth
              type="text"
              label="Contract Location"
              {...bindContractLocation}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DollarTextField
              fullWidth
              type="text"
              label="Total Value of Contract"
              {...bindContractTotalValue}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <NullableTextField
              fullWidth
              type="text"
              label="Legal Jurisdiction of Contract"
              {...bindContractLegalJurisdiction}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <NullableTextField
              fullWidth
              type="text"
              label="Contract Number"
              {...bindContractNumber}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DatePicker
              value={contractStartDate}
              label={"Contract Start Date"}
              onChange={(value) => {
                setContractStartDate(value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DatePicker
              value={completionDate}
              label={"Completion"}
              onChange={(value) => {
                setPracticalCompletionDate(value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DatePicker
              value={practicalCompletionDate}
              label={"Practical Completion"}
              onChange={(value) => {
                setPracticalCompletionDate(value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DatePicker
              value={finalCompletionDate}
              label={"Final Completion"}
              onChange={(value) => {
                setFinalCompletionDate(value);
              }}
            />
          </Grid>
        </Grid>
      </>
    );
  }

  function renderCommonProjectQuestions() {
    return (
      <>
        <CardTitle title="For New Projects:" />
        <Grid container columnSpacing={2} rowSpacing={2} mb={3}>
          <Grid item xs={12} md={12}>
            <YesNo
              label="Has the company undertaken previous contracts for this client?"
              {...bindPreviousContracts}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <YesNo
              label="Has the company undertaken a contract of this type before?"
              {...bindTypeBefore}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <FlexBox center sx={{ height: "100%" }}>
              <Typography variant="formText">
                Will the role be Head Contractor or Subcontractor?
              </Typography>
            </FlexBox>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              select
              value={bindRole.value || "Choose one"}
              onChange={(event) => {
                bindRole.onChange(event.target.value);
              }}
            >
              {["Choose one", "Head", "Subcontractor"].map((option) => {
                return (
                  <MenuItem key={option} value={option || undefined}>
                    {option}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>
          <Grid item xs={12} md={12}>
            <YesNo
              label="Does the Contract include any “Design” obligations and/or liability?"
              {...bindDesignObligations}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <YesNo
              label="Does the Contract identify delay events that give rise to Extensions of Time and Cost Recovery?"
              {...bindDelayEvents}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <YesNo
              label="Has the bond wording been specified in the contract? Alternatively, has the wording been separately negotiated? (in either case, please provide a copy of the relevant document via covering email)"
              {...bindWordingSpecified}
            />
          </Grid>
        </Grid>

        <CardTitle title="For Existing Projects:" />
        <Grid container columnSpacing={2} rowSpacing={2} mb={3}>
          <Grid item xs={12} md={12}>
            <YesNo
              label="Has your Principal stated any issues/disputes about the contract works or defects with the project? (if yes, please elaborate via covering email)"
              {...bindIssuesDisputes}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <YesNo
              label="Has either of the project program, or contract sum been exceeded?"
              {...bindExceededContractSum}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <YesNo
              label="Are we replacing existing security? e.g. cash retentions or bank guarantees? (if yes, please specify the type via covering email)"
              {...bindReplacingExisting}
            />
          </Grid>
        </Grid>

        <CardTitle title="For Single Maintenance/DLP Bonds/Bank Guarantees Requests:" />
        <Grid container columnSpacing={2} rowSpacing={2} mb={3}>
          <Grid item xs={12} md={12}>
            <YesNo
              label="With respect to the contract, has your Principal made any claims to defects or faults in materials, workmanship or design? (if so, please provide full details via covering email)"
              {...bindDefectClaims}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <YesNo
              label="Has your Principal extended the defects liability period? (if so, please provide full details via covering email)"
              {...bindExtendedLiabilityPeriod}
            />
          </Grid>
        </Grid>

        <CardTitle title="For Offsite/Unfixed Materials and Advance Payment Bonds:" />
        <Grid container columnSpacing={2} rowSpacing={2}>
          <Grid item xs={12} md={8}>
            <Typography>Please provide:</Typography>
            <ul style={{ listStylePosition: "inside" }}>
              <li style={{ marginTop: "0.5em" }}>
                <Typography variant="formText" sx={{ display: "inline" }}>
                  A copy of the Contract Extract that refers to the security
                  required in respect of the Off‐site Materials or Advance
                  Payment requirements via email
                </Typography>
              </li>
              <li style={{ marginTop: "0.5em" }}>
                <Typography variant="formText" sx={{ display: "inline" }}>
                  A copy of the Billing Advice or the Purchase Order with exact
                  description of materials and value of goods via covering email
                </Typography>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12} md={4}>
            <FlexBox sx={{ height: "100%" }} justify="end" align="end">
              <FormControlLabel
                label="(Tick if provided)"
                control={
                  <Checkbox
                    checked={bindOffsiteDocumentsProvided.value}
                    onChange={(event) => {
                      bindOffsiteDocumentsProvided.onChange(
                        event.target.checked,
                      );
                    }}
                  />
                }
              />
            </FlexBox>
          </Grid>
        </Grid>
      </>
    );
  }
  function renderVeroProjectQuestions() {
    return (
      <>
        <Grid container columnSpacing={2} rowSpacing={2} mb={3}>
          <Grid item xs={12} md={12}>
            <YesNo
              label="Will the company assume any design exposure/liability for this contract?"
              {...bindDesignObligations}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <YesNo
              label="Has the company undertaken a contract of this type before?"
              {...bindTypeBefore}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <YesNo
              label="Has the company undertaken previous contracts for this client?"
              {...bindPreviousContracts}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <FlexBox center sx={{ height: "100%" }}>
              <Typography variant="formText">
                Will the role be Head Contractor or Subcontractor?
              </Typography>
            </FlexBox>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              select
              value={bindRole.value || "Choose one"}
              onChange={(event) => {
                bindRole.onChange(event.target.value);
              }}
            >
              {["Choose one", "Head", "Subcontractor"].map((option) => {
                return (
                  <MenuItem key={option} value={option || undefined}>
                    {option}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>
          <Grid item xs={12} md={8}>
            <FlexBox center sx={{ height: "100%" }}>
              <Typography variant="formText">
                Percentage to be subcontracted
              </Typography>
            </FlexBox>
          </Grid>
          <Grid item xs={12} md={4}>
            <NullableTextField
              fullWidth
              type="number"
              label=""
              {...bindPercentageSubcontracted}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <YesNo
              label="Has the bond wording been specified?"
              {...bindWordingSpecified}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <FlexBox center sx={{ height: "100%" }}>
              <Typography variant="formText">
                Form of Contract i.e. (AS2124, JCC, NZIA, NZS3910, other)
              </Typography>
            </FlexBox>
          </Grid>
          <Grid item xs={12} md={4}>
            <NullableTextField
              fullWidth
              type="text"
              label=""
              {...bindFormOfContract}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography variant="label">
              *Please provide copy of Special Conditions of contract. If Yesn
              standard contract please provide a full copy.
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <YesNo
              label="Are force majeure risks excluded risks under the contract?"
              {...bindForceMajeure}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <NullableTextField
              fullWidth
              type="text"
              label="What is the limit of liquidated damages?"
              {...bindLimitDamages}
            />
          </Grid>
        </Grid>
      </>
    );
  }

  function renderDeclaration() {
    return (
      <>
        <CardTitle title="Declaration" />
        <Grid container columnSpacing={2} rowSpacing={2}>
          <Grid item xs={12} md={4}>
            <NullableTextField
              fullWidth
              type="text"
              label="Name"
              {...bindDeclarationName}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <NullableTextField
              fullWidth
              type="text"
              label="Job Title"
              {...bindDeclarationJobTitle}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <DatePicker
              value={declarationDate}
              label={"Date"}
              onChange={(value) => {
                setDeclarationDate(value);
              }}
            />
          </Grid>
        </Grid>
      </>
    );
  }

  function renderSignatureBox(width, height, scale) {
    return (
      <>
        <Typography variant="label" sx={{ display: "block", mt: 2, mb: "3px" }}>
          Signature
        </Typography>
        <SignatureBox
          width={width * (scale || 1)}
          height={height * (scale || 1)}
          dataURL={signature}
          onChange={(dataURL) => {
            setSignature(dataURL);
          }}
        />
      </>
    );
  }

  function renderActions() {
    return (
      <FlexBox justify="end" mt={2}>
        <Actions>
          <Button
            variant="outlined"
            onClick={() => {
              const formData = getFormData();
              fillApplicationForm(
                formData,
                issuer,
                bond.fronted,
                generateFileName(),
              );
              if (onSubmit) onSubmit(formData);
            }}
          >
            Export PDF
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              onClose();
            }}
          >
            Close
          </Button>
        </Actions>
      </FlexBox>
    );
  }

  switch (issuer.name) {
    case "Vero":
      return (
        <Box>
          {renderIncludeRelated()}

          {renderVeroApplicantDetails()}

          <Divider sx={{ my: 3 }} />

          {renderVeroBeneficiaryDetails()}

          <Divider sx={{ my: 3 }} />

          {renderVeroContractDetails()}

          <Divider sx={{ my: 3 }} />

          {renderVeroProjectQuestions()}

          <Divider sx={{ my: 3 }} />

          {renderDeclaration()}

          {renderSignatureBox(268, 31, 1.5)}

          <Divider sx={{ my: 3 }} />

          {renderActions()}
        </Box>
      );

    default:
      return (
        <Box>
          {renderIncludeRelated()}

          {renderCommonApplicantDetails()}

          <Divider sx={{ my: 3 }} />

          {renderCommonBeneficiaryDetails()}

          <Divider sx={{ my: 3 }} />

          {renderSecurityRequired()}

          <Divider sx={{ my: 3 }} />

          {renderCommonContractDetails()}

          <Divider sx={{ my: 3 }} />

          {renderCommonProjectQuestions()}

          <Divider sx={{ my: 3 }} />

          {renderDeclaration()}

          {renderSignatureBox(268, 31, 1.5)}

          <Divider sx={{ my: 3 }} />

          {renderActions()}
        </Box>
      );
  }
}

function YesNo({ label, value, onChange }) {
  return (
    <FormControl component="fieldset" sx={{ width: "100%" }}>
      <Grid container>
        <Grid item xs={12} md={8}>
          <Typography
            variant="formText"
            sx={{ marginRight: 2, marginTop: "auto", marginBottom: "auto" }}
          >
            {label}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <FlexBox justify="end" mt="auto">
            <FormControlLabel
              label="Yes"
              control={
                <Checkbox
                  checked={value === true}
                  onChange={(event) => {
                    onChange(event.target.checked ? true : null);
                  }}
                />
              }
            />
            <FormControlLabel
              label="No"
              control={
                <Checkbox
                  checked={value === false}
                  onChange={(event) => {
                    onChange(event.target.checked ? false : null);
                  }}
                />
              }
            />
          </FlexBox>
        </Grid>
      </Grid>
    </FormControl>
  );
}

function splitAddress(address) {
  if (!address) return {};

  let address1 = address;
  let address2 = "";
  let state = "";
  let postcode = "";

  const parts = address.split(" ");
  if (parts.length > 1) {
    // Postcode?
    let part = parts[parts.length - 1];
    if (!isNaN(parseInt(part))) {
      postcode = part;
      parts.pop();
      address1 = parts.join(" ");

      // State?
      part = parts[parts.length - 1].replace(",", "");
      let validState = false;
      switch (part.toLowerCase()) {
        case "australian capital territory":
        case "act":
          state = "ACT";
          validState = true;
          break;
        case "new south wales":
        case "nsw":
          state = "NSW";
          validState = true;
          break;
        case "northern territory":
        case "nt":
          state = "NT";
          validState = true;
          break;
        case "queensland":
        case "qld":
          state = "QLD";
          validState = true;
          break;
        case "south australia":
        case "sa":
          state = "SA";
          validState = true;
          break;
        case "tasmania":
        case "tas":
          state = "TAS";
          validState = true;
          break;
        case "victoria":
        case "vic":
          state = "VIC";
          validState = true;
          break;
        case "western australia":
        case "wa":
          state = "WA";
          validState = true;
          break;
        default:
          break;
      }
      if (validState) {
        parts.pop();
        address1 = parts.join(" ");
        if (address1.charAt(address1.length - 1) === ",")
          address1 = address1.substr(0, address1.length - 1);

        // address2?
        const index = address1.lastIndexOf(",");
        if (index >= 0) {
          address2 = address1.substring(index + 1).trim();
          address1 = address1.substring(0, index);
        }
      }
    }
  }

  return { address1, address2, state, postcode };
}
