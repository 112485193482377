import React, { useState } from "react";

const CacheIterationContext = React.createContext({});

export const CacheIterationProvider = ({ children }) => {
  const [cacheIteration, setCacheIteration] = useState(1);

  return (
    <CacheIterationContext.Provider
      value={{
        cacheIteration: cacheIteration,
        incrementCacheIteration: () => {
          setCacheIteration(cacheIteration + 1);
        },
      }}
    >
      {children}
    </CacheIterationContext.Provider>
  );
};

export default CacheIterationContext;
