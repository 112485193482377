import { Paper, TableContainer } from "@mui/material";

import StandardTableBase from "./StandardTableBase";

export default function StandardTable({
  columns,
  rows,
  stickyHeader,
  compact,
  verticalAlign,
  sx,
  ...props
}) {
  return (
    <Paper sx={{ width: "100%", marginBottom: 2, ...sx }} {...props}>
      <TableContainer>
        <StandardTableBase
          stickyHeader={stickyHeader}
          columns={columns}
          rows={rows}
          compact={compact}
          verticalAlign={verticalAlign}
        />
      </TableContainer>
    </Paper>
  );
}
