import { TextField, Typography } from "@mui/material";

import FlexBox from "@/components/layout/FlexBox";

export default function TableControlSelect({
  label,
  value,
  onChange,
  children,
  sx,
  ...rest
}) {
  return (
    <FlexBox center sx={sx}>
      <Typography variant="control" sx={{ textAlign: "right" }}>
        {label}
      </Typography>
      <TextField
        value={value}
        select
        onChange={onChange}
        {...rest}
        sx={{
          ".MuiSelect-root": {
            marginLeft: "8px",
          },
          ".MuiSelect-select": {
            padding: "4px 24px 5px 8px",
            fontSize: "14px",
          },
          fieldset: {
            border: "none",
          },
        }}
      >
        {children}
      </TextField>
    </FlexBox>
  );
}
