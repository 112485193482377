import { Add } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { Link } from "react-router";

import FlexBox from "@/components/layout/FlexBox";

export default function AddButton({ children, to, ...props }) {
  function renderContent() {
    return (
      <FlexBox center>
        <Add sx={{ marginLeft: "-5px", marginRight: 1 }} />
        <Box sx={{ position: "relative", top: "1px" }}>{children}</Box>
      </FlexBox>
    );
  }

  return to ? (
    <Button component={Link} to={to} variant="outlined" {...props}>
      {renderContent()}
    </Button>
  ) : (
    <Button variant="outlined" {...props}>
      {renderContent()}
    </Button>
  );
}
