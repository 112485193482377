import { Chip, useTheme } from "@mui/material";

export default function BondStatus({ bondStatus, sx }) {
  const theme = useTheme();

  return (
    <Chip
      variant="status"
      label={bondStatus.name}
      sx={{ ...sx, background: theme.bondStatusColors[bondStatus.name] }}
    />
  );
}
