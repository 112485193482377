import { useContext, useEffect } from "react";
import { useAuthenticator, withAuthenticator } from "@aws-amplify/ui-react";
import { ThemeProvider } from "@mui/material";
import { Amplify } from "aws-amplify";
import { fetchAuthSession } from "aws-amplify/auth";
import { BrowserRouter as Router } from "react-router";

import AppRoutes from "@/AppRoutes";
import UserDataLoader from "@/components/modules/UserDataLoader";
import { CacheIterationProvider } from "@/contexts/CacheIterationContext";
import { LayoutProvider } from "@/contexts/LayoutContext";
import { StaticDataProvider } from "@/contexts/StaticDataContext";
import UserContext from "@/contexts/UserContext";
import { AWS } from "@/Environment";
import inscapTheme from "@/Theme";

import "@aws-amplify/ui-react/styles.css";
import "./assets/css/style.css";
import "@csstools/normalize.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: AWS.USER_POOL_ID ?? "",
      userPoolClientId: AWS.APP_CLIENT_ID ?? "",
    },
  },
});

function App() {
  const { onLoggedIn, user } = useContext(UserContext);

  const { authStatus } = useAuthenticator((context) => [context.authStatus]);

  if (authStatus === "authenticated" && !user.cognito) {
    fetchAuthSession()
      .then((session) => onLoggedIn(session))
      .catch((error) => console.log("Fetch auth session error", error));
  }

  useEffect(() => {
    document.title = "InsCap";
  }, []);

  return (
    <CacheIterationProvider>
      <ThemeProvider theme={inscapTheme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StaticDataProvider>
            <LayoutProvider>
              <Router>
                {user && user.inscap && user.cognito ? (
                  <AppRoutes user={user} />
                ) : user && user.cognito ? (
                  <UserDataLoader />
                ) : undefined}
              </Router>
            </LayoutProvider>
          </StaticDataProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </CacheIterationProvider>
  );
}

const AuthenicatedApp = withAuthenticator(App, {
  hideSignUp: true,
  formFields: {
    setupTotp: {
      QR: {
        totpIssuer: "Inscap",
      },
    },
  },
});

export { AuthenicatedApp as App };
