import { useContext, useEffect, useRef, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router";

import API from "@/API";
import CentreLoader from "@/components/common/CentreLoader";
import DisplayField from "@/components/common/DisplayField";
import NullableTextField from "@/components/common/NullableTextField";
import SectionCard from "@/components/common/SectionCard";
import SectionTitle from "@/components/common/SectionTitle";
import FlexBox from "@/components/layout/FlexBox";
import SaveDrawer from "@/components/modules/SaveDrawer";
import SortableTable from "@/components/modules/tables/SortableTable";
import {
  CLIENT_DATAROOM_ID,
  CLIENT_PRIMARY_ID,
  CLIENT_SUBSIDIARY_ID,
} from "@/contexts/StaticDataContext";
import UserContext from "@/contexts/UserContext";
import { useGet, usePost, usePut } from "@/hooks/useAPI";
import { useDetectClient } from "@/hooks/useDetectClient";
import { useQuery } from "@/hooks/useQuery";
import { checkFormValidity } from "@/Utils";

function createDefaultContact() {
  return {
    name: null,
    role: null,
    email: null,
    landline: null,
    mobile: null,
    address: null,
    type: null,
  };
}

function createDefaultUser(id_group) {
  return {
    email: null,
    groups: [id_group],
    indemnifiers: [],
    clients: [],
  };
}

export default function EditContactPage() {
  const navigate = useNavigate();

  const query = useQuery();
  const id_contact = query.id === "add" ? null : query.id;
  const creatingNew = query.id === "add";
  let id_client = query.client;

  const { user } = useContext(UserContext);

  const [originalContactData] = useGet(
    id_contact ? API.getContact(user, id_contact) : null,
  );
  const {
    contact: originalContact,
    user: originalUser,
    client: originalClient,
    requests,
  } = originalContactData || {};

  const [contact, setContact] = useState(originalContact);
  const [contactUser, setContactUser] = useState(originalUser);
  const [client, setClient] = useState(originalClient);

  if (client) id_client = client.id_client;
  const isOrphan = !id_client;

  useDetectClient(id_client);

  const [originalClientData] = useGet(
    id_client ? API.getClient(user, id_client) : null,
  );
  const { indemnifiers } = originalClientData || {};

  const [originalClientsData] = useGet(isOrphan ? API.getClients(user) : null);
  const { clients } = originalClientsData || {};

  const [contactRevertTarget, setContactRevertTarget] =
    useState(originalContact);
  const [userRevertTarget, setUserRevertTarget] = useState(null);

  const [activating, setActivating] = useState(false);
  const [activated, setActivated] = useState(false);
  const [deactivated, setDeactivated] = useState(false);
  const [reactivated, setReactivated] = useState(false);
  const [resetSent, setResetSent] = useState(false);

  const [contactChanged, setContactChanged] = useState(false);
  const [userChanged, setUserChanged] = useState(false);
  const [submittingContact, setSubmittingContact] = useState(false);
  const [submittingUser, setSubmittingUser] = useState(false);
  const [submittingActivation, setSubmittingActivation] = useState(false);

  const [postContact] = usePost(API.postContact(user));
  const [putContact] = usePut(
    contact && contact.id_contact && API.putContact(user, contact.id_contact),
  );
  const [postUser] = usePost(API.postUser(user));
  const [putUser] = usePut(
    contactUser &&
      contactUser.id_user &&
      API.putUser(user, contactUser.id_user),
  );
  const [postResetUser] = usePost(
    contactUser &&
      contactUser.id_user &&
      API.postResetUser(user, contactUser.id_user),
  );

  const contactFormRef = useRef();
  const userFormRef = useRef();

  useEffect(() => {
    if (creatingNew && !contact) {
      const d = createDefaultContact();
      setContact(d);
      setContactUser(null);
      setClient(null);
      setContactRevertTarget(d);
    }
  }, [
    creatingNew,
    contact,
    setContact,
    setContactUser,
    setClient,
    setContactRevertTarget,
  ]);

  useEffect(() => {
    if (!creatingNew && (!contact || !contact.id_contact) && originalContact) {
      setContact(originalContact);
      setContactUser(originalUser);
      setClient(originalClient);
      setContactRevertTarget(originalContact);
      setUserRevertTarget(originalContact.user);
    }
  }, [
    creatingNew,
    contact,
    originalContact,
    originalUser,
    originalClient,
    setContact,
    setContactUser,
    setContactRevertTarget,
  ]);

  useEffect(() => {
    if (!creatingNew && !client && originalContact) {
      setClient(originalClient);
    }
  }, [creatingNew, client, originalContact, originalClient, setClient]);

  const updateContact = (newValues) => {
    setContact({
      ...contact,
      ...newValues,
    });
    setContactChanged(true);
  };

  const updateUser = (newValues) => {
    setContactUser({
      ...contactUser,
      ...newValues,
    });
    setUserChanged(true);
  };

  function revertChanges() {
    setContact({ ...contactRevertTarget });
    setContactUser({ ...userRevertTarget });
    setContactChanged(false);
    setUserChanged(false);
  }

  function saveChanges(event) {
    if (contactChanged) {
      if (checkFormValidity(contactFormRef.current)) {
        setSubmittingContact(true);
        const contactData = { ...contact };

        if (creatingNew) {
          postContact({
            client: { id_client: id_client },
            contact: contactData,
          })
            .then((responseData) => {
              setSubmittingContact(false);
              setContactRevertTarget(contactData);
              setContactChanged(false);
              if (responseData.contact)
                navigate("/contact/" + responseData.contact.id_contact);
            })
            .catch((error) => {
              console.log("Post contact error", error);
            });
        } else {
          putContact({
            client: { id_client: id_client },
            contact: contactData,
          })
            .then((responseData) => {
              setSubmittingContact(false);
              setContactRevertTarget(contactData);
              setContactChanged(false);
            })
            .catch((error) => {
              console.log("Put contact error", error);
            });
        }
      }
    }

    if (userChanged && !creatingNew) {
      if (checkFormValidity(userFormRef.current)) {
        setSubmittingUser(true);
        const userData = { ...contactUser };

        putUser({
          user: userData,
        })
          .then((responseData) => {
            setSubmittingUser(false);
            setUserRevertTarget(userData);
            setUserChanged(false);
          })
          .catch((error) => {
            console.log("Put user error", error);
          });
      }
      setUserChanged(false);
    }
  }

  function beginActivating() {
    const u = createDefaultUser(
      isOrphan ? CLIENT_DATAROOM_ID : CLIENT_PRIMARY_ID,
    );
    u.email = contact.email;
    setContactUser(u);
    setUserRevertTarget(u);
    setActivating(true);
  }

  function sendActivation(event) {
    if (checkFormValidity(event.target.form)) {
      setSubmittingActivation(true);
      postUser({
        user: {
          id_contact: contact.id_contact,
          clients: isOrphan ? contactUser.clients : [client.id_client],
          email: contactUser.email,
          groups: contactUser.groups,
          indemnifiers: contactUser.indemnifiers,
          read: true,
          write: true,
        },
      })
        .then((response) => {
          setActivated(true);
          setSubmittingActivation(false);
        })
        .catch((error) => {
          console.log("Post user error", error);
        });
    }
  }

  function deactivate(event) {
    setSubmittingActivation(true);
    putUser({
      user: {
        id_contact: contact.id_contact,
        id_client: client ? client.id_client : null,
        email: contactUser.email,
        read: false,
        write: false,
        active: false,
      },
    })
      .then((response) => {
        setDeactivated(true);
        setReactivated(false);
        setSubmittingActivation(false);
      })
      .catch((error) => {
        console.log("Post user error", error);
      });
  }

  function reactivate(event) {
    setSubmittingActivation(true);
    putUser({
      user: {
        id_contact: contact.id_contact,
        id_client: client.id_client,
        email: contactUser.email,
        read: true,
        write: true,
        active: true,
      },
    })
      .then((response) => {
        setReactivated(true);
        setDeactivated(false);
        setSubmittingActivation(false);
      })
      .catch((error) => {
        console.log("Post user error", error);
      });
  }

  function resetUser(event) {
    setSubmittingActivation(true);
    postResetUser({})
      .then((response) => {
        setSubmittingActivation(false);
        setResetSent(true);
      })
      .catch((error) => {
        console.log("Post reset user error", error);
      });
  }

  const disabled =
    submittingActivation ||
    submittingContact ||
    submittingUser; /* || (createdContactId && !id_contact)*/

  const isPrimary =
    contactUser && contactUser.groups.indexOf(CLIENT_PRIMARY_ID) >= 0;
  const isSubsidiary =
    contactUser && contactUser.groups.indexOf(CLIENT_SUBSIDIARY_ID) >= 0;
  const isDataroom =
    contactUser && contactUser.groups.indexOf(CLIENT_DATAROOM_ID) >= 0;

  function renderAccessControls() {
    return (
      <>
        <Grid item xs={12} md={12}>
          <FormControl>
            <FormLabel
              sx={{ display: "block", marginBottom: 0.5 }}
              id="user-type-label"
            >
              User access{client ? " - " + client.name : ""}
            </FormLabel>
            <RadioGroup
              aria-labelledby="user-type-label"
              value={
                isPrimary
                  ? CLIENT_PRIMARY_ID
                  : isSubsidiary
                    ? CLIENT_SUBSIDIARY_ID
                    : isDataroom
                      ? CLIENT_DATAROOM_ID
                      : null
              }
              onChange={(event) => {
                updateUser({ groups: [parseInt(event.target.value)] });
                setUserChanged(true);
              }}
            >
              {!isOrphan && (
                <FormControlLabel
                  disabled={disabled}
                  value={CLIENT_PRIMARY_ID}
                  control={<Radio />}
                  label="Primary"
                />
              )}
              {!isOrphan && (
                <FormControlLabel
                  disabled={disabled}
                  value={CLIENT_SUBSIDIARY_ID}
                  control={<Radio />}
                  label="Subsidiary"
                />
              )}
              {isOrphan && (
                <FormControlLabel
                  disabled={disabled}
                  value={CLIENT_DATAROOM_ID}
                  control={<Radio />}
                  label="Dataroom"
                />
              )}
            </RadioGroup>
          </FormControl>
        </Grid>
        {isSubsidiary && (
          <Grid item xs={12} md={12} mb={3}>
            <FormLabel sx={{ display: "block", marginBottom: 0.5 }}>
              Available indemnifiers
            </FormLabel>
            <Grid container columnSpacing={2} rowSpacing={2}>
              {indemnifiers &&
                indemnifiers.map((indemnifier) => {
                  const index = contactUser.indemnifiers.indexOf(
                    indemnifier.id_indemnifier,
                  );
                  return (
                    <Grid item xs={12} md={6} key={indemnifier.id_indemnifier}>
                      <FormControlLabel
                        label={indemnifier.name}
                        control={
                          <Checkbox
                            disabled={disabled}
                            checked={index >= 0}
                            onChange={(event) => {
                              const newIndemnifiers = [
                                ...contactUser.indemnifiers,
                              ];
                              if (index >= 0) {
                                newIndemnifiers.splice(index, 1);
                              } else {
                                newIndemnifiers.push(
                                  indemnifier.id_indemnifier,
                                );
                              }
                              updateUser({ indemnifiers: newIndemnifiers });
                              setUserChanged(true);
                            }}
                          />
                        }
                      />
                    </Grid>
                  );
                })}
            </Grid>
          </Grid>
        )}
        {isDataroom && (
          <Grid item xs={12} md={12} mb={3}>
            <FormLabel sx={{ display: "block", marginBottom: 0.5 }}>
              Available clients
            </FormLabel>
            <Grid container columnSpacing={2} rowSpacing={2}>
              {clients.map((client) => {
                const index = contactUser.clients.indexOf(client.id_client);
                return (
                  <Grid item xs={12} md={6} key={client.id_client}>
                    <FormControlLabel
                      label={client.name}
                      control={
                        <Checkbox
                          disabled={disabled}
                          checked={index >= 0}
                          onChange={(event) => {
                            const newClients = [...contactUser.clients];
                            if (index >= 0) {
                              newClients.splice(index, 1);
                            } else {
                              newClients.push(client.id_client);
                            }
                            updateUser({ clients: newClients });
                            setUserChanged(true);
                          }}
                        />
                      }
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        )}
      </>
    );
  }

  return (
    <>
      {contact ? (
        <>
          <SectionTitle title={"Contact details"} />
          <SectionCard>
            <form ref={contactFormRef}>
              <Grid
                container
                columnSpacing={2}
                rowSpacing={2}
                alignItems="center"
              >
                <Grid item xs={12} md={6}>
                  <NullableTextField
                    label="Name"
                    fullWidth
                    disabled={disabled}
                    type="text"
                    required
                    value={contact.name}
                    onChange={(value) => {
                      updateContact({ name: value });
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <NullableTextField
                    label="Email"
                    fullWidth
                    disabled={disabled}
                    type="email"
                    required
                    value={contact.email}
                    onChange={(value) => {
                      updateContact({ email: value });
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <NullableTextField
                    label="Role"
                    fullWidth
                    disabled={disabled}
                    type="text"
                    value={contact.role}
                    onChange={(value) => {
                      updateContact({ role: value });
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControlLabel
                    label="Receives notifications"
                    control={
                      <Checkbox
                        disabled={disabled}
                        checked={contact.notifications || false}
                        onChange={(event) => {
                          updateContact({
                            notifications: event.target.checked,
                          });
                        }}
                        sx={{ marginLeft: 1 }}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <NullableTextField
                    label="Landline"
                    fullWidth
                    disabled={disabled}
                    type="text"
                    value={contact.landline}
                    onChange={(value) => {
                      updateContact({ landline: value });
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <NullableTextField
                    label="Mobile"
                    fullWidth
                    disabled={disabled}
                    type="text"
                    value={contact.mobile}
                    onChange={(value) => {
                      updateContact({ mobile: value });
                    }}
                  />
                </Grid>
              </Grid>
            </form>
          </SectionCard>

          {!creatingNew &&
            ((isOrphan || indemnifiers) && (!isOrphan || clients) ? (
              activated ? (
                <Alert sx={{ mb: 3 }}>Activation email sent</Alert>
              ) : activating ? (
                <SectionCard>
                  <form>
                    <Grid container columnSpacing={2} rowSpacing={2}>
                      {renderAccessControls()}
                      <Grid item xs={12} md={6}>
                        <NullableTextField
                          label="User email"
                          fullWidth
                          type="email"
                          required
                          disabled={disabled}
                          value={contactUser.email}
                          onChange={(value) => {
                            updateUser({ email: value });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FlexBox center sx={{ height: "100%" }}>
                          <Button
                            my="auto"
                            variant="contained"
                            disabled={disabled}
                            onClick={sendActivation}
                          >
                            Send activation email
                          </Button>
                        </FlexBox>
                      </Grid>
                    </Grid>
                  </form>
                </SectionCard>
              ) : contactUser ? (
                <>
                  <SectionTitle title={"User details"} />
                  <SectionCard>
                    <form ref={userFormRef}>
                      <Grid container columnSpacing={2} rowSpacing={2}>
                        {contactUser.active && renderAccessControls()}
                        <Grid item xs={12} md={6}>
                          <DisplayField
                            label="User Email"
                            fullWidth
                            value={contactUser.email || ""}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FlexBox center sx={{ height: "100%" }}>
                            {contactUser.active ? (
                              <Button
                                my="auto"
                                variant="outlined"
                                color="error"
                                disabled={disabled}
                                onClick={deactivate}
                              >
                                Deactivate
                              </Button>
                            ) : (
                              <Button
                                my="auto"
                                variant="outlined"
                                disabled={disabled}
                                onClick={reactivate}
                              >
                                Reactivate
                              </Button>
                            )}
                          </FlexBox>
                        </Grid>
                        {contactUser.active ? (
                          <Grid item xs={12} md={12}>
                            <FlexBox center sx={{ height: "100%" }}>
                              <Button
                                my="auto"
                                variant="outlined"
                                color="error"
                                disabled={disabled || resetSent}
                                onClick={resetUser}
                                sx={{ marginRight: 2 }}
                              >
                                Reset
                              </Button>
                              {resetSent ? (
                                <Typography>
                                  Reset email has been sent
                                </Typography>
                              ) : (
                                <Typography>
                                  User will be sent an email prompting them to
                                  set up their Authenticator again
                                </Typography>
                              )}
                            </FlexBox>
                          </Grid>
                        ) : null}
                      </Grid>
                    </form>
                  </SectionCard>

                  {deactivated ? (
                    <Alert sx={{ mb: 3 }}>User has been deactivated</Alert>
                  ) : reactivated ? (
                    <Alert sx={{ mb: 3 }}>User has been reactivated</Alert>
                  ) : null}
                </>
              ) : (
                <Box>
                  <Button
                    variant="contained"
                    disabled={disabled}
                    onClick={() => {
                      beginActivating();
                    }}
                  >
                    Activate user
                  </Button>
                </Box>
              )
            ) : (
              <CentreLoader />
            ))}

          {!creatingNew && requests && <ActivityTable requests={requests} />}

          <SaveDrawer
            open={contactChanged || (userChanged && !activating)}
            actions={
              <>
                <Button
                  variant="contained"
                  disabled={disabled}
                  onClick={saveChanges}
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  disabled={disabled}
                  onClick={revertChanges}
                >
                  Cancel
                </Button>
              </>
            }
          />
        </>
      ) : (
        <CentreLoader />
      )}
    </>
  );
}

function ActivityTable({ requests }) {
  const columns = [
    { key: "date", cell: "Date", width: "9em" },
    { key: "time", cell: "Time", width: "9em" },
    { key: "method", cell: "Method", width: "5em" },
    { key: "route", cell: "@/API route accessed" },
  ];

  const rows = requests
    ? requests.map((request, index) => {
        const m = new moment(request.timestamp).utc();

        const cells = [
          m.format("DD MMM YYYY"),
          m.format("hh:mm a"),
          request.method,
          request.route,
        ];

        return {
          key: index,
          cells: cells,
        };
      })
    : [];

  return (
    <>
      <SectionTitle title={"Recent activity"} />
      <SortableTable columns={columns} rows={rows} />
    </>
  );
}
