import React, { useContext } from "react";
import { Divider } from "@mui/material";

import API from "@/API";
import CentreLoader from "@/components/common/CentreLoader";
import PageTitle from "@/components/common/PageTitle";
import SectionCard from "@/components/common/SectionCard";
import SectionTitle from "@/components/common/SectionTitle";
import ClientTotals from "@/components/modules/ClientTotals";
import Financials from "@/components/modules/Financials";
import StatementDownloader from "@/components/modules/StatementDownloader";
import UserContext from "@/contexts/UserContext";
import { useGet } from "@/hooks/useAPI";
import { useDetectClient } from "@/hooks/useDetectClient";
import { useQuery } from "@/hooks/useQuery";

export default function DataroomClientPage() {
  const query = useQuery();
  const id_client = query.id === "add" ? null : parseInt(query.id);

  const { user } = useContext(UserContext);

  useDetectClient(id_client);

  const [clientData] = useGet(
    id_client ? API.getClient(user, id_client) : null,
  );
  const client = (clientData || {}).client;
  const facilitys = (clientData || {}).facilitys;

  //const [bondsData] = useGet(API.getBonds(user, id_client));
  //const { bonds } = bondsData || {};

  return (
    <>
      {client && facilitys ? (
        <>
          <PageTitle title={client.name} />

          <Divider />

          <ClientTotals facilitys={facilitys} />

          {/*
			<SectionTitle title="Facility Profile" />
			<SectionCard>
				<FacilityProfileGraph client={client} facilitys={facilitys} bonds={bonds} />
			</SectionCard>
			*/}

          <Divider sx={{ mb: 2 }} />

          <SectionTitle title="Statements" />
          <SectionCard>
            <StatementDownloader
              adminMode={false}
              client={client}
              facilitys={facilitys}
            />
          </SectionCard>

          <Divider sx={{ mb: 2 }} />

          <SectionTitle title="Financial Data" />
          <Financials adminMode={false} client={client} facilitys={facilitys} />
        </>
      ) : (
        <CentreLoader />
      )}
    </>
  );
}
