import { Box, Card, CardContent, Divider, Modal } from "@mui/material";

export default function CenteredModal({
  children,
  headerContent,
  width,
  minWidth,
  maxWidth,
  sx,
  ...props
}) {
  return (
    <Modal open sx={{ display: "flex", flexDirection: "column" }} {...props}>
      <Box
        sx={{
          margin: "auto",
          width: width || "300px",
          maxWidth: maxWidth || "100%",
          minWidth: minWidth || null,
        }}
      >
        <Card>
          {headerContent ? (
            <>
              <CardContent sx={{ width: "100%" }}>{headerContent}</CardContent>
              <Divider />
            </>
          ) : null}

          <CardContent sx={{ width: "100%", ...sx }}>{children}</CardContent>
        </Card>
      </Box>
    </Modal>
  );
}
