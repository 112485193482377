import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { LegendToggleOutlined } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  MenuItem,
  TablePagination,
  Toolbar,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router";

import ScaleScrollBar from "@/components/common/ScaleScrollBar";
import SectionTitle from "@/components/common/SectionTitle";
import FlexBox from "@/components/layout/FlexBox";
import TableControlSelect from "@/components/modules/tables/TableControlSelect";
import LayoutContext from "@/contexts/LayoutContext";
import useLocalStorage from "@/hooks/useLocalStorage";
import {
  EXTENSION_TYPE_MAINTENANCE,
  EXTENSION_TYPE_PERFORMANCE,
} from "@/ProjectFunctions";

//const pxPerYear = 300;

const COLOR_UNBONDED = "#5F5F5F";
const COLOR_PERFORMANCE = "#D18F5F";
const COLOR_MAINTENANCE = "#4D6679";
const COLOR_PERFORMANCE_EXTENSION = "#77F3BF";
const COLOR_MAINTENANCE_EXTENSION = "#AAC7FF";

export default function ProjectsChart({
  title,
  actions,
  preparedProjects,
  rowsPerPageOptions,
  sx,
}) {
  const { wideLayout } = useContext(LayoutContext);

  const navigate = useNavigate();

  const scroller = useRef(null);
  const [scrollerWidth, setScrollerWidth] = useState(0);
  const [chartScale, setChartScale] = useState({ lowValue: 0, highValue: 1 });

  if (!rowsPerPageOptions) rowsPerPageOptions = [10, 25, 100];

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useLocalStorage(
    "projectsChart.rowsPerPage",
    rowsPerPageOptions[0],
  );
  const [showLegend, setShowLegend] = useLocalStorage(
    "projectsChart.showLegend",
    true,
  );

  const PROJECT_TYPE_ACTIVE = "Active";
  const PROJECT_TYPE_ALL = "All";
  const projectTypeFilters = [PROJECT_TYPE_ALL, PROJECT_TYPE_ACTIVE];
  const [projectTypeFilter, setProjectTypeFilter] = useLocalStorage(
    "projectsChart.projectTypeFilter",
    PROJECT_TYPE_ACTIVE,
  );

  const PROJECT_ORDER_CREATED = "Project Created";
  const PROJECT_ORDER_START_DATE = "Date Starting";
  const PROJECT_ORDER_END_DATE = "Date Ending";
  const projectOrderFilters = [
    PROJECT_ORDER_CREATED,
    PROJECT_ORDER_START_DATE,
    PROJECT_ORDER_END_DATE,
  ];
  const [projectOrderFilter, setProjectOrderFilter] = useLocalStorage(
    "projectsChart.projectOrderFilter",
    PROJECT_ORDER_CREATED,
  );

  const PROJECT_SHOW_EXTENSIONS_YES = "Yes";
  const PROJECT_SHOW_EXTENSIONS_NO = "No";
  const projectShowExtensionsFilters = [
    PROJECT_SHOW_EXTENSIONS_YES,
    PROJECT_SHOW_EXTENSIONS_NO,
  ];
  const [projectShowExtensionsFilter, setProjectShowExtensionsFilter] =
    useLocalStorage(
      "projectsChart.projectShowExtensionsFilter",
      PROJECT_SHOW_EXTENSIONS_YES,
    );

  const filteredProjects = useMemo(() => {
    let filtered = [...preparedProjects];

    switch (projectTypeFilter) {
      case PROJECT_TYPE_ACTIVE:
        const now = new Date();
        filtered = filtered.filter((project) => {
          return project.endDate >= now;
        });
        break;
      default:
        break;
    }

    switch (projectOrderFilter) {
      case PROJECT_ORDER_CREATED:
        filtered.sort((a, b) => {
          return (
            new Date(a.project.timestamp_created).getTime() -
            new Date(b.project.timestamp_created).getTime()
          );
        });
        break;
      case PROJECT_ORDER_START_DATE:
        filtered.sort((a, b) => {
          return (
            a.project.date_start.getTime() - b.project.date_start.getTime()
          );
        });
        break;
      case PROJECT_ORDER_END_DATE:
        filtered.sort((a, b) => {
          return a.endDate.getTime() - b.endDate.getTime();
        });
        break;
      default:
        break;
    }

    return filtered;
  }, [preparedProjects, projectTypeFilter, projectOrderFilter]);

  const showExtensions =
    projectShowExtensionsFilter === PROJECT_SHOW_EXTENSIONS_YES;

  const handleResize = useCallback(() => {
    setScrollerWidth(
      scroller && scroller.current ? scroller.current.clientWidth : 0,
    );
  }, [scroller]);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  const { startTime, endTime, minValue, maxValue, timeLines, pxPerYear } =
    useMemo(() => {
      let startDate = new Date();
      let endDate = startDate;
      let minValue = undefined;
      let maxValue = 0;

      if (filteredProjects) {
        filteredProjects.forEach((preparedProject) => {
          const {
            project,
            currentPerformanceDate,
            currentMaintenanceDate,
            currentProjectValue,
          } = preparedProject;
          if (project.date_start && project.date_start < startDate)
            startDate = project.date_start;
          if (currentPerformanceDate && currentPerformanceDate > endDate)
            endDate = currentPerformanceDate;
          if (currentMaintenanceDate && currentMaintenanceDate > endDate)
            endDate = currentMaintenanceDate;

          minValue =
            minValue === undefined
              ? currentProjectValue
              : Math.min(minValue, currentProjectValue);
          maxValue = Math.max(maxValue, currentProjectValue);
        });
      }

      const timeLines = [];
      const date = new Date(startDate);
      date.setMonth(6);
      date.setDate(1);
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      date.setMilliseconds(0);
      if (date < startDate) startDate = new Date(date);
      const startTime = startDate.getTime();

      let i = 0;
      do {
        if (i > 0) date.setMonth(date.getMonth() + 6);
        const time = date.getTime();
        timeLines.push({
          time: time,
          date: new Date(date),
          label: date.getMonth() === 0 ? "" + date.getFullYear() : null,
        });
        if (date > endDate) endDate = new Date(date);
        i++;
      } while ((date < endDate || i < 4) && i < 30);

      const endTime = endDate.getTime();

      const visibleFraction = Math.max(
        0.01,
        chartScale.highValue - chartScale.lowValue,
      );
      const totalWidth = scrollerWidth / visibleFraction;
      const numYears = (endTime - startTime) / (1000 * 60 * 60 * 24 * 365.25);
      const pxPerYear = totalWidth / numYears;

      timeLines.forEach((line) => {
        line.x =
          ((line.time - startTime) / (1000 * 60 * 60 * 24 * 365.25)) *
          pxPerYear;
      });

      return {
        startDate,
        endDate,
        startTime,
        endTime,
        minValue,
        maxValue,
        timeLines,
        pxPerYear,
      };
    }, [
      filteredProjects,
      scrollerWidth,
      chartScale.highValue,
      chartScale.lowValue,
    ]);

  const visibleProjects = useMemo(() => {
    return filteredProjects
      ? filteredProjects.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
      : [];
  }, [filteredProjects, page, rowsPerPage]);

  useEffect(() => {
    if (page * rowsPerPage >= filteredProjects.length) {
      setPage(
        Math.max(0, Math.floor((filteredProjects.length - 1) / rowsPerPage)),
      );
    }
  }, [page, rowsPerPage, filteredProjects]);

  useEffect(() => {
    if (scroller && filteredProjects) {
      const element = scroller.current;
      element.scrollLeft = chartScale.lowValue * element.scrollWidth;
    }
  }, [scroller, filteredProjects, chartScale.lowValue]);

  return (
    <>
      <SectionTitle title={title} actions={actions} />

      <Card sx={sx}>
        <CardContent sx={{ position: "relative", padding: 0 }}>
          <Box
            ref={scroller}
            sx={{
              borderBottom: "1px solid #e0e0e0",
              paddingBottom: "10px",
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                position: "relative",
                paddingTop: "20px",
                paddingBottom: "40px",
                minHeight: "240px",
              }}
            >
              {timeLines &&
                timeLines.map((timeLine) => {
                  return (
                    <Box
                      key={timeLine.time}
                      sx={{
                        position: "absolute",
                        top: 0,
                        bottom: "20px",
                        borderLeft: "1px solid #cccccc",
                        left: timeLine.x + "px",
                      }}
                    >
                      {timeLine.label ? (
                        <Typography
                          variant="label"
                          sx={{
                            position: "absolute",
                            top: "100%",
                            transform: "translate(-50%, 5px)",
                          }}
                        >
                          {timeLine.label}
                        </Typography>
                      ) : null}
                    </Box>
                  );
                })}

              {visibleProjects &&
                visibleProjects.map((project) => {
                  return (
                    <ProjectBar
                      key={project.project.id_project}
                      project={project}
                      startTime={startTime}
                      endTime={endTime}
                      pxPerYear={pxPerYear}
                      minValue={minValue}
                      maxValue={maxValue}
                      showExtensions={showExtensions}
                      onClick={() => {
                        navigate("/project/" + project.project.id_project);
                      }}
                    />
                  );
                })}
            </Box>
          </Box>

          <Legend
            sx={{
              left: 20,
              bottom: 40,
              cursor: "ne-resize",
              userSelect: "none",
            }}
            collapsed={!showLegend}
            onMouseDown={() => {
              setShowLegend(!showLegend);
            }}
          />
        </CardContent>

        <ScaleScrollBar
          lowValue={chartScale.lowValue}
          highValue={chartScale.highValue}
          minRange={0.1}
          maxRange={1}
          onChange={setChartScale}
        />

        <Toolbar
          sx={{
            minHeight: "52px",
            justifyContent: wideLayout ? "space-between" : null,
            alignItems: wideLayout ? null : "start",
            flexFlow: wideLayout ? "row" : "column",
          }}
        >
          <TableControlSelect
            label="Project Type:"
            sx={{ minHeight: "52px" }}
            value={projectTypeFilter}
            onChange={(event) => {
              setProjectTypeFilter(event.target.value);
            }}
          >
            {projectTypeFilters.map((type) => {
              return (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              );
            })}
          </TableControlSelect>

          <TableControlSelect
            label="Order By:"
            sx={{ minHeight: "52px" }}
            value={projectOrderFilter}
            onChange={(event) => {
              setProjectOrderFilter(event.target.value);
            }}
          >
            {projectOrderFilters.map((type) => {
              return (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              );
            })}
          </TableControlSelect>

          <TableControlSelect
            label="Show Extensions:"
            sx={{ minHeight: "52px" }}
            value={projectShowExtensionsFilter}
            onChange={(event) => {
              setProjectShowExtensionsFilter(event.target.value);
            }}
          >
            {projectShowExtensionsFilters.map((type) => {
              return (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              );
            })}
          </TableControlSelect>

          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={filteredProjects.length}
            rowsPerPage={rowsPerPage}
            page={Math.max(
              0,
              Math.min(
                page,
                Math.floor((filteredProjects.length - 1) / rowsPerPage),
              ),
            )}
            onPageChange={(event, newPage) => {
              setPage(newPage);
            }}
            onRowsPerPageChange={(event) => {
              setRowsPerPage(+event.target.value);
              setPage(0);
            }}
            sx={{
              ".MuiToolbar-root": {
                padding: 0,
              },
              ".MuiTablePagination-spacer": {
                display: "none",
              },
            }}
          />
        </Toolbar>
      </Card>
    </>
  );
}

function ProjectBar({
  project: {
    project,
    bonds,
    typedExtensions,
    currentPerformanceDate,
    originalMaintenanceDate,
    originalPerformanceDate,
    currentMaintenanceDate,
    currentProjectValue,
  },
  startTime,
  endTime,
  pxPerYear,
  minValue,
  maxValue,
  showExtensions,
  onClick,
}) {
  const extensionLines = useMemo(() => {
    const pLines = [];
    const mLines = [];
    if (
      originalPerformanceDate &&
      currentPerformanceDate &&
      originalPerformanceDate.getTime() !== currentPerformanceDate.getTime()
    ) {
      pLines.push({
        type: EXTENSION_TYPE_PERFORMANCE,
        date: originalPerformanceDate,
        color: COLOR_PERFORMANCE_EXTENSION,
      });
    }
    if (
      originalMaintenanceDate &&
      currentMaintenanceDate &&
      originalMaintenanceDate.getTime() !== currentMaintenanceDate.getTime()
    ) {
      mLines.push({
        type: EXTENSION_TYPE_MAINTENANCE,
        date: originalMaintenanceDate,
        color: COLOR_MAINTENANCE_EXTENSION,
      });
    }
    typedExtensions.forEach((extension) => {
      switch (extension.type) {
        case EXTENSION_TYPE_PERFORMANCE:
          pLines.push({
            type: extension.type,
            date: new Date(extension.date),
            color: COLOR_PERFORMANCE_EXTENSION,
          });
          break;
        case EXTENSION_TYPE_MAINTENANCE:
          mLines.push({
            type: extension.type,
            date: new Date(extension.date),
            color: COLOR_MAINTENANCE_EXTENSION,
          });
          break;
        default:
          break;
      }
    });
    pLines.pop(); // Don't need last of each because that is the current (end of graph)
    mLines.pop();

    return pLines.concat(mLines);
  }, [
    originalPerformanceDate,
    originalMaintenanceDate,
    currentPerformanceDate,
    currentMaintenanceDate,
    typedExtensions,
  ]);

  if (!project.date_start || !currentPerformanceDate) return null;

  const isBonded = bonds && bonds.length > 0;

  const startDate = project.date_start;
  let originalDate = originalPerformanceDate;
  if (!originalDate || originalMaintenanceDate > originalDate)
    originalDate = originalMaintenanceDate;
  let endDate = currentPerformanceDate;
  if (!endDate || currentMaintenanceDate > endDate)
    endDate = currentMaintenanceDate;

  const startF = (startDate.getTime() - startTime) / (endTime - startTime);
  const performanceF =
    (currentPerformanceDate.getTime() - startTime) / (endTime - startTime);
  const endF = (endDate.getTime() - startTime) / (endTime - startTime);
  const yearsSpanned = (endTime - startTime) / (1000 * 60 * 60 * 24 * 365.25);
  const totalWidth = yearsSpanned * pxPerYear;

  const valueF =
    maxValue > minValue
      ? (currentProjectValue - minValue) / (maxValue - minValue)
      : 0;

  const showPerformanceBar = endF > performanceF;

  const shadow = "inset 0px 1px 3px rgba(0, 0, 0, 0.25)";

  return (
    <FlexBox
      center
      onClick={onClick}
      sx={{
        mb: "3px",
        borderRadius: "3px",
        position: "relative",
        left: startF * totalWidth + "px",
        width: (endF - startF) * totalWidth + "px",
        height: 36 + valueF * 40 + "px",
        cursor: "pointer",
      }}
    >
      <Box
        sx={{
          background: isBonded ? COLOR_PERFORMANCE : COLOR_UNBONDED,
          borderRadius: showPerformanceBar ? "3px 0 0 3px" : "3px",
          boxShadow: shadow,
          position: "absolute",
          left: 0,
          top: 0,
          bottom: 0,
          width: (performanceF - startF) * totalWidth + "px",
        }}
      />

      {showPerformanceBar ? (
        <Box
          sx={{
            background: isBonded ? COLOR_MAINTENANCE : COLOR_UNBONDED,
            borderRadius: "0 3px 3px 0",
            boxShadow: shadow,
            position: "absolute",
            right: 0,
            top: 0,
            bottom: 0,
            width: (endF - performanceF) * totalWidth + "px",
          }}
        />
      ) : null}

      <Typography
        sx={{
          color: "white",
          padding: "0 16px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          zIndex: 1,
        }}
      >
        {project.name}
      </Typography>

      {showExtensions
        ? extensionLines.map((extension, index) => {
            return (
              <ExtensionFlag
                key={index}
                color={extension.color}
                sx={{
                  left:
                    ((extension.date.getTime() - startTime) /
                      (endTime - startTime) -
                      startF) *
                      totalWidth +
                    "px",
                }}
              />
            );
          })
        : null}
    </FlexBox>
  );
}

function Legend({ collapsed, onClick, onMouseDown, sx }) {
  return (
    <Box
      onClick={onClick}
      onMouseDown={onMouseDown}
      sx={{
        position: "absolute",
        background: "#FFFFFF",
        boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.25)",
        borderRadius: "5px",
        padding: collapsed ? "5px" : "13px 15px",
        lineHeight: collapsed ? 0 : null,
        zIndex: 2,
        ...sx,

        ul: {
          listStyle: "none",
          "li:not(:last-child)": {
            margin: "0 0 15px",
          },
          ".icon": {
            position: "relative",
            display: "inline-block",
            width: "30px",
            height: "20px",
          },
        },
      }}
    >
      {collapsed ? (
        <LegendToggleOutlined sx={{ opacity: 0.5 }} />
      ) : (
        <ul>
          <li>
            <FlexBox align="end">
              <Box className="icon">
                <LegendBox color={COLOR_PERFORMANCE} />
              </Box>
              <Typography variant="label">Performance</Typography>
            </FlexBox>
          </li>
          <li>
            <FlexBox align="end">
              <Box className="icon">
                <LegendBox color={COLOR_MAINTENANCE} />
              </Box>
              <Typography variant="label">Maintenance</Typography>
            </FlexBox>
          </li>
          <li>
            <FlexBox align="end">
              <Box className="icon">
                <ExtensionFlag
                  color={COLOR_PERFORMANCE_EXTENSION}
                  sx={{ left: "3px" }}
                />
              </Box>
              <Typography variant="label">Performance Extension</Typography>
            </FlexBox>
          </li>
          <li>
            <FlexBox align="end">
              <Box className="icon">
                <ExtensionFlag
                  color={COLOR_MAINTENANCE_EXTENSION}
                  sx={{ left: "3px" }}
                />
              </Box>
              <Typography variant="label">Maintenance Extension</Typography>
            </FlexBox>
          </li>
          <li>
            <FlexBox align="end">
              <Box className="icon">
                <LegendBox color={COLOR_UNBONDED} />
              </Box>
              <Typography variant="label">Unbonded</Typography>
            </FlexBox>
          </li>
        </ul>
      )}
    </Box>
  );
}

function LegendBox({ color }) {
  return (
    <Box
      sx={{
        width: "20px",
        height: "20px",
        background: color,
        boxShadow: "inset 0px 1px 3px rgba(0, 0, 0, 0.25)",
        borderRadius: "3px",
      }}
    />
  );
}

function ExtensionFlag({ color, sx, ...rest }) {
  const size = 10;
  return (
    <Box
      {...rest}
      sx={{
        position: "absolute",
        top: 0,
        bottom: 0,
        borderLeft: "2px solid " + color,
        ...sx,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "25%",
          left: 0,
          width: 0,
          height: 0,
          borderTop: size / 2 + "px transparent solid",
          borderBottom: size / 2 + "px transparent solid",
          borderLeft: size + "px " + color + " solid",
          marginTop: -size / 2 + "px",
        }}
      />
    </Box>
  );
}
