import { Checkbox } from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router";

import FilterableTable from "@/components/modules/tables/FilterableTable";
import { compareDateStrings } from "@/Utils";

export default function ProjectsTable({
  title,
  projects,
  enabledProjects,
  onToggleProject,
  actions,
}) {
  const navigate = useNavigate();

  function setProjectEnabled(id_project, value) {
    if (onToggleProject) onToggleProject(id_project, value);
  }

  const columns = [
    { key: "chart", cell: "Chart", width: 100 },
    { key: "name", cell: "Name", minWidth: 200 },
    { key: "date_start", cell: "Start Date", compareFunc: compareDateStrings },
    {
      key: "date_performance",
      cell: "Completion",
      compareFunc: compareDateStrings,
    },
    {
      key: "date_maintenance",
      cell: "Maintenance",
      compareFunc: compareDateStrings,
    },
  ];

  const rows = [];
  if (projects && enabledProjects) {
    projects.forEach(
      ({ project, currentPerformanceDate, currentMaintenanceDate }) => {
        rows.push({
          key: project.id_project,
          cells: [
            <Checkbox
              checked={enabledProjects[project.id_project]}
              onChange={(event) => {
                setProjectEnabled(project.id_project, event.target.checked);
              }}
            />,
            project.name || "",
            project.date_start
              ? moment(project.date_start).format("DD/MM/YYYY")
              : null,
            currentPerformanceDate
              ? moment(currentPerformanceDate).format("DD/MM/YYYY")
              : null,
            currentMaintenanceDate
              ? moment(currentMaintenanceDate).format("DD/MM/YYYY")
              : null,
          ],
          onClick: (event) => {
            if (event.target.tagName.toUpperCase() !== "INPUT") {
              navigate("/project/" + project.id_project);
            }
          },
        });
      },
    );
  }

  return (
    <FilterableTable
      title={title}
      actions={actions}
      columns={columns}
      rows={rows}
      sx={{ marginBottom: 4 }}
    />
  );
}
