import React from "react";
import { Checkbox } from "@mui/material";
import { useNavigate } from "react-router";

import FilterableTable from "@/components/modules/tables/FilterableTable";

export default function ContactsTable({ title, contacts, actions }) {
  const navigate = useNavigate();

  const columns = [
    { key: "name", cell: "Name", width: 200 },
    { key: "role", cell: "Role", width: 120 },
    { key: "email", cell: "Email", width: 200 },
    { key: "landline", cell: "Landline", width: 120 },
    { key: "mobile", cell: "Mobile", width: 120 },
    { key: "user", cell: "User" /*width: 120*/ },
  ];

  const rows = [];
  contacts.forEach(
    ({ id_contact, name, role, email, landline, mobile, user }) => {
      rows.push({
        key: id_contact,
        cells: [
          name,
          role,
          email,
          landline,
          mobile,
          user ? <Checkbox checked={true} disabled /> : null,
        ],
        onClick: () => {
          navigate("/contact/" + id_contact);
        },
      });
    },
  );

  return (
    <FilterableTable
      title={title}
      actions={actions}
      columns={columns}
      rows={rows}
      sx={{ marginBottom: 4 }}
    />
  );
}
