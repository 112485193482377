import { Box } from "@mui/material";

export default function FlexBox({
  children,
  sx,
  column,
  center,
  align,
  justify,
  ...props
}) {
  return (
    <Box
      {...props}
      sx={{
        ...sx,
        display: "flex",
        flexDirection: column ? "column" : null,
        alignItems: center ? "center" : align,
        justifyContent: justify,
      }}
    >
      {children}
    </Box>
  );
}
