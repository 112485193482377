import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Button, Checkbox, Grid } from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router";

import API from "@/API";
import AddButton from "@/components/common/AddButton";
import CentreLoader from "@/components/common/CentreLoader";
import NullableTextField from "@/components/common/NullableTextField";
import PageTitle from "@/components/common/PageTitle";
import SectionCard from "@/components/common/SectionCard";
import SectionTitle from "@/components/common/SectionTitle";
import SaveDrawer from "@/components/modules/SaveDrawer";
import StatementDownloader from "@/components/modules/StatementDownloader";
import PaginatedTable from "@/components/modules/tables/PaginatedTable";
import StaticDataContext from "@/contexts/StaticDataContext";
import UserContext from "@/contexts/UserContext";
import { useGet, usePost, usePut } from "@/hooks/useAPI";
import { useDetectClient } from "@/hooks/useDetectClient";
import { useQuery } from "@/hooks/useQuery";
import {
  checkFormValidity,
  formatMoney,
  generateUniqueNumberedName,
  indexByField,
  searchByField,
  tagForRemoval,
} from "@/Utils";

function createDefaultClient() {
  return {
    name: null,
    code: null,
    abn: null,
  };
}

export default function EditClientPage() {
  const navigate = useNavigate();

  const query = useQuery();
  const id_client = query.id === "add" ? null : parseInt(query.id);
  const creatingNew = query.id === "add";

  useDetectClient(id_client);

  const { user } = useContext(UserContext);

  const staticData = useContext(StaticDataContext);

  const [originalClientData] = useGet(
    id_client ? API.getClient(user, id_client) : null,
  );
  const {
    client: originalClient,
    facilitys: originalFacilitys,
    indemnifiers: originalIndemnifiers,
    contacts: originalContacts,
    xero_contacts: originalXeroContacts,
  } = originalClientData || {};

  /*
	const [originalXeroData] = useGet(API.getXeroContacts(user));
	const { xero_contacts: allXeroContacts } = originalXeroData || {};
	*/

  const [client, setClient] = useState(originalClient);
  const [facilitys, setFacilitys] = useState(originalFacilitys);
  const [indemnifiers, setIndemnifiers] = useState(originalIndemnifiers);
  const [contacts, setContacts] = useState(originalContacts);
  const [xeroContacts, setXeroContacts] = useState(originalXeroContacts);

  const [facilityStates, setFacilityStates] = useState([]);
  const [facilityOrder, setFacilityOrder] = useState([]);

  const [revertTarget, setRevertTarget] = useState(originalClientData);

  const [changed, setChanged] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [postClient] = usePost(API.postClient(user));
  const [putClient] = usePut(id_client ? API.putClient(user, id_client) : null);

  const formRef = useRef();

  useEffect(() => {
    if (creatingNew) {
      const d = createDefaultClient();
      setClient(d);
      setFacilitys([]);
      setIndemnifiers([]);
      setContacts([]);
      setXeroContacts([]);
      setRevertTarget({
        client: d,
        facilitys: [],
        indemnifiers: [],
        contacts: [],
        xero_contacts: [],
      });
    }
  }, [
    creatingNew,
    setClient,
    setFacilitys,
    setIndemnifiers,
    setContacts,
    setXeroContacts,
  ]);

  useEffect(() => {
    if (!creatingNew) {
      setClient(originalClient);
      setFacilitys(originalFacilitys);
      setIndemnifiers(originalIndemnifiers);
      setContacts(originalContacts);
      setXeroContacts(originalXeroContacts);
      setRevertTarget(
        structuredClone({
          client: originalClient,
          facilitys: originalFacilitys,
          indemnifiers: originalIndemnifiers,
          contacts: originalContacts,
          xero_contacts: originalXeroContacts,
        }),
      );
    }
  }, [
    creatingNew,
    originalClient,
    originalFacilitys,
    originalIndemnifiers,
    originalContacts,
    originalXeroContacts,
    setClient,
    setFacilitys,
    setIndemnifiers,
    setContacts,
    setXeroContacts,
  ]);

  // Construct facilities state
  useEffect(() => {
    if (!facilitys || !staticData) return;

    const updatedFacilityStates = [];
    const updatedFacilityOrder = [];

    staticData.issuers.forEach((issuer, index) => {
      const facility = searchByField(facilitys, "id_issuer", issuer.id_issuer);

      const facilityState = {
        id_facility: facility && facility.id_facility,
        id_issuer: issuer.id_issuer,
        facilityIndex:
          facility &&
          indexByField(facilitys, "id_facility", facility.id_facility),
        issuerIndex: index,
        name: issuer.name,
        active: facility !== null,
      };

      updatedFacilityStates.push(facilityState);
      if (facilityState.active) updatedFacilityOrder.push(facilityState);
    });

    setFacilityStates(updatedFacilityStates);
    setFacilityOrder(updatedFacilityOrder);
  }, [facilitys, staticData, setFacilityStates, setFacilityOrder]);

  function revert() {
    const clone = structuredClone(revertTarget);
    setClient(clone.client);
    setFacilitys(clone.facilitys);
    setIndemnifiers(clone.indemnifiers);
    setContacts(clone.contacts);
    setXeroContacts(clone.xero_contacts);
    setChanged(false);
  }

  const updateClient = useCallback(
    (newValues) => {
      setClient({
        ...client,
        ...newValues,
      });
      setChanged(true);
    },
    [client],
  );

  function createDefaultIndemnifier() {
    return {
      active: true,
      id_indemnifier: generateUniqueNumberedName(
        indemnifiers,
        "id_indemnifier",
        "New Indemnifier ",
      ),
      custom: true,
      id_client: id_client,
      name: null,
      abn: null,
      facility_indemnifiers: [],
    };
  }

  function addIndemnifier() {
    setIndemnifierAt(indemnifiers.length, createDefaultIndemnifier());
  }

  const setIndemnifierAt = useCallback(
    (index, updatedIndemnifier) => {
      const updatedIndemnifiers = [...indemnifiers];
      updatedIndemnifiers[index] = updatedIndemnifier;
      setIndemnifiers(updatedIndemnifiers);
      setChanged(true);
    },
    [indemnifiers],
  );

  const setIndemnifierFacilityActive = useCallback(
    (id_indemnifier, id_facility, value) => {
      const indemnifier = searchByField(
        indemnifiers,
        "id_indemnifier",
        id_indemnifier,
      );
      const updatedIndemnifier = {
        ...indemnifier,
        facilitys: [...indemnifier.facility_indemnifiers] || [],
      };

      if (value) {
        updatedIndemnifier.facility_indemnifiers.push({
          id_facility: id_facility,
        });
      } else {
        updatedIndemnifier.facility_indemnifiers.splice(
          indexByField(
            updatedIndemnifier.facility_indemnifiers,
            "id_facility",
            id_facility,
          ),
          1,
        );
      }

      setIndemnifierAt(indemnifiers.indexOf(indemnifier), updatedIndemnifier);
    },
    [indemnifiers, setIndemnifierAt],
  );

  const updateIndemnifierField = useCallback(
    (id_indemnifier, field, value) => {
      const indemnifier = searchByField(
        indemnifiers,
        "id_indemnifier",
        id_indemnifier,
      );

      const updatedIndemnifier = { ...indemnifier, [field]: value };

      setIndemnifierAt(indemnifiers.indexOf(indemnifier), updatedIndemnifier);
    },
    [indemnifiers, setIndemnifierAt],
  );

  function save(event) {
    if (checkFormValidity(formRef.current)) {
      setSubmitting(true);
      const r = structuredClone({
        client: client,
        facilitys: facilitys,
        indemnifiers: indemnifiers,
        contacts: contacts,
        xero_contacts: xeroContacts,
      });

      // Process removals
      const dataToSubmit = structuredClone(r);
      if (!creatingNew) dataToSubmit.client.active = true;
      dataToSubmit.indemnifiers.forEach((indemnifier) => {
        if (indemnifier.custom) {
          delete indemnifier.custom;
          delete indemnifier.id_indemnifier;
        } else {
          const revertIndemnifier = searchByField(
            revertTarget.indemnifiers,
            "id_indemnifier",
            indemnifier.id_indemnifier,
          );
          if (revertIndemnifier)
            tagForRemoval(
              indemnifier.facility_indemnifiers,
              revertIndemnifier.facility_indemnifiers,
              "id_facility",
            );
        }
      });

      if (creatingNew) {
        postClient({
          client: dataToSubmit.client,
        })
          .then((responseData) => {
            setSubmitting(false);
            setRevertTarget(r);
            setChanged(false);
            navigate("/client/" + responseData.client.id_client);
          })
          .catch((error) => {
            console.log("Post client error", error);
          });
      } else {
        putClient(dataToSubmit)
          .then((responseData) => {
            setSubmitting(false);
            setRevertTarget(r);
            setChanged(false);
          })
          .catch((error) => {
            console.log("Put client error", error);
          });
      }
    }
  }

  let canAddFacility = false;
  if (staticData && facilitys) {
    staticData.issuers.forEach((issuer) => {
      if (indexByField(facilitys, "id_issuer", issuer.id_issuer) === -1)
        canAddFacility = true;
    });
  }

  return (
    <>
      {staticData &&
      client &&
      facilitys &&
      facilityStates &&
      facilityOrder &&
      xeroContacts /* && allXeroContacts*/ ? (
        <>
          <PageTitle title={creatingNew ? "Add client" : client.name} />

          <form ref={formRef}>
            <SectionCard>
              <SectionTitle title="Client details" />
              <Grid container columnSpacing={2} rowSpacing={2}>
                <Grid item xs={12} md={6}>
                  <NullableTextField
                    label="Name"
                    fullWidth
                    type="text"
                    required
                    value={client.name}
                    onChange={(value) => {
                      updateClient({ name: value });
                    }}
                  />
                </Grid>
                <Grid item xs={6} md={3}>
                  <NullableTextField
                    label="Code"
                    fullWidth
                    type="text"
                    required
                    value={client.code}
                    onChange={(value) => {
                      updateClient({ code: value });
                    }}
                  />
                </Grid>
                <Grid item xs={6} md={3}>
                  <NullableTextField
                    label="ABN"
                    fullWidth
                    type="text"
                    required
                    value={client.abn}
                    onChange={(value) => {
                      updateClient({ abn: value });
                    }}
                  />
                </Grid>
                {/*
						<Grid item xs={12} md={6}>	
							<FlexBox>
								<TextField label="Xero Link"
									value={(xeroContacts && xeroContacts.length > 0) ? xeroContacts[0].id_xero_contact : ''}
									select
									onChange={(event) => {
										let contact = searchByField(allXeroContacts, 'id_xero_contact', event.target.value);
										if (contact) {
											contact = {...contact, active: 1};											
											setXeroContacts([contact]);
											setChanged(true);
										}
									}}
									sx={{flex: '1 1 0'}}
								>
									{ allXeroContacts.map((xeroContact) => {
										if (xeroContact.active && xeroContact.id_client !== client.id_client) return null;
										return <MenuItem key={xeroContact.id_xero_contact} value={xeroContact.id_xero_contact}>{xeroContact.name}</MenuItem>
									})}				
								</TextField>
								{(xeroContacts && xeroContacts.length > 0) &&
									<IconButton sx={{margin: 'auto 0 auto 8px'}} onClick={() => {
										setXeroContacts([]);
										setChanged(true);
									}}>
										<LinkOffOutlined />
									</IconButton>
								}
							</FlexBox>						
						</Grid>
						*/}
              </Grid>
              <SectionTitle title="Applications" mt={2} />
              <Grid container columnSpacing={2} rowSpacing={2}>
                <Grid item xs={12} md={12}>
                  <NullableTextField
                    label="Business address"
                    fullWidth
                    type="text"
                    value={client.address}
                    onChange={(value) => {
                      updateClient({ address: value });
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <NullableTextField
                    label="Contact name"
                    fullWidth
                    type="text"
                    value={client.contact}
                    onChange={(value) => {
                      updateClient({ contact: value });
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <NullableTextField
                    label="Telephone"
                    fullWidth
                    type="text"
                    value={client.phone}
                    onChange={(value) => {
                      updateClient({ phone: value });
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <NullableTextField
                    label="Job title"
                    fullWidth
                    type="text"
                    value={client.role}
                    onChange={(value) => {
                      updateClient({ role: value });
                    }}
                  />
                </Grid>
              </Grid>
            </SectionCard>

            {!creatingNew && (
              <>
                <ContactsTable
                  contacts={contacts}
                  navigate={navigate}
                  actions={
                    <AddButton to={"/contact/add?client=" + id_client}>
                      Add contact
                    </AddButton>
                  }
                />

                <FacilitysTable
                  facilityStates={facilityStates}
                  facilitys={facilitys}
                  staticData={staticData}
                  navigate={navigate}
                  actions={
                    canAddFacility && (
                      <AddButton to={"/facility/add?client=" + id_client}>
                        Add facility
                      </AddButton>
                    )
                  }
                />

                <IndemnifiersTable
                  facilityOrder={facilityOrder}
                  indemnifiers={indemnifiers}
                  updateIndemnifierField={updateIndemnifierField}
                  setIndemnifierFacilityActive={setIndemnifierFacilityActive}
                  actions={
                    <AddButton onClick={addIndemnifier}>
                      Add indemnifier
                    </AddButton>
                  }
                />
              </>
            )}
          </form>

          {!creatingNew && (
            <>
              <SectionTitle title="Statements" />
              <SectionCard>
                <StatementDownloader
                  adminMode={true}
                  client={client}
                  facilitys={facilitys}
                />
              </SectionCard>
            </>
          )}

          <SaveDrawer
            open={changed}
            actions={
              <>
                <Button
                  variant="contained"
                  disabled={submitting}
                  onClick={save}
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  disabled={submitting}
                  onClick={revert}
                >
                  Cancel
                </Button>
              </>
            }
          />
        </>
      ) : (
        <CentreLoader />
      )}
    </>
  );
}

// ContactsTable --------------------------------------------------------------
function ContactsTable({ contacts, navigate, actions }) {
  const columns = [
    { key: "name", cell: "Name", width: 200 },
    { key: "role", cell: "Role", width: 120 },
    { key: "email", cell: "Email", width: 200 },
    { key: "landline", cell: "Landline", width: 120 },
    { key: "mobile", cell: "Mobile", width: 120 },
    { key: "user", cell: "User" /*width: 120*/ },
  ];

  const rows = [];
  contacts.forEach(
    ({ id_contact, name, role, email, landline, mobile, user }) => {
      rows.push({
        key: id_contact,
        cells: [
          name,
          role,
          email,
          landline,
          mobile,
          user ? <Checkbox checked={true} disabled /> : null,
        ],
        onClick: () => {
          navigate("/contact/" + id_contact);
        },
      });
    },
  );

  return (
    <TableCard title="People" actions={actions} columns={columns} rows={rows} />
  );
}

// FacilitysTable --------------------------------------------------------------
function FacilitysTable({
  facilityStates,
  facilitys,
  staticData,
  navigate,
  actions,
}) {
  const columns = [
    { key: "active", cell: "Active", width: 50 },
    { key: "issuer", cell: "Issuer", width: 130 },
    { key: "prime_rate", cell: "Prime rate", width: 110 },
    { key: "capacity", cell: "Capacity", width: 120 },
    { key: "renewal_date", cell: "Renewal date", width: 130 },
  ];
  staticData.banks.forEach((bank) => {
    columns.push({
      key: bank.id_bank + "_active",
      cell: bank.name + " fronted",
      width: 130,
    });
  });

  const rows = [];
  facilityStates.forEach(
    ({ name, facilityIndex, active, id_issuer, id_facility }) => {
      const facility = facilitys[facilityIndex];
      if (facility) {
        const cells = [
          <Checkbox disabled checked={facility.active} />,
          name,
          active ? facility.prime_rate + "%" : "",
          active ? formatMoney(facility.limit) : "",
          active ? moment(facility.renewal_date).format("DD/MM/YYYY") : "",
        ];
        staticData.banks.forEach((bank) => {
          const facility_bank = searchByField(
            facility.facility_banks,
            "id_bank",
            bank.id_bank,
          );
          cells.push(
            active && (
              <Checkbox
                disabled
                checked={facility_bank && facility_bank.active}
              />
            ),
          );
        });

        rows.push({
          key: id_issuer,
          cells: cells,
          onClick: () => {
            navigate("/facility/" + id_facility);
          },
        });
      }
    },
  );

  return (
    <TableCard
      title="Facilities"
      columns={columns}
      rows={rows}
      actions={actions}
    />
  );
}

// IndemnifiersTable --------------------------------------------------------------
function IndemnifiersTable({
  facilityOrder,
  indemnifiers,
  updateIndemnifierField,
  setIndemnifierFacilityActive,
  actions,
}) {
  const columns = [
    { key: "active", cell: "Active", width: 50 },
    { key: "indemnifier", cell: "Indemnifier", minWidth: 200 },
    { key: "abn", cell: "ABN", minWidth: 140 },
  ];
  facilityOrder.forEach(({ name, id_issuer }) => {
    columns.push({ key: id_issuer, width: 120, cell: name });
  });

  const rows = [];
  indemnifiers.forEach((indemnifier) => {
    rows.push({
      key: "bond_type_" + indemnifier.id_indemnifier,
      cells: [
        <Checkbox
          checked={indemnifier.active}
          onChange={(event) => {
            updateIndemnifierField(
              indemnifier.id_indemnifier,
              "active",
              event.target.checked,
            );
          }}
        />,
        <NullableTextField
          fullWidth
          expands
          compact
          smallText
          required
          type="text"
          value={indemnifier.name}
          onChange={(value) => {
            updateIndemnifierField(indemnifier.id_indemnifier, "name", value);
          }}
        />,
        <NullableTextField
          fullWidth
          compact
          smallText
          type="text"
          value={indemnifier.abn}
          onChange={(value) => {
            updateIndemnifierField(indemnifier.id_indemnifier, "abn", value);
          }}
        />,
      ].concat(
        facilityOrder.map(({ id_facility }) => {
          const active =
            searchByField(
              indemnifier.facility_indemnifiers,
              "id_facility",
              id_facility,
            ) !== null;
          return (
            <Checkbox
              checked={active}
              onChange={(event) => {
                setIndemnifierFacilityActive(
                  indemnifier.id_indemnifier,
                  id_facility,
                  event.target.checked,
                );
              }}
            />
          );
        }),
      ),
    });
  });

  return (
    <TableCard
      title="Indemnifiers"
      actions={actions}
      columns={columns}
      rows={rows}
    />
  );
}

function TableCard({ title, actions, columns, rows, sx, ...props }) {
  return (
    <>
      <SectionTitle title={title} actions={actions} />
      <PaginatedTable
        columns={columns}
        rows={rows}
        {...props}
        sx={{ ...sx, marginBottom: 4 }}
      />
    </>
  );
}
