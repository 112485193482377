import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";
import { red } from "@mui/material/colors";

import {
  BOND_STATUS_APPLIED,
  BOND_STATUS_ISSUED,
  calculateBondStatusUsage,
} from "@/BondFunctions";
import ExpandableCard from "@/components/layout/ExpandableCard";
import FlexBox from "@/components/layout/FlexBox";
import { formatMoney, searchByField } from "@/Utils";

import FacilityCapacityBar from "./FacilityCapacityBar";

export default function CapacitySummary({ bonds, facility, staticData }) {
  const theme = useTheme();

  const bondColors = {};
  bonds.forEach((bond, index) => {
    bondColors[index] = theme.bondColors[index % theme.bondColors.length];
  });

  const issuer =
    staticData &&
    facility &&
    searchByField(staticData.issuers, "id_issuer", facility.id_issuer);

  const { pending, active } = calculateBondStatusUsage(
    facility.bond_status_totals,
    staticData.bond_statuses,
  );

  let remaining = facility.limit - pending - active;
  if (bonds)
    bonds.forEach((bond) => {
      remaining -= bond.face_value;
    });

  const remainingStyle = {};
  if (remaining < 0) remainingStyle.color = red[700];

  return bonds && bonds.length > 0 && facility && issuer ? (
    <ExpandableCard
      headerContent={
        <Typography variant="h6">{"Capacity with " + issuer.name}</Typography>
      }
    >
      <FacilityCapacityBar
        key={facility.id_facility}
        facility={facility}
        additionalBonds={bonds}
        additionalBondColors={bondColors}
      />

      <Divider sx={{ marginTop: 2, marginBottom: 2 }} />

      <Stack spacing={1}>
        <DotPoint
          color={"#E0E0E0"}
          label="Total"
          value={formatMoney(facility.limit, 2)}
        />
        {pending ? (
          <DotPoint
            color={theme.bondStatusColors[BOND_STATUS_APPLIED]}
            label="Pending"
            value={"-" + formatMoney(pending, 2)}
          />
        ) : null}
        {active ? (
          <DotPoint
            color={theme.bondStatusColors[BOND_STATUS_ISSUED]}
            label="Active"
            value={"-" + formatMoney(active, 2)}
          />
        ) : null}
        {bonds.map((bond, index) => {
          return (
            <DotPoint
              key={index}
              color={bondColors[index]}
              label={"Bond " + (index + 1)}
              value={"-" + formatMoney(bond.face_value, 2)}
            />
          );
        })}
      </Stack>

      <Divider sx={{ marginTop: 2, marginBottom: 2 }} />

      <FlexBox>
        <Typography variant="tertiary">Remaining capacity</Typography>
        <Typography
          variant="tertiary"
          sx={{ ...remainingStyle, marginLeft: "auto" }}
        >
          {formatMoney(remaining, 2)}
        </Typography>
      </FlexBox>
    </ExpandableCard>
  ) : null;
}

function DotPoint({ color, label, value, ...props }) {
  return (
    <FlexBox center {...props}>
      <Box
        sx={{
          background: color,
          width: "6px",
          height: "6px",
          borderRadius: "50%",
          marginRight: 1,
        }}
      />
      <Typography variant="tertiary">{label}</Typography>
      <Typography variant="tertiary" ml="auto">
        {value}
      </Typography>
    </FlexBox>
  );
}
