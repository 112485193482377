import React, { useContext } from "react";
import { Divider, Grid, Paper, Typography } from "@mui/material";

import API from "@/API";
import {
  BOND_STATUS_APPLIED,
  BOND_STATUS_DRAFT,
  BOND_STATUS_ISSUED,
  BOND_STATUS_QUOTE,
  calculateBondStatusUsage,
  statusId,
} from "@/BondFunctions";
import CentreLoader from "@/components/common/CentreLoader";
import PageTitle from "@/components/common/PageTitle";
import FlexBox from "@/components/layout/FlexBox";
import MobilePadding from "@/components/layout/MobilePadding";
import BondReturnsTable from "@/components/modules/tables/BondReturnsTable";
import { FilterableBondsTable } from "@/components/modules/tables/BondsTable";
import StaticDataContext from "@/contexts/StaticDataContext";
import UserContext from "@/contexts/UserContext";
import { useGet } from "@/hooks/useAPI";
import { formatMoney } from "@/Utils";

export default function AdminDashboardPage() {
  const { user } = useContext(UserContext);

  const staticData = useContext(StaticDataContext);
  const [clientsData] = useGet(API.getClientsTotals(user));
  const [bondsData] = useGet(API.getBonds(user));
  const [bondReturnsData] = useGet(API.getBondReturns(user));
  const { bonds } = bondsData || {};
  const { bonds: bondReturns } = bondReturnsData || {};
  const { clients } = clientsData || {};

  if (!staticData) return <CentreLoader />;

  let filteredClients;
  if (clients) filteredClients = clients.filter((client) => client.active);

  let totalLimit = 0;
  let totalUtilised = 0;
  let totalQuoted = 0;
  let totalPremium = 0;
  if (staticData && filteredClients) {
    filteredClients.forEach((client) => {
      const { pending, active } = calculateBondStatusUsage(
        client.totals.total_bond_face_values,
        staticData.bond_statuses,
      );
      totalUtilised += active;
      totalQuoted += pending;
      totalLimit += client.totals.total_client_facility_limits;
      totalPremium += client.totals.total_bond_fees.total_client_bond_fees;
    });
  }

  const quoteId = statusId(BOND_STATUS_QUOTE, staticData);
  const appliedId = statusId(BOND_STATUS_APPLIED, staticData);
  const draftId = statusId(BOND_STATUS_DRAFT, staticData);
  const issuedId = statusId(BOND_STATUS_ISSUED, staticData);
  const applications = [];
  const issuedBonds = [];
  const endedBonds = [];
  if (bonds) {
    bonds.forEach((bond) => {
      switch (bond.id_bond_status) {
        case quoteId:
        case appliedId:
        case draftId:
          applications.push(bond);
          break;
        case issuedId:
          issuedBonds.push(bond);
          break;
        default:
          endedBonds.push(bond);
          break;
      }
    });
  }

  return (
    <>
      {clients && bonds && bondReturns ? (
        <>
          <PageTitle title="Admin dashboard" />

          <Divider />

          <MobilePadding mb={1}>
            <Grid
              container
              columnSpacing={2}
              rowSpacing={1}
              justifyContent="center"
              mt={3}
              mb={4}
            >
              <Grid item xs={12} md={3}>
                <Paper>
                  <FlexBox column center>
                    <Typography variant="grandtotal" mt={2}>
                      {formatMoney(totalUtilised, 2)}
                    </Typography>
                    <Typography variant="h5" mb={2}>
                      Utilised
                    </Typography>
                  </FlexBox>
                </Paper>
              </Grid>
              <Grid item xs={12} md={3}>
                <Paper>
                  <FlexBox column center>
                    <Typography variant="grandtotal" mt={2}>
                      {formatMoney(totalQuoted, 2)}
                    </Typography>
                    <Typography variant="h5" mb={2}>
                      Quotes and applications
                    </Typography>
                  </FlexBox>
                </Paper>
              </Grid>
              <Grid item xs={12} md={3}>
                <Paper>
                  <FlexBox column center>
                    <Typography variant="grandtotal" mt={2}>
                      {formatMoney(totalLimit, 2)}
                    </Typography>
                    <Typography variant="h5" mb={2}>
                      Total capacity
                    </Typography>
                  </FlexBox>
                </Paper>
              </Grid>
              <Grid item xs={12} md={3}>
                <Paper>
                  <FlexBox column center>
                    <Typography variant="grandtotal" mt={2}>
                      {formatMoney(totalPremium, 2)}
                    </Typography>
                    <Typography variant="h5" mb={2}>
                      Premium in applications
                    </Typography>
                  </FlexBox>
                </Paper>
              </Grid>
            </Grid>
          </MobilePadding>

          <BondReturnsTable
            title="Returning bonds"
            bondReturns={bondReturns}
            clients={clients}
          />

          <FilterableBondsTable
            title="Quotes and applications"
            bonds={applications}
            clients={clients}
            undercoverMode={false}
          />

          <FilterableBondsTable
            title="Issued bonds"
            bonds={issuedBonds}
            clients={clients}
            undercoverMode={false}
          />

          <FilterableBondsTable
            title="Inactive bonds"
            bonds={endedBonds}
            clients={clients}
            undercoverMode={false}
          />
        </>
      ) : (
        <CentreLoader />
      )}
    </>
  );
}
