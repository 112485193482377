import { Fragment, useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router";

import API from "@/API";
import CentreLoader from "@/components/common/CentreLoader";
import DatePicker from "@/components/common/DatePicker";
import DisplayField from "@/components/common/DisplayField";
import {
  DollarTextField,
  PercentageTextField,
} from "@/components/common/FormattedTextField";
import SectionCard from "@/components/common/SectionCard";
import SectionTitle from "@/components/common/SectionTitle";
import FlexBox from "@/components/layout/FlexBox";
import FacilityFiles from "@/components/modules/FacilityFiles";
import SaveDrawer from "@/components/modules/SaveDrawer";
import StaticDataContext from "@/contexts/StaticDataContext";
import UserContext from "@/contexts/UserContext";
import { useGet, usePost, usePut, useUpload } from "@/hooks/useAPI";
import { useDetectClient } from "@/hooks/useDetectClient";
import { useQuery } from "@/hooks/useQuery";
import {
  checkFormValidity,
  formatPercentage,
  indexByField,
  searchByField,
  tagForRemoval,
} from "@/Utils";

function createDefaultFacility(id_client) {
  return {
    active: true,
    account_rm: null,
    bond_type_facility_rates: [],
    facility_banks: [],
    facility_fees: [],
    id_client: id_client,
    id_facility: null,
    id_issuer: null,
    limit: 0,
    prime_rate: 0,
    renewal_date: new Date(),
    minimum_bond_charge: 0,
  };
}

export default function EditFacilityPage() {
  const navigate = useNavigate();

  const query = useQuery();
  const id_facility = query.id === "add" ? null : query.id;
  const creatingNew = query.id === "add";
  const id_client = query.client;

  const { user } = useContext(UserContext);

  const staticData = useContext(StaticDataContext);

  const [originalFacilityData] = useGet(
    id_facility ? API.getFacility(user, id_facility) : null,
  );
  const { facility: originalFacility } = originalFacilityData || {};

  const [originalClientData] = useGet(
    creatingNew && id_client ? API.getClient(user, id_client) : null,
  );
  const { facilitys } = originalClientData || {};

  const [facility, setFacility] = useState(originalFacility);

  const [showRates, setShowRates] = useState(false);

  const [revertTarget, setRevertTarget] = useState(originalFacility);

  const [changed, setChanged] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [postFacility] = usePost(API.postFacility(user));
  const [putFacility] = usePut(
    facility &&
      facility.id_facility &&
      API.putFacility(user, facility.id_facility),
  );

  const [postFileUpload] = useUpload(API.uploadFacilityFile(user, id_facility));

  const formRef = useRef();

  useDetectClient(facility && facility.id_client);

  useEffect(() => {
    if (creatingNew) {
      setFacility(createDefaultFacility(id_client));
      setRevertTarget(createDefaultFacility(id_client));
    }
  }, [creatingNew, setFacility, setRevertTarget, id_client]);

  useEffect(() => {
    if (!creatingNew) {
      setFacility(originalFacility);
      setRevertTarget(structuredClone(originalFacility));
    }
  }, [creatingNew, originalFacility, setFacility]);

  function revert() {
    setFacility(structuredClone(revertTarget));
    setChanged(false);
  }

  function updateFacility(newValues) {
    setFacility({
      ...facility,
      ...newValues,
    });
    setChanged(true);
  }

  function updateFacilityBank(id_facility_bank, field, value) {
    const newFacility = structuredClone(facility);
    const facility_bank = searchByField(
      newFacility.facility_banks,
      "id_facility_bank",
      id_facility_bank,
    );
    if (facility_bank) {
      facility_bank[field] = value;
      setFacility(newFacility);
      setChanged(true);
    }
    setChanged(true);
  }

  function updateBondTypeRate(id_bond_type, value) {
    const newFacility = structuredClone(facility);
    const index = indexByField(
      newFacility.bond_type_facility_rates,
      "id_bond_type",
      id_bond_type,
    );
    if (index >= 0) {
      if (value === null) {
        newFacility.bond_type_facility_rates.splice(index, 1);
      } else {
        newFacility.bond_type_facility_rates[index] = {
          ...newFacility.bond_type_facility_rates[index],
          rate: value,
        };
      }
    } else {
      newFacility.bond_type_facility_rates.push({
        id_bond_type: id_bond_type,
        rate: value,
      });
    }
    setFacility(newFacility);
    setChanged(true);
  }
  function updateFacilityFee(id_facility_fee_type, value) {
    const newFacility = { ...facility };
    const index = indexByField(
      newFacility.facility_fees,
      "id_facility_fee_type",
      id_facility_fee_type,
    );
    if (index >= 0) {
      if (value === null) {
        newFacility.facility_fees.splice(index, 1);
      } else {
        newFacility.facility_fees[index] = {
          ...newFacility.facility_fees[index],
          fee: value,
        };
      }
    } else {
      newFacility.facility_fees.push({
        id_facility_fee_type: id_facility_fee_type,
        fee: value,
      });
    }
    setFacility(newFacility);
    setChanged(true);
  }
  function updateBankRate(id_bank, id_duration, value) {
    const newFacility = { ...facility };

    const bank_index = indexByField(
      newFacility.facility_banks,
      "id_bank",
      id_bank,
    );
    if (bank_index >= 0) {
      const facility_bank = (newFacility.facility_banks[bank_index] = {
        ...newFacility.facility_banks[bank_index],
      });

      const duration_index = indexByField(
        facility_bank.facility_bank_duration_rates,
        "id_duration",
        id_duration,
      );
      if (duration_index >= 0) {
        if (value === null) {
          facility_bank.facility_bank_duration_rates.splice(duration_index, 1);
        } else {
          facility_bank.facility_bank_duration_rates[duration_index] = {
            ...facility_bank.facility_bank_duration_rates[duration_index],
            rate: value,
          };
        }
      } else {
        facility_bank.facility_bank_duration_rates.push({
          id_duration: id_duration,
          rate: value,
        });
      }
      setFacility(newFacility);
      setChanged(true);
    }
  }

  function setBankActive(id_bank, active) {
    const newFacility = {
      ...facility,
      facility_banks: [...facility.facility_banks],
    };
    let facility_bank = searchByField(
      newFacility.facility_banks,
      "id_bank",
      id_bank,
    );
    if (!active && facility_bank) {
      facility_bank.active = false;
    } else if (active && facility_bank) {
      facility_bank.active = true;
    } else if (active && !facility_bank) {
      facility_bank = {
        active: true,
        id_bank: id_bank,
        id_facility: id_facility,
        facility_bank_duration_rates: [],
        minimum_fronting_charge: 0,
      };
      staticData.durations.forEach((duration) => {
        facility_bank.facility_bank_duration_rates.push({
          id_bank: id_bank,
          id_duration: duration.id_duration,
          id_facility: id_facility,
          rate: 0,
        });
      });
      newFacility.facility_banks.push(facility_bank);
    }
    setFacility(newFacility);
    setChanged(true);
  }

  function save(event) {
    if (checkFormValidity(formRef.current)) {
      setSubmitting(true);
      const r = structuredClone(facility);

      // Process removals
      const facilityToSubmit = structuredClone(facility);
      tagForRemoval(
        facilityToSubmit.bond_type_facility_rates,
        revertTarget.bond_type_facility_rates,
        "id_bond_type_facility_rate",
      );
      tagForRemoval(
        facilityToSubmit.facility_fees,
        revertTarget.facility_fees,
        "id_facility_fee",
      );
      facilityToSubmit.facility_banks.forEach((facility_bank) => {
        const revertBank = searchByField(
          revertTarget.facility_banks,
          "id_facility_bank",
          facility_bank.id_facility_bank,
        );
        if (revertBank)
          tagForRemoval(
            facility_bank.facility_bank_duration_rates,
            revertBank.facility_bank_duration_rates,
            "id_duration",
          );
      });

      if (creatingNew) {
        postFacility({
          facility: facilityToSubmit,
        })
          .then((responseData) => {
            setSubmitting(false);
            setRevertTarget(r);
            setChanged(false);
            navigate("/facility/" + responseData.facility.id_facility);
          })
          .catch((error) => {
            console.log("Post facility error", error);
          });
      } else {
        putFacility({
          facility: facilityToSubmit,
        })
          .then((responseData) => {
            setSubmitting(false);
            setRevertTarget(r);
            setChanged(false);
          })
          .catch((error) => {
            console.log("Put facility error", error);
          });
      }
    }
  }

  return (
    <>
      {staticData && facility && (!creatingNew || facilitys) ? (
        <>
          <SectionTitle title={"Facility details"} />
          <SectionCard>
            <form ref={formRef}>
              <Grid container columnSpacing={2} rowSpacing={2}>
                <Grid item xs={12} md={6}>
                  {creatingNew ? (
                    <TextField
                      label="Issuer"
                      fullWidth
                      select
                      required
                      value={facility.id_issuer || ""}
                      onChange={(event) => {
                        updateFacility({ id_issuer: event.target.value });
                      }}
                    >
                      {staticData.issuers.map((issuer) => {
                        const found =
                          (searchByField(
                            facilitys,
                            "id_issuer",
                            issuer.id_issuer,
                          ) &&
                            true) ||
                          false;
                        return found ? null : (
                          <MenuItem
                            key={issuer.id_issuer}
                            value={issuer.id_issuer}
                          >
                            {issuer.name}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  ) : (
                    <DisplayField fullWidth value={facility.issuer_name} />
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  {
                    <DatePicker
                      label="Renewal date"
                      required
                      value={facility.renewal_date}
                      onChange={(value) => {
                        updateFacility({ renewal_date: value });
                      }}
                    />
                  }
                </Grid>
                {!creatingNew && (
                  <Grid item xs={12} md={6}>
                    <FormControlLabel
                      label="Active facility"
                      control={
                        <Checkbox
                          checked={facility.active}
                          onChange={(event) => {
                            updateFacility({ active: event.target.checked });
                          }}
                          sx={{ marginLeft: 1 }}
                        />
                      }
                    />
                  </Grid>
                )}
              </Grid>

              <Divider sx={{ marginTop: 3, marginBottom: 3 }} />

              <Grid container columnSpacing={2} rowSpacing={2}>
                <Grid item xs={12} md={6}>
                  <PercentageTextField
                    label="Prime rate"
                    fullWidth
                    required
                    value={facility.prime_rate}
                    onChange={(value) => {
                      updateFacility({ prime_rate: value });
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DollarTextField
                    label="Capacity"
                    fullWidth
                    required
                    value={facility.limit}
                    onChange={(value) => {
                      updateFacility({ limit: value });
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DollarTextField
                    label="Minimum bond charge"
                    fullWidth
                    required
                    value={facility.minimum_bond_charge}
                    onChange={(value) => {
                      updateFacility({ minimum_bond_charge: value });
                    }}
                  />
                </Grid>
              </Grid>

              <FlexBox justify="center" my={2}>
                <Button
                  onClick={(event) => {
                    setShowRates(!showRates);
                  }}
                >
                  {(showRates ? "Hide" : "show") + " all rates"}
                </Button>
              </FlexBox>

              {showRates && (
                <Grid container columnSpacing={2} rowSpacing={2}>
                  {staticData.bond_types.map((bondType) => {
                    const bond_type_facility_rate = searchByField(
                      facility.bond_type_facility_rates,
                      "id_bond_type",
                      bondType.id_bond_type,
                    );
                    return (
                      <Grid item xs={12} md={6} key={bondType.id_bond_type}>
                        <PercentageTextField
                          label={bondType.name}
                          clearable
                          fullWidth
                          placeholder={formatPercentage(facility.prime_rate)}
                          value={
                            bond_type_facility_rate &&
                            bond_type_facility_rate.rate
                          }
                          onChange={(value) =>
                            updateBondTypeRate(bondType.id_bond_type, value)
                          }
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              )}

              <Divider sx={{ marginTop: 3, marginBottom: 3 }} />

              <Grid container columnSpacing={2} rowSpacing={2}>
                {staticData.facility_fee_types.map((feeType, index) => {
                  const facility_fee = searchByField(
                    facility.facility_fees,
                    "id_facility_fee_type",
                    feeType.id_facility_fee_type,
                  );
                  return (
                    <Grid
                      item
                      xs={12}
                      md={6}
                      key={feeType.id_facility_fee_type}
                    >
                      <DollarTextField
                        label={feeType.name}
                        fullWidth
                        value={facility_fee ? facility_fee.fee : null}
                        onChange={(value) =>
                          updateFacilityFee(feeType.id_facility_fee_type, value)
                        }
                      />
                    </Grid>
                  );
                })}
              </Grid>

              {!creatingNew && (
                <>
                  <Divider sx={{ marginTop: 3, marginBottom: 3 }} />

                  <FacilityFiles
                    adminMode={true}
                    facility={facility}
                    files={facility.facility_files}
                    postFileUpload={postFileUpload}
                  />
                </>
              )}

              {staticData.banks.map(({ name, id_bank }) => {
                const facility_bank = searchByField(
                  facility.facility_banks,
                  "id_bank",
                  id_bank,
                );

                return (
                  <Fragment key={id_bank}>
                    <Divider sx={{ marginTop: 3, marginBottom: 2 }} />

                    <FormControlLabel
                      label={name + " fronted"}
                      control={
                        <Checkbox
                          checked={facility_bank ? facility_bank.active : false}
                          onChange={(event) => {
                            setBankActive(id_bank, event.target.checked);
                          }}
                          sx={{ marginLeft: 1 }}
                        />
                      }
                    />

                    {facility_bank && facility_bank.active && (
                      <Grid container columnSpacing={2} rowSpacing={2} mt={0}>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          key={facility_bank.id_facility_bank}
                        >
                          <DollarTextField
                            label="Minimum fronting charge"
                            fullWidth
                            value={facility_bank.minimum_fronting_charge}
                            onChange={(value) =>
                              updateFacilityBank(
                                facility_bank.id_facility_bank,
                                "minimum_fronting_charge",
                                value,
                              )
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={6}></Grid>

                        {staticData.durations.map(
                          ({ duration, id_duration }) => {
                            const facility_duration = searchByField(
                              facility_bank.facility_bank_duration_rates,
                              "id_duration",
                              id_duration,
                            );
                            return (
                              <Grid item xs={12} md={6} key={id_duration}>
                                <PercentageTextField
                                  label={duration}
                                  fullWidth
                                  value={
                                    facility_duration
                                      ? facility_duration.rate
                                      : null
                                  }
                                  onChange={(value) =>
                                    updateBankRate(id_bank, id_duration, value)
                                  }
                                />
                              </Grid>
                            );
                          },
                        )}
                      </Grid>
                    )}
                  </Fragment>
                );
              })}
            </form>
          </SectionCard>

          <SaveDrawer
            open={changed}
            actions={
              <>
                <Button
                  variant="contained"
                  disabled={submitting}
                  onClick={save}
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  disabled={submitting}
                  onClick={revert}
                >
                  Cancel
                </Button>
              </>
            }
          />
        </>
      ) : (
        <CentreLoader />
      )}
    </>
  );
}
