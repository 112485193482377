import React, { useContext } from "react";
import { Navigate } from "react-router";

import UserContext from "@/contexts/UserContext";

export default function ClientRoute({ children, ...attributes }) {
  const { user } = useContext(UserContext);

  return (
    <>
      {user && user.cognito && user.inscap && user && !user.isAdmin ? (
        children
      ) : (
        <Navigate to="/403" />
      )}
    </>
  );
}
