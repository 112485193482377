import React, { useContext } from "react";
import { Divider } from "@mui/material";

import API from "@/API";
import CentreLoader from "@/components/common/CentreLoader";
import FacilityProfileGraph from "@/components/common/FacilityProfileGraph";
import PageTitle from "@/components/common/PageTitle";
import SectionCard from "@/components/common/SectionCard";
import SectionTitle from "@/components/common/SectionTitle";
import ClientTotals from "@/components/modules/ClientTotals";
import StatementDownloader from "@/components/modules/StatementDownloader";
import BondReturnsTable from "@/components/modules/tables/BondReturnsTable";
import { FilterableBondsTable } from "@/components/modules/tables/BondsTable";
import StaticDataContext from "@/contexts/StaticDataContext";
import UserContext from "@/contexts/UserContext";
import { useGet } from "@/hooks/useAPI";
import { useDetectClient } from "@/hooks/useDetectClient";
import { useQuery } from "@/hooks/useQuery";

export function UndercoverDashboardPage() {
  const query = useQuery();
  const id_client = query.id === "add" ? null : parseInt(query.id);

  useDetectClient(id_client);

  const { user } = useContext(UserContext);

  const [clientData] = useGet(
    id_client ? API.getClient(user, id_client) : null,
  );
  const { client, facilitys } = clientData || {};

  const [bondsData, bondsDataLoading] = useGet(API.getBonds(user, id_client));
  const { bonds } = (!bondsDataLoading && bondsData) || {};

  const [bondReturnsData] = useGet(API.getBondReturns(user, id_client));
  const { bonds: bondReturns } = bondReturnsData || {};

  return (
    <DashboardPage
      undercoverMode={true}
      client={client}
      facilitys={facilitys}
      bonds={bonds}
      bondReturns={bondReturns}
    />
  );
}

export function ClientDashboardPage() {
  const { user } = useContext(UserContext);

  const [clientData] = useGet(API.getClient(user));
  const { client, facilitys } = clientData || {};

  const [bondsData] = useGet(API.getBonds(user));
  const { bonds } = bondsData || {};

  const [bondReturnsData] = useGet(API.getBondReturns(user));
  const { bonds: bondReturns } = bondReturnsData || {};

  return (
    <DashboardPage
      undercoverMode={false}
      client={client}
      facilitys={facilitys}
      bonds={bonds}
      bondReturns={bondReturns}
    />
  );
}

function DashboardPage({
  undercoverMode,
  client,
  facilitys,
  bonds,
  bondReturns,
}) {
  const staticData = useContext(StaticDataContext);

  const { user } = useContext(UserContext);

  const canSeeClientTotals = user && !user.isSubsidiary;

  if (
    !staticData ||
    !client ||
    (!facilitys && canSeeClientTotals) ||
    !bonds ||
    !bondReturns
  ) {
    return <CentreLoader />;
  }

  return (
    <>
      <PageTitle title={client.name} />

      {canSeeClientTotals ? (
        <>
          <Divider />

          <ClientTotals facilitys={facilitys} />

          <SectionTitle title="Facility Profile" />
          <SectionCard>
            <FacilityProfileGraph
              client={client}
              facilitys={facilitys}
              bonds={bonds}
            />
          </SectionCard>
        </>
      ) : (
        <Divider sx={{ mb: 2 }} />
      )}

      <BondReturnsTable
        title="Returning bonds"
        bondReturns={bondReturns}
        clients={[client]}
      />

      <FilterableBondsTable
        title="Bonds"
        bonds={bonds}
        undercoverMode={undercoverMode}
      />

      <SectionTitle title="Statements" />
      <SectionCard>
        <StatementDownloader
          adminMode={undercoverMode}
          client={client}
          facilitys={facilitys}
        />
      </SectionCard>
    </>
  );
}
