import ExcelJS from "exceljs";
import * as FileSaver from "file-saver";
import moment from "moment";

import { calculateDays, calculateOverrideRate } from "@/BondFunctions";
import { ENV } from "@/Environment";
import { isBlank } from "@/Utils";

const PERCENT_FORMAT = "0.00%";
const MONEY_FORMAT = '_("$"* #,##0.00_);_("$"* #,##0.00_);_($* "-"??_);_(@_)';

const blue = "FF89B3DB";
const grey = "FFD9D9D9";
const black = "FF000000";
const white = "FFFFFFFF";

export function downloadStatementSpreadsheet(
  statementDate,
  bondsData,
  client,
  facilitys,
) {
  if (ENV.logApiCalls)
    console.log("Generating statement", statementDate, bondsData);
  const fileName = client.name.replace(/ /gi, "-") + "-Statement.xlsx";

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(client.code + " Client");

  // End of financial year
  const eofy = moment(statementDate);
  eofy.endOf("month");
  if (eofy.get("month") > 5) eofy.add(1, "year");
  eofy.set("month", 5);

  // First month - 2 years before end of current financial year (+ 1 month for start of that financial year)
  const firstMonth = moment(eofy);
  firstMonth.subtract(2, "year");
  firstMonth.add(1, "month");

  // End month - 1 year after end of current financial year
  const lastMonth = moment(eofy);
  lastMonth.add(1, "year");

  const CLIENT = "client_code";
  const CLIENT_NAME = "client_name";
  const RATE = "bond_rate";
  const INVOICE = "bond_invoice";
  const BOND_REF = "bond_reference";
  const TYPE = "bond_type";
  const ISSUER = "bond_facility_issuer_name";
  const INDEMNIFIER = "bond_indemnifier_name";
  const PRINCIPAL = "bond_principal";
  const PROJECT = "bond_project";
  const FACE_VALUE = "bond_face_value";
  const STATUS = "bond_status";
  const ON_ISSUE = "on_issue";
  const START_DATE = "bond_date_start";
  const END_DATE = "bond_date_finish";

  const columns = [
    { label: "Client", key: CLIENT, width: 10 },
    {
      label: "Rate",
      key: RATE,
      width: 10,
      isPercent: true,
      style: { numFmt: PERCENT_FORMAT },
    },
    { label: "Invoice #", key: INVOICE, width: 18 },
    { label: "Bond Ref", key: BOND_REF, width: 24 },
    { label: "Type", key: TYPE, width: 17 },
    { label: "Issuer", key: ISSUER, width: 14 },
    { label: "Entity", key: INDEMNIFIER, width: 32 },
    { label: "Principal", key: PRINCIPAL, width: 32 },
    { label: "Project", key: PROJECT, width: 20 },
    {
      label: "Face Value",
      key: FACE_VALUE,
      width: 18,
      isMoney: true,
      style: { numFmt: MONEY_FORMAT },
    },
    { label: "Status", key: STATUS, width: 13 },
    {
      label: "On Issue",
      key: ON_ISSUE,
      width: 18,
      isMoney: true,
      style: { numFmt: MONEY_FORMAT },
    },
    { label: "Start Date", key: START_DATE, width: 15, isDate: true },
    { label: "Fee End Date", key: END_DATE, width: 15, isDate: true },
    //{ label: null, width: 6 },
  ];
  /*
	let monthKeys = [];
	let monthKeyIndex = 1;
	for (let month = moment(firstMonth); month <= lastMonth; month.add(1, "month")) {
		let monthKey = "Date" + monthKeyIndex;
		monthKeys.push(monthKey);
		columns.push({
			key: monthKey, label: month.toDate(), labelIsDate: true, width: 16, style: { numFmt: MONEY_FORMAT },
		})
		monthKeyIndex++;
	}
	*/

  // Preprocess data
  const issuerTotals = {};
  let totalOnIssue = 0;
  facilitys.forEach((facility) => {
    issuerTotals[facility.issuer_name] = 0;
  });
  for (let i = 0; i < bondsData.length; i++) {
    const rowData = bondsData[i];

    let isIssued;
    switch (rowData[STATUS]) {
      case "Issued":
        isIssued = true;
        break;
      default:
        isIssued = false;
        break;
    }

    if (isIssued) rowData[ON_ISSUE] = rowData[FACE_VALUE];
    const value = isIssued ? parseFloat(rowData[FACE_VALUE]) : 0;

    const issuer = rowData[ISSUER];
    issuerTotals[issuer] = (issuerTotals[issuer] || 0) + value;
    totalOnIssue += value;

    const bond_fee_override = rowData["bond_fee_override"];
    if (bond_fee_override !== null) {
      const face_value = rowData[FACE_VALUE];
      const days = calculateDays(
        new Date(rowData["bond_date_start"]),
        new Date(rowData["bond_date_finish"]),
      );
      rowData[RATE] = calculateOverrideRate(
        face_value,
        days,
        bond_fee_override,
        2,
      );
    }

    // If no indemnifier, use client name
    if (!rowData[INDEMNIFIER]) rowData[INDEMNIFIER] = rowData[CLIENT_NAME];
  }

  worksheet.columns = columns.map((column) => {
    if (isBlank(column.key)) column.key = column.label;
    return {
      key: column.key,
      width: column.width,
      style: column.style,
    };
  });

  worksheet.getCell(2, 2).value = "Client:";
  worksheet.getCell(2, 3).value = client.code;
  worksheet.getCell(2, 5).value = "Statement Date:";
  worksheet.getCell(2, 6).value = statementDate;
  worksheet.getCell(2, 6).numFmt = "dd-mmm-yy";
  worksheet.addRow();

  const issuerHeaderRow = worksheet.addRow();
  issuerHeaderRow.getCell(3).value = "Utilised limits";
  setFill(issuerHeaderRow.getCell(3), blue);
  worksheet.mergeCells("C4:D4");

  for (const issuer in issuerTotals) {
    const row = worksheet.addRow();
    row.getCell("C").value = issuer;
    row.getCell("D").value = issuerTotals[issuer];
    row.getCell("D").numFmt = MONEY_FORMAT;
  }

  const issuerTotalRow = worksheet.addRow();
  issuerTotalRow.getCell("C").value = "Total";
  setFill(issuerTotalRow.getCell("C"), grey);
  issuerTotalRow.getCell("D").value = totalOnIssue;
  issuerTotalRow.getCell("D").numFmt = MONEY_FORMAT;
  setFill(issuerTotalRow.getCell("D"), grey);

  const borderStyle = { style: "medium", color: { argb: "FF000000" } };
  for (let i = issuerHeaderRow._number; i <= issuerTotalRow._number; i++) {
    worksheet.getCell(i, 3).border = { left: borderStyle };
    worksheet.getCell(i, 4).border = { right: borderStyle };
  }
  worksheet.getCell(issuerHeaderRow._number, 3).border = {
    top: borderStyle,
    left: borderStyle,
    right: borderStyle,
  };
  worksheet.getCell(issuerTotalRow._number, 3).border = {
    bottom: borderStyle,
    left: borderStyle,
  };
  worksheet.getCell(issuerTotalRow._number, 4).border = {
    bottom: borderStyle,
    right: borderStyle,
  };

  worksheet.addRow();

  // Header row
  const headerRow = worksheet.addRow();
  setFontColor(headerRow, white);
  setFill(headerRow, black);
  columns.forEach((column, index) => {
    const cell = headerRow.getCell(index + 1);
    cell.value = column.label;
    if (column.labelIsDate) cell.numFmt = "dd-mmm-yy";
  });

  // Build out grid from input data
  for (let i = 0; i < bondsData.length; i++) {
    const rowData = bondsData[i];
    const row = worksheet.addRow();

    //let endDate = rowData[END_DATE];
    //let isCurrent = (new Date(endDate) >= statementDate);
    //let goesBeyondFinancialYear = (new Date(endDate) > eofy.toDate());

    for (let j = 0; j < columns.length; j++) {
      const column = columns[j];
      const cell = row.getCell(j + 1);

      let value = rowData[column.key];
      if (column.isDate) value = isBlank(value) ? null : new Date(value);
      if (column.isPercent)
        value = isBlank(value) ? null : parseFloat(value) / 100;
      if (column.isMoney) value = isBlank(value) ? null : parseFloat(value);
      cell.value = value;
    }
  }

  const fr = headerRow._number + 1;
  const lr = Math.max(fr, worksheet._rows.length);

  function cl(indexOrKey) {
    return columnLetter(worksheet, indexOrKey);
  }

  function addSumFormula(columnKey) {
    totalsRow.getCell(columnKey).value = {
      formula: "=SUM(" + cl(columnKey) + fr + ":" + cl(columnKey) + lr + ")",
    };
  }

  // Add Totals row
  worksheet.addRow();
  const totalsRow = worksheet.addRow();
  setFill(totalsRow, grey);
  addSumFormula(FACE_VALUE);
  addSumFormula(ON_ISSUE);
  /*
	monthKeys.forEach((key) => {
		addSumFormula(key);
	})
	*/

  // Apply shared formulae
  /*
	if (bondsData.length > 0) {

		const formulaRow = worksheet.getRow(fr);
		
		function assignFormula(columnLabel, formula, includedColumns) {
			const letter = cl(columnLabel);

			// Find applicable columns to apply range
			let firstColumnLetter = letter;
			let lastColumnLetter = letter;
			includedColumns.forEach((checkLabel) => {
				let checkLetter = cl(checkLabel);
				let checkNumber = worksheet.getColumn(checkLetter)._number;
				if (checkNumber < worksheet.getColumn(firstColumnLetter)._number) firstColumnLetter = checkLetter;
				if (checkNumber > worksheet.getColumn(lastColumnLetter)._number) lastColumnLetter = checkLetter;
			});

			// Assign formula
			formulaRow.getCell(columnLabel).value = {
				formula: formula,
				shareType: 'shared',
				ref: firstColumnLetter + fr + ":" + lastColumnLetter + lr,
			}

			// Link back to main formula
			for (let i = fr + 1; i <= lr; i++) {
				worksheet.getCell(letter + i).value = { sharedFormula: letter + fr };
			}
		}

		// Month formula
		monthKeys.forEach((key) => {
			let MONTH_COLUMN = key;
			assignFormula(
				MONTH_COLUMN,
				"=IF(EOMONTH($" + cl(END_DATE) + fr + ",0)>" + cl(MONTH_COLUMN) + "$" + headerRow._number + ",$" + cl(ON_ISSUE) + fr + ",0)",
				[ON_ISSUE, END_DATE],
			);
		});

	}
	*/

  workbook.xlsx.writeBuffer().then((data) => {
    const blobType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const blob = new Blob([data], { type: blobType });
    FileSaver.saveAs(blob, fileName);
  });
}

function columnLetter(worksheet, indexOrKey) {
  const column = worksheet.getColumn(indexOrKey);
  let num = column._number - 1;
  let letters = "";
  while (num >= 0) {
    letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"[num % 26] + letters;
    num = Math.floor(num / 26) - 1;
  }
  return letters;
}

function createFill(argb) {
  return {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: argb },
  };
}
function createFontColor(argb) {
  return {
    color: { argb: argb },
  };
}

function setFill(o, argb) {
  o.fill = createFill(argb);
}
function setFontColor(o, argb) {
  o.font = createFontColor(argb);
}
