import { useContext, useEffect } from "react";

import API from "@/API";
import CentreLoader from "@/components/common/CentreLoader";
import UserContext from "@/contexts/UserContext";
import { useGet } from "@/hooks/useAPI";

export default function UserDataLoader() {
  const { onDataLoaded, logout } = useContext(UserContext);

  const [userData /*userDataLoading*/, , userDataError] = useGet(
    API.getUserData(),
  );

  useEffect(() => {
    if (userDataError) {
      console.log("Error", userDataError, userDataError.response);
      if (userDataError.response.status === 401) {
        logout();
      }
    } else if (userData) {
      onDataLoaded(userData.user, userData.contact);
    }
  }, [userData, userDataError, logout, onDataLoaded]);

  return <CentreLoader />;
}
