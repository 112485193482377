import { useContext } from "react";
import { Box, Container, Drawer } from "@mui/material";

import FlexBox from "@/components/layout/FlexBox";
import Header from "@/components/modules/Header";
import SiteNavigation from "@/components/modules/SiteNavigation";
import LayoutContext from "@/contexts/LayoutContext";

export default function PageContainer({ children, sx, ...props }) {
  const {
    wideLayout,
    drawerOpen,
    setDrawerOpen,
    navCollapsed,
    setNavCollapsed,
    navStyle,
    contentStyle,
    containerStyle,
  } = useContext(LayoutContext);

  return (
    <>
      <Header
        onHamburgerClick={() => {
          if (wideLayout) {
            setNavCollapsed(!navCollapsed);
          } else {
            setDrawerOpen(!drawerOpen);
          }
        }}
      />

      <FlexBox mb={2}>
        {wideLayout ? (
          <Box sx={navStyle}>
            <SiteNavigation collapsed={navCollapsed} />
          </Box>
        ) : (
          <Drawer
            anchor={"left"}
            open={drawerOpen}
            onClose={() => {
              setDrawerOpen(false);
            }}
          >
            <SiteNavigation
              collapsed={false}
              onLinkClicked={() => {
                setDrawerOpen(false);
              }}
            />
          </Drawer>
        )}
        <Box sx={contentStyle}>
          <Container sx={{ ...containerStyle, paddingBottom: 4 }}>
            <FlexBox column pt={2} sx={{ flexGrow: 1, ...sx }} {...props}>
              {children}
            </FlexBox>
          </Container>
        </Box>
      </FlexBox>
    </>
  );
}
