import { CircularProgress } from "@mui/material";

import CentreWrapper from "@/components/layout/CentreWrapper";

export default function CentreLoader() {
  return (
    <CentreWrapper>
      <CircularProgress />
    </CentreWrapper>
  );
}
