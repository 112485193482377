import { Box } from "@mui/material";

import FlexBox from "./FlexBox";

export default function CentreWrapper({
  children,
  sx,
  padding,
  vertical,
  ...props
}) {
  return (
    <FlexBox
      p={padding === undefined ? 2 : padding}
      sx={{ flexGrow: 1, alignItems: vertical ? "center" : null }}
      {...props}
    >
      <Box sx={{ margin: "auto", ...sx }}>{children}</Box>
    </FlexBox>
  );
}
